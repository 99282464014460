import Vue from 'vue'
import firebase from 'firebase'
import store from '../store'
import App from './App.vue'
import './assets/tailwind.css'
import router from './router'
import VueClipboard from 'vue-clipboard2'
import Notifications from 'vue-notification'
import Vuelidate from 'vuelidate'
import VueMoment from 'vue-moment'
import { Datetimepicker, Button, Icon, Upload, Modal, Table, Pagination, Tabs, Loading, Switch } from '@oruga-ui/oruga'
import '@oruga-ui/oruga/dist/oruga.css'
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { VLazyImagePlugin } from "v-lazy-image";

// import VueTour from 'vue-tour'
// require('vue-tour/dist/vue-tour.css')

import {profilesCollection} from './firebase.js'

import {SENTRY_DSN, GTM} from './Constants.js'

import VueGtm from "@gtm-support/vue2-gtm"

// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";

Vue.config.productionTip = false

// const options = {
//   maxToasts: 1,
//   position: "top-right",
//   timeout: 2000,
//   closeOnClick: true,
//   pauseOnFocusLoss: true,
//   pauseOnHover: true,
//   draggable: true,
//   draggablePercent: 0.6,
//   showCloseButtonOnHover: true,
//   hideProgressBar: true,
//   closeButton: "button",
//   icon: true,
//   rtl: false
// };
// Vue.use(Toast, options)

Vue.use(Notifications)
Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(VueMoment)
// Vue.use(VueTour)

// Oruga components
Vue.use(Datetimepicker)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Upload)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(Switch)
Vue.use(Loading)
Vue.use(VLazyImagePlugin)

Vue.use(VueGtm, {
  id: GTM,
  enabled: true,
  debug: false,
  vueRouter: router
})

// Global event bus solution
// https://stackoverflow.com/questions/48035310/open-a-vuetify-dialog-from-a-component-template-in-vuejs
export const bus = new Vue()


Sentry.init({
  Vue,
  dsn: SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


// === Filters ===
Vue.filter("prettyCurrency", function(value) {
  if (!value) return ""

  const prettyValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  return `R${prettyValue}`
})

// === Authentication ===
firebase.auth().onAuthStateChanged(user => {
  store.dispatch('fetchUser', user)

  if(user) {
    profilesCollection
    .doc(user.uid)
    .onSnapshot(snap => {
      const document = snap.data()
      store.dispatch('setProfile', document)
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
