<template>
  <div class="relative overflow-hidden">
    <img class="w-full" :src="headerImage" v-if="headerImage && !loading" />
    <img
      :alt="title"
      class="w-full"
      src="~@/assets/images/the-one-stellenbosch/main-page-banner-lg.jpg"
      v-if="!headerImage && !loading"
    />
    <button class="absolute top-10 w-full text-center">
    </button>
  </div>
</template>

<script>
import { settingsCollection } from "../../firebase.js";
import {APP_TITLE} from "@/Constants";

export default {
  name: "PageBanner",
  data() {
    return {
      title: APP_TITLE,
      headerImage: null,
      loading: true,
    };
  },
  created() {
    settingsCollection.doc("globalSettings").onSnapshot((doc) => {
      this.headerImage = doc.data().headerImage;
      this.loading = false;
    });
  },
};
</script>
