<template>
  <div>
    <!-- Global Discount Type Needed to be Used -->
    <ul
      v-for="global in globals"
      :key="global.globalDiscountType"
    >
      <div
        v-if="global.globalDiscountType === 'amount'"
      >
        <ul
          v-for="global in globals"
          :key="global.globalDiscountAmount"
        >
          <!-- Locked Discount Global Discount type = Amount -->
          <button
            v-if="!user.profile.answeredDiscountSurvey"
            class="w-full flex flex-row items-center justify-center p-2 text-white font-semibold bg-green-500 hover:bg-green-400 border-2 border-green-500 rounded cursor-pointer"
            type="button"
            style="transition: all 0.15s ease"
            v-on:click="toggleModal()"
          >
            <lockIcon />
            Unlock {{formatPrice(global.globalDiscountAmount)}} Discount
          </button>

          <!-- Unlocked Discount Global Discount type = Amount -->
          <button
            v-else
            class="w-full flex flex-row items-center justify-center p-2 text-green-500 font-semibold border-2 border-green-500 rounded cursor-pointer"
            type="button"
            style="transition: all 0.15s ease"
            v-on:click="toggleModal()"
          >
            <UnlockIcon />
            {{formatPrice(global.globalDiscountAmount)}} Discount Unlocked!
          </button>
        </ul>
      </div>
      <div
        v-if="global.globalDiscountType === 'percentage'"
      >
        <ul
          v-for="global in globals"
          :key="global.globalDiscountAmount"
        >
          <!-- Locked Discount Global Discount type = Percentage -->
          <button
            v-if="!user.profile.answeredDiscountSurvey"
            class="w-full flex flex-row items-center justify-center p-2 text-white font-semibold bg-green-500 hover:bg-green-400 border-2 border-green-500 rounded cursor-pointer"
            type="button"
            style="transition: all 0.15s ease"
            v-on:click="toggleModal()"
          >
            <lockIcon />
            Unlock {{global.globalDiscountAmount}}% Discount
          </button>

          <!-- Unlocked Discount Global Discount type = Percentage -->
          <button
            v-else
            class="w-full flex flex-row items-center justify-center p-2 text-green-500 font-semibold border-2 border-green-500 rounded cursor-pointer"
            type="button"
            style="transition: all 0.15s ease"
            v-on:click="toggleModal()"
          >
            <UnlockIcon />
            {{global.globalDiscountAmount}}% Discount Unlocked!
          </button>
        </ul>
      </div>

      <!-- Global Discount Type Modal -->
      <button
        class="lb-dialog-close-btn-x top-5 right-5"
        v-if="showModal"
        v-on:click="toggleModal()"
      >
        ×
      </button>

      <div
        v-if="showModal"
        class="fixed z-50 top-16 left-1/2 transform -translate-x-1/2 w-full max-w-sm md:max-w-3xl outline-none focus:outline-none"
      >
        <div class="relative w-auto mx-auto p-10 bg-white rounded shadow-lg">
          <!--content-->
          <div
            class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
          >
            <div
              v-if="global.globalDiscountType === 'amount'"
            >
              <ul
                v-for="global in globals"
                :key="global.globalDiscountAmount"
              >
                <!-- Locked Discount Global Discount type = Amount -->
                <div v-if="!user.profile.answeredDiscountSurvey">

                  <h1 class="text-lg">
                    Unlock your {{global.globalDiscountAmount | prettyCurrency}} launch discount!
                  </h1>

                  <p class="mt-5 font-normal">
                    Simply complete your buyer profile by clicking below and you will automatically unlock your {{global.globalDiscountAmount | prettyCurrency}} launch discount on the interactive price list.
                  </p>

                  <button
                    v-on:click="closeMenu"
                    @click.prevent="goToSurvey"
                    class="lb-primary-button mt-5"
                  >
                    Complete My Profile
                  </button>

                </div>
                <!-- Unlocked Discount Global Discount type = Amount -->
                <div v-else>

                  <h1 class="text-lg">
                    Your {{global.globalDiscountAmount | prettyCurrency}} launch discount has been unlocked!
                  </h1>

                  <p class="mt-5 font-normal">
                    Your buyer profile has been completed and your {{global.globalDiscountAmount | prettyCurrency}} launch discount on the interactive price list has been unlocked.
                  </p>

                  <button
                    v-on:click="closeMenu"
                    class="mt-5 p-2 font-semibold text-center text-sm text-white bg-green-500 border border-green-500 rounded"
                  >
                    Profile Completed
                  </button>

                </div>
              </ul>
            </div>
            <div
              v-if="global.globalDiscountType === 'percentage'"
            >
              <ul
                v-for="global in globals"
                :key="global.globalDiscountAmount"
              >
                <!-- Locked Discount Global Discount type = Percentage -->
                <div v-if="!user.profile.answeredDiscountSurvey">

                  <h1 class="text-lg">
                    Unlock your {{global.globalDiscountAmount}}% launch discount!
                  </h1>

                  <p class="mt-5 font-normal">
                    Simply complete your buyer profile by clicking below and you will automatically unlock your {{global.globalDiscountAmount}}% launch discount on the interactive price list.
                  </p>

                  <button
                    @click.prevent="goToSurvey"
                    v-on:click="closeMenu"
                    class="lb-primary-button mt-5"
                  >
                    Complete My Profile
                  </button>

                </div>
                <!-- Unlocked Discount Global Discount type = Percentage -->
                <div v-else>

                  <h1 class="text-lg">
                    Your {{global.globalDiscountAmount}}% launch discount has been unlocked!
                  </h1>

                  <p class="mt-5 font-normal">
                    Your buyer profile has been completed and your {{global.globalDiscountAmount}}% launch discount on the interactive price list has been unlocked.
                  </p>

                  <button
                    v-on:click="closeMenu"
                    class="mt-5 p-2 font-semibold text-center text-sm text-white bg-green-500 border border-green-500 rounded"
                  >
                    Profile Completed
                  </button>

                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showModal"
        @click="showModal = false"
        class="opacity-25 fixed inset-0 z-40 bg-black"
      ></div>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import UnlockIcon from "vue-material-design-icons/LockOpenVariant.vue"
import lockIcon from "vue-material-design-icons/Lock.vue"
import {settingsCollection} from "@/firebase";

export default {
  name: "MainNavBarProfileDropdownDiscount",
  props: {
    closeMenu: {
      callback: Function,
    }
  },
  components: {
    UnlockIcon,
    lockIcon,
  },
  data() {
    return {
      globals: [],
      showModal: false,
    };
  },
  methods: {
    formatPrice(num) {
      if (num >= 1000000000) {
        return 'R' + (num / 1000000000).toFixed(1).replace(/\.0$/, '')+ 'G';
      }
      if (num >= 1000000) {
        return 'R' + (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
        return 'R' + (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    goToSurvey() {
      this.showModal = false,
        this.$router.push({name: 'survey'})
    }
  },
  created() {
    settingsCollection.orderBy("globalDiscountAmount").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          displaySplashScreen: doc.data().displaySplashScreen,
          splashScreenMessage: doc.data().splashScreenMessage,
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
        });
      });
      this.globals = globals;
    });
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  }
};
</script>
