<template>
  <div>
    <button v-if="!isUnitInShortlist"
      @click="addToShortlist(unitID)"
      class="has-tooltip flex items-center justify-center w-full p-2 text-sm tracking-wider font-semibold text-black shadow-border bg-gray-100 rounded hover:bg-secondarylight hover:text-white"
    >
      <span>
        <h1>
        <ShortlistIcon class="text-primary" />
        </h1>
        <div v-if="shortlistCount >= settings.shortlistUnitShow">
         <p class="span_paragraph">
         </p>
        </div>
      </span>
      <span v-if="show" class="tooltip -bottom-7 -left-20">ADD TO LIST</span>
    </button>

    <button
      v-else
      @click="deleteFromShortlist(unitID, unitName)"
      class="has-tooltip flex items-center justify-center p-2 w-full text-sm tracking-wider font-semibold text-white shadow-border bg-secondary rounded"
    >
      <span>
      <h1>
      <ShortlistIcon class="text-primary" />
      </h1>
       <div v-if="shortlistCount >= settings.shortlistUnitShow">
         <p class="span_paragraph">
         </p>
       </div>
      </span>
      <span v-if="show" class="tooltip -bottom-7 -left-20">SHORTLISTED</span>
    </button>
  </div>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import {profilesCollection, settingsCollection} from "../../firebase.js";

import isNil from "lodash/isNil";
import indexOf from "lodash/indexOf";
import ShortlistIcon from "vue-material-design-icons/Star.vue";

export default {
  name: "AddToShortlist",
  components: {
    ShortlistIcon,
  },
  props: {
    unit: {
      default: [],
    },
    unitID: {
      default: null,
    },
    unitName: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    settings: {
      shortlistUnitShow: null,
    },
    shortlistCount: 0
  }),
  methods: {
    returnID(id)
    {
      alert(id);
    },
    getShortlistCollection() {
      profilesCollection.onSnapshot((profiles) => {
        this.shortlistCount = 0;
        profiles.forEach((profile) => {
          if (
            profile.data().unitShortlistIDs &&
            profile.data().unitShortlistIDs.length > 0
          ) {
            profile.data().unitShortlistIDs.map((unit) => {
              if (unit === this.unitID)
                this.shortlistCount++
            });
          }
        });
      });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.shortlistUnitShow = doc.data().shortlistUnitShow || null;
      });
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    addToShortlist(unitID) {
      if (isNil(this.user) || !this.user.loggedIn) {
        this.$router.push('/login')
        this.$notify({
          group: "global",
          type: "error",
          text:
            "Please create an account and sign in to add a unit to your shortlist",
        });

        return;
      }

      const shortlistData = {
        unitID: unitID,
        unitName: this.unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayUnion(unitID),
          unitShortlist: firebase.firestore.FieldValue.arrayUnion(
            shortlistData
          ),
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            text: "Unit added to your shortlist",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            text: error.message,
          });
        });
    },
    deleteFromShortlist(unitID, unitName) {
      const shortlistData = {
        unitID: unitID,
        unitName: unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayRemove(unitID),
          unitShortlist: firebase.firestore.FieldValue.arrayRemove(
            shortlistData
          ),
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            text: "Unit removed to your shortlist",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            text: error.message,
          });
        });
    },
  },
  created() {
    this.getSettings();
    // this.getShortlistCollection();
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isUnitInShortlist() {
      if (
        !isNil(this.user) &&
        !isNil(this.user.profile) &&
        !isNil(this.user.profile.unitShortlistIDs)
      ) {
        return indexOf(this.user.profile.unitShortlistIDs, this.unitID) >= 0;
      }

      return false;
    },
  },
  mounted() {
    this.getShortlistCollection();
  },
};
</script>
<style  lang="css" scoped>
.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.span_paragraph {
  font-size: 10px;
}
.is-bold {
  font-weight: bold;
}
</style>
