<template>
  <div>
    <!--Modal-->
    <button
      class="flex flex-row items-center justify-center w-full p-2 text-black font-semibold rounded hover:text-white hover:bg-primary cursor-pointer"
      type="button"
      v-on:click="toggleModal()"
    >
      Shortlist
      <span class="ml-2 px-2 py-1 text-sm border-2 border-black rounded">{{
          totalUnits
        }}</span>
    </button>

    <button
      class="lb-dialog-close-btn-x top-5 right-5"
      v-if="showModal"
      v-on:close="toggleModal()"
      v-on:click="closeMenu"
    >
      ×
    </button>

    <div
      v-if="showModal && settings.allowReserve"
      class="fixed z-50 top-16 left-1/2 transform -translate-x-1/2 w-full max-w-md md:max-w-lg overflow-x-hidden overflow-y-auto p-1 inset-0 outline-none focus:outline-none justify-center"
    >
      <div class="relative w-auto mx-auto p-3 bg-white rounded shadow-lg">
        <!--content-->
        <div
          class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
        >
          <p class="font-semibold text-lg text-black">Your Unit Shortlist</p>

          <!--Unit Name/Number & price & if Discount -->
          <div
            v-for="unit in units"
            :key="unit.id"
            class="flex flex-row mt-5 items-center justify-center p-2 border rounded"
          >
            <div class="flex-grow text-left">
              <p class="text-black">Unit {{ unit.name }}</p>
              <UnitPrice
                class="text-black relative"
                tableStyle="true"
                :unit="unit"
                :settings="settings"
                :show="false"
                :bigPrice="false"
                :normalPrice="true"
              />
            </div>

            <div v-if="settings.isGlobalOverrideAction === true">
              <button
                @click="viewUnit(unit.name)"
                v-on:click="closeMenu"
                class="p-2 font-semibold text-md bg-primary rounded"
              >
                View
              </button>

              <a
                :href="settings.globalOverrideAction"
                target="_blank"
              >
                <button
                  class="bg-primary w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                >
                  {{settings.globalOverrideActionButton}}
                </button>
              </a>
            </div>

            <div v-else>
              <div v-if="unit.isOverrideAction === true">
                <!--View Unit Btn-->
                <button
                  @click="viewUnit(unit.name)"
                  v-on:click="closeMenu"
                  class="p-2 font-semibold text-md bg-primary rounded"
                >
                  View
                </button>

                <!--Status of Unit Btn-->

                <a
                  :href="unit.unitOverrideAction"
                  target="_blank"
                >
                  <button
                    v-on:click="closeMenu"
                    class="bg-primary w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                  >
                    {{unit.unitOverrideActionButton}}
                  </button>
                </a>
              </div>

              <div class="has-tooltip" v-else>
                <!--View Unit Btn-->
                <button
                  @click="viewUnit(unit.name)"
                  class="p-2 font-semibold text-md bg-primary rounded"
                  v-on:click="closeMenu"
                  :id="unit.customIdViewButton"
                >
                  View
                </button>

                <!--Status of Unit Btn-->

                <button
                  disabled
                  class="w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'pending' || unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment'"
                  v-on:click="closeMenu"
                >
                  Pending
                </button>

                <button
                  disabled
                  class="w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'reserved'"
                  v-on:click="closeMenu"
                >
                  Reserved
                </button>
                <button
                  disabled
                  class="w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'sold'"
                  v-on:click="closeMenu"
                >
                  Sold
                </button>

                <button
                  class="w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold text-white tracking-wider rounded outline-none bg-green-500 border hover:bg-green-600 border-solid border-green-500 focus:outline-none"
                  @click="goToUnitReserve(unit.name)"
                  v-on:click="closeMenu"
                  v-if="unit.status === 'available' && !disableReserve && !unit.preReserved"
                >
                  Reserve
                </button>
                <button
                  disabled
                  class="w-28 md:w-36 ml-2 p-2 uppercase text-md font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border focus:outline-none cursor-not-allowed"
                  v-if="unit.status === 'available' && !disableReserve && unit.preReserved"
                  v-on:click="closeMenu"
                >
                  Pending
                </button>
                <button
                  disabled
                  class="relative w-28 md:w-36 ml-2 p-2 text-white tracking-wider rounded outline-none border-solid focus:outline-none bg-gray-400"
                  v-if="unit.status === 'available' && disableReserve"
                  v-on:click="closeMenu"
                >
                  <span class="text-md font-semibold uppercase">Reserve</span>
                  <span class="tooltip  -bottom-0 -right-0">This reserve button will become active once sales go live</span>
                </button>
              </div>
            </div>



            
            <!--Delete Unit Btn-->
            <button
              @click="deleteFromShortlist(unit.id, unit.name)"
              class="ml-2 p-2 md:px-2 md:py-2 font-semibold text-md bg-red-500 text-white rounded"
            >
              X
            </button>
          </div>

          <div
            v-if="user.profile.unitShortlist.length === 0"
            class="flex flex-col items-center mt-5"
          >
            <p class="mb-2">Hit the</p>
            <ShortlistIcon :size="40" class="text-primary" />
            <p class="mt-2">button to add units to your Shortlist</p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showModal"
      @click="showModal = false"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
  </div>
</template>

<script>
import firebase from "firebase";
import { profilesCollection, settingsCollection } from "../../firebase.js";
import { unitsCollection } from "../../firebase.js";
import UnitPrice from "./shared/UnitPrice.vue";
import ShortlistIcon from "vue-material-design-icons/Star.vue";

export default {
  name: "MainNavBarProfileDropdownShortlist",
  props: {
    user: {
      type: Object,
    },
    settings: {
      type: Object,
    },
    closeMenu: {
      callback: Function,
    }
  },
  components: {
    ShortlistIcon,
    UnitPrice,
  },
  data() {
    return {
      units: [],
      globals: [],
      deletedShortlistItems: [],
      showModal: false,
      now: new Date(),
      roleStatuses: ['admin', 'admin-viewonly', 'superadmin']
    };
  },
  created() {
    settingsCollection.orderBy("globalDiscountAmount").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          displaySplashScreen: doc.data().displaySplashScreen,
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          interestRate: (doc.data().interestRate || null),
          splashScreenMessage: doc.data().splashScreenMessage,
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
          allowDeposit: (doc.data().allowDeposit || false),
          depositAmount: (doc.data().depositAmount || null),
          setOneDayOnlyDiscount: (doc.data().setOneDayOnlyDiscount || false),
          isGlobalOverrideAction: (doc.data().isGlobalOverrideAction || false),
          globalOverrideAction: (doc.data().globalOverrideAction || null),
          globalOverrideActionButton: (doc.data().globalOverrideActionButton || null),
          showAvailableUnits: doc.data().showAvailableUnits,
          isUsersOnline: doc.data().isUsersOnline,
          allowDiscountNoSurvey: doc.data().allowDiscountNoSurvey,
          isLaunchDate: (doc.data().isLaunchDate || false),
          depositType: (doc.data().depositType || null),
          bypassAdmin: (doc.data().bypassAdmin || false),
        });
      });
      this.globals = globals;
    });
  },
  methods: {
    updateNow() {
      this.now = Date.now();
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    goToUnitReserve(unitName) {
      this.$router.push({ name: "reserve", params: { name: unitName } });
      this.showModal = false;
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    viewUnit(unitName) {
      this.$router.push({ name: "unit", params: { name: unitName } })
        .then(() => {
          window.location.reload();
        })

      this.showModal = false;
    },
    getUnits() {
        this.units = [];
        const unitIDs = this.user.profile.unitShortlistIDs;

        // Split the unitIDs into chunks of 10.
        const chunkSize = 10
        let chunk = 0
        for (let i = 0; i < unitIDs.length; i += chunkSize) {
            chunk = unitIDs.slice(i, i + chunkSize);
            this.test(chunk)
        }
    },
    async test(chunk) {
      await unitsCollection
        .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
        .onSnapshot(snap => {
          snap.forEach(doc => {
            var item = doc.data()
            item["id"] = doc.id

            // If the user has deleted this unit without closing the
            // menu, don't add it ever again.
            if (this.deletedShortlistItems.find(x => x.unitName === doc.data().name)) {
              // Do nothing as the unit should not be put back.
            } else {
              // If the unit has not yet been added to
              // this.units, add it.
              if (!this.units.some(e => e.id === doc.id)) {
                this.units.push(item)
              } else {
                // The unit has already been added to this.units, but
                // the status of the unit might have changed.
                // For this reason, we need to find this unit within this.units,
                // remove the existing unit in the list, and readd it...
                // so that the new status of the unit will reflect.

                // Find index.
                const itemId = doc.id
                const index = this.units.findIndex(object => {
                  return object.id === itemId
                });

                // If the status differs, remove the unit and readd it
                // with the new status.
                if (this.units[index].status !== doc.data().status) {
                  this.units.splice(index, 1)
                  this.units.push(item)
                }  else if (this.units[index].preReserved !== doc.data().preReserved) {
                  this.units.splice(index, 1)
                  this.units.push(item)
                }
              }
            }
          })
        })
    },
    deleteFromShortlist(unitID, unitName) {
      const shortlistData = {
        unitID: unitID,
        unitName: unitName,
      };

      // Taking into consideration the fact that the user
      // might not close the menu while the status of a unit changes,
      // we need to ensure that we do not readd the unit to the shortlist
      // in such rare instances where the user has deleted the unit
      // from his or her shortlist.
      this.deletedShortlistItems.push(shortlistData)

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayRemove(unitID),
          unitShortlist: firebase.firestore.FieldValue.arrayRemove(
            shortlistData
          ),
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            text: "Unit removed to your shortlist",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            text: error.message,
          });
        });
    },
  },
  computed: {
    totalUnits: function () {
      return this.user.profile.unitShortlist.length;
    },
    disableReserve: function () {
      if (this.settings && this.settings.launchDate && this.settings.launchDate > this.now) {
        if (this.units.isOneDayOnlyDiscount === true) {
          return false;
        }
        if (this.roleStatuses.includes(this.user.profile.role) && this.globals[0].bypassAdmin === true) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    totalUnits: function () {
      this.getUnits();
    },
  },
  async mounted() {
    console.log('mounted')
    const units = await this.getUnits()
    console.log(units)
    setInterval(this.updateNow, 1000);
  },
};
</script>
