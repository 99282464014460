<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full" x-data="{ open: true }">
    <button
      @click="displayDropdown = !displayDropdown"
      class="relative flex flex-row px-3 py-2 text-black text-green-400 items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-green-400 overflow-ellipsis hover:text-green-500 focus:text-green-500 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap;"
    >
      <!-- Filter Name Content -->
      <div
        class="text-green overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap; "
      >
        <ShareIcon class="md:hidden inline-block" :size=18 />
        <div class="inline-block ml-2 md:ml-0 md:top-0" style="justify-content: center;">
          Share
        </div>
      </div>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-on-clickaway="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right shaddow-lg"
    >
      <div class="py-5 px-5 w-52 bg-white rounded-md shadow-lg">
        <ul class="font-normal tracking-wider cursor-pointer">
          <!-- Filter Drop Down Link Content -->
          <li>
            <a
              href=""
              class="hover:text-green-500 focus:text-green-500 top-2"
              @click.prevent="handleLinkClipboardCopy"
              v-clipboard:copy="generateShareLink()"
            ><LinkIcon class="inline-block relative" /> Link</a
            >
          </li>
          <!-- Filter Drop Down Email Content -->
          <li>
            <a
              class="hover:text-green-500 focus:text-green-500 top-2"
              @click="shareEmail()"
            ><EmailIcon class="inline-block relative" /> Email</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { isEmpty } from "lodash";

import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import ShareIcon from "vue-material-design-icons/Share.vue";

import { APP_TITLE } from "../../Constants";

export default {
  // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
  name: "MainUnitReserveListFilterAspect",
  props: {
    propAspects: {
      default: Array,
    },
  },
  components: {
    LinkIcon,
    EmailIcon,
    ShareIcon,
  },
  data: () => ({
    displayDropdown: false,
  }),
  mixins: [clickaway],
  methods: {
    generateShareLink() {
      //--- LINKS ---
      // https://www.facebook.com/sharer/sharer.php?u=google.com
      // https://twitter.com/intent/tweet?url=google.com&text=
      // mailto:info@example.com?&subject=&cc=&bcc=&body=google.com%0A

      // https://www.linkedin.com/shareArticle?mini=true&url=google.com
      // https://pinterest.com/pin/create/button/?url=google.com&media=&description=

      // <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share">Share via Whatsapp</a>

      this.displayDropdown = true;

      let url = window.location.origin + this.$route.path;
      let query = this.$route.query;
      let queryBuilder = "";
      var queryCounter = 0;

      if (query !== null || !isEmpty(query)) {
        // minprice
        if (query.minprice && query.minprice > 0) {
          queryBuilder += `minprice=${query.minprice}&`;
          queryCounter += 1;
        }

        // maxprice
        if (query.maxprice && query.maxprice < 99999999) {
          queryBuilder += `maxprice=${query.maxprice}&`;
          queryCounter += 1;
        }

        // aspect
        if (query.aspect) {
          queryBuilder += `aspect=${query.aspect}&`;
          queryCounter += 1;
        }

        // view
        if (query.view) {
          queryBuilder += `view=${query.view}&`;
          queryCounter += 1;
        }

        // floor
        if (query.floor) {
          queryBuilder += `floor=${query.floor}&`;
          queryCounter += 1;
        }

        // order
        if (query.order) {
          queryBuilder += `order=${query.order}&`;
          queryCounter += 1;
        }

        // status
        if (query.status) {
          queryBuilder += `status=${query.status}&`;
          queryCounter += 1;
        }

        if (query.search) {
          queryBuilder += `search=${query.search}&`;
          queryCounter += 1;
        }

        if (query.unitType) {
          queryBuilder += `unit=${query.unitType}&`;
          queryCounter += 1;
        }

        if (queryCounter > 0) {
          url += "?";
          url += queryBuilder.slice(0, -1);
        }
      }

      return url;
    },
    handleLinkClipboardCopy() {
      this.$notify({
        group: "global",
        type: "info",
        title: "Link added to Clipboard",
      });
    },
    toggleDropdown() {
      this.displayDropdown = !this.displayDropdown;
    },
    shareEmail: function () {
      let url = this.generateShareLink();
        // .replace(/&/g, "&")
        // .replace(/ /g, "%20");
      url = encodeURIComponent(url);
      const title = APP_TITLE;
      const emailSubject = `I found apartments you may be interested in at ${title}.`;
      const emailBody = `Click here to view it online: ${url}`;
      const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
      window.location = emailLink;
    },
  },
  mounted() {
    this.selectedAspects = this.propAspects;
  },
  watch: {
    propAspects: function () {
      this.selectedAspects = this.propAspects;
    },
  },
};
</script>

<style>
svg.material-design-icon__svg {
  margin-top: -5px !important;
}
@media (max-width: 425px) {
    span.material-design-icon {
      margin-top: 0px !important;
  }
} 
</style>