export const APP_TITLE = process.env.VUE_APP_APP_TITLE
export const APP_ADDRESS = process.env.VUE_APP_APP_ADDRESS
export const APP_AREA = process.env.VUE_APP_APP_AREA
export const APP_PRIVACY_POLICY = process.env.VUE_APP_APP_PRIVACY_POLICY
export const APP_TERMS_AND_CONDITIONS_LINK = process.env.VUE_APP_APP_TERMS_AND_CONDITIONS

export const APP_META_DESCRIPTION = process.env.VUE_APP_APP_META_DESCRIPTION
export const APP_META_IMAGE = process.env.VUE_APP_APP_META_IMAGE

// export const APP_QUESTIONNAIRE_DISCOUNT_AMOUNT = process.env.VUE_APP_QUESTIONNAIRE_DISCOUNT_AMOUNT
// export const APP_QUESTIONNAIRE_DISCOUNT_AMOUNT_SHORT = process.env.VUE_APP_QUESTIONNAIRE_DISCOUNT_AMOUNT_SHORT

// export const APP_EFT_BANK = process.env.VUE_APP_BANK
// export const APP_EFT_ACCOUNT_HOLDER = process.env.VUE_APP_ACCOUNT_HOLDER
// export const APP_EFT_ACCOUNT_NUMBER = process.env.VUE_APP_ACCOUNT_NUMBER
// export const APP_EFT_BRANCH_CODE = process.env.VUE_APP_BRANCH_CODE
// export const APP_EFT_REFERANCE = process.env.VUE_APP_REFERANCE
// export const APP_EFT_POP = process.env.VUE_APP_POP

  // export const GTM = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID

// export const PUBLIC_ASSETS_UNITS_LAYOUT_URL_BASE = process.env.VUE_APP_PUBLIC_ASSETS_UNITS_LAYOUT_URL_BASE

export const ASSET_PAGE_BANNER_IMAGE = process.env.VUE_APP_ASSET_PAGE_BANNER_IMAGE
// export const ASSET_LOGIN_IMAGE = process.env.VUE_APP_ASSET_LOGIN_IMAGE
// export const ASSET_LOGO_IMAGE = process.env.VUE_APP_ASSET_LOGO_IMAGE
// export const ASSET_ADMIN_LOGO_IMAGE = process.env.VUE_APP_ASSET_ADMIN_LOGO_IMAGE


export const PAYFAST_ACTION_URL = process.env.VUE_APP_PAYFAST_ACTION_URL
export const PAYFAST_MERCHANT_ID = process.env.VUE_APP_PAYFAST_MERCHANT_ID
export const PAYFAST_MERCHANT_KEY = process.env.VUE_APP_PAYFAST_MERCHANT_KEY
export const PAYFAST_AMOUNT = process.env.VUE_APP_PAYFAST_AMOUNT
export const PAYFAST_RETURN_URL = process.env.VUE_APP_PAYFAST_RETURN_URL
export const PAYFAST_CANCEL_URL = process.env.VUE_APP_PAYFAST_CANCEL_URL
export const PAYFAST_NOTIFY_URL = process.env.VUE_APP_PAYFAST_NOTIFY_URL

export const SENDTESTMAIL_URL = process.env.VUE_APP_SENDTESTMAIL_URL
export const PAYSTACK_PUBLIC_KEY = process.env.VUE_APP_PAYSTACK_PUBLIC_KEY

export const PENDING_NOTIFY_URL = process.env.VUE_APP_PENDING_NOTIFY_URL
export const GA_REALTIME_URL = process.env.VUE_APP_GA_REALTIME_URL
export const GA_PAGEVIEWS_URL = process.env.VUE_APP_GA_PAGEVIEWS_URL
export const GA_ALL_USERS_URL = process.env.VUE_APP_GA_ALL_USERS_URL
export const GA_NEW_USERS_URL = process.env.VUE_APP_GA_NEW_USERS_URL
export const GA_AVG_SESSION_DURATION_URL = process.env.VUE_APP_GA_AVG_SESSION_DURATION_URL
export const GTM = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN


export const INTEREST_RATE = 7.25
export const FILTER_INCREMENT = 50000
export const FILTER_INCREMENT_MILLION = 100000
export const FILTER_BOUND = 2000000
export const RENTAL_GUARANTEED_DEFAULT = "1 YR"
export const OVERRIDE_ACTION_BUTTON_DEFAULT = "More Info"
export const GLOBAL_OVERRIDE_ACTION_BUTTON_DEFAULT = "More Info"

export const APP_ADMIN_PAGINATION = process.env.VUE_APP_APP_ADMIN_PAGINATION
export const APP_ONE_DAY_ONLY = process.env.VUE_APP_APP_ONE_DAY_ONLY

export const APP_COOKIE_UTM_SOURCE = process.env.VUE_APP_APP_COOKIE_UTM_SOURCE

export const APP_VERSION = process.env.VUE_APP_APP_VERSION

export const PAGINATED_TOTAL_UNITS_URL = process.env.VUE_APP_PAGINATED_TOTAL_UNITS_URL
export const DELETE_OPTIMISTIC_LOCKS_URL = process.env.VUE_APP_DELETE_OPTIMISTIC_LOCKS_URL
export const FOO_OPTIMISTIC_LOCKS_URL = process.env.VUE_APP_FOO_OPTIMISTIC_LOCKS_URL
