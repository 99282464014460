<template>
  <!-- Aspect === Direction Facing / Direction -->
  <!-- Filter Menu Item Content -->
  <div class="relative w-full" x-data="{ open: true }">
    <button
      @click="displayDropdown = !displayDropdown"
      class="relative flex flex-row px-3 py-2 text-black items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-gray-300 overflow-ellipsis hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span v-if="selectedViews.length === 0">
        {{title}}
      </span>

      <span
      v-else
      class="flex flex-row overflow-ellipsis"
      style="overflow: hidden; white-space: nowrap"
      >
        {{selectedViews.length}} <SelectedIcon :size="20" class="ml-2 text-primary" />
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-on-clickaway="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right shaddow-lg"
    >
      <div class="pt-8 pb-5 px-5 w-52 bg-white rounded-md shadow-lg">
        <ul class="font-normal tracking-wider cursor-pointer">
          <li
          v-for="view in viewsList"
          :key="view"
          class="mb-2">
            <label class="inline-flex items-center">
              <input
              v-model="selectedViews"
              :value="view"
              @change="setView"
              type="checkbox"
              class="rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
              >
              <span class="ml-2">{{view}}</span>
              </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import SelectedIcon from 'vue-material-design-icons/CheckboxMarked'
//import {unitsCollection} from "@/firebase";
import { mapGetters } from "vuex";

export default {
  // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
  name: "MainUnitReserveListFilterAspect",
  props: {
    propViews: {
      default: Array
    }
  },
  components: {
    SelectedIcon,
  },
  data: () => ({
    displayDropdown: false,
    title: 'Outlook',
    selectedViews: [],
    views: [],
  }),
  mixins: [ clickaway ],
  methods: {
      getViews(){
        let views = [];
      this.units.forEach((doc) => {
        views.push({
          view: doc.view,
        });
      });
      console.log(views)
      this.views = views;
      },
    toggleDropdown() {
      this.displayDropdown = !this.displayDropdown;
    },
    setView() {
      const views = this.selectedViews

      this.$emit('update:view-filter', views)
      Object.assign(this.$route.query, {view: encodeURI(views)})
    },
  },
  created() {
    this.getViews()
  },
  computed: {
      ...mapGetters({
      units: "units",
    }),
    viewsList: function() {
      const uniqueViews = [];
      for(let i =0; i < this.views.length; i++) {
        if(uniqueViews.indexOf(this.views[i].view) === -1) {
          uniqueViews.push(this.views[i].view)
        }
      }
       const uniqueView = uniqueViews.sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (b < a) {
          return 1;
        }
        return 0;
      });
      return uniqueView
    },
  },
  mounted() {
    this.selectedViews = this.propViews
  },
  watch: {
    propViews: function() {
      this.selectedViews = this.propViews
    }
  }
};
</script>
