<template>
  <div id="app" class="antialiased">
    <notifications group="global" />
    <router-view />
    <footer class="text-sm pl-2 pr-2 py-2">
      <div class=" grid grid-cols-1 md:grid-cols-2">
        <div class="text-center md:text-left">
          &copy; 2022. {{title}}. All Rights Reserved.
        </div>
        <div class="text-center md:text-right">
          <div class="text-center md:text-right container">
            <a
              v-bind:href="urlPrivacyPolicy"
              target="_blank"
            >
              Privacy Policy
            </a>
            <p class="ml-2">
              Version: {{version}}
            </p>
          </div>
        </div>
      </div>
    </footer>
    <div v-if="componentLoading">
      <UnitsLoading />
    </div>
  </div>
</template>

<script>
import {APP_TITLE, APP_PRIVACY_POLICY, APP_VERSION} from "@/Constants";
import { settingsCollection, unitsCollection } from './firebase';
import UnitsLoading from "./components/app/MainUnitReserveListUnitsLoading.vue";
import store from '../store';
import {
  reverse
} from "lodash";
//import {reverse,} from "lodash";
export default {
  data: () => ({
    version: APP_VERSION,
    title: APP_TITLE,
    units: [],
    componentLoading: true,
    unitsFiltered: [],
    urlPrivacyPolicy: APP_PRIVACY_POLICY,
    orderBy: "default",
    orderByReverse: false,
    tourSteps: [
      {
        target: "#v-step-filter-name", // We're using document.querySelector() under the hood
        header: {
          title: "Get Started",
        },
        content: `Use the filter to find <strong>your next unit</strong>!`,
      },
      {
        target: "#v-step-profile",
        content: "Check your profile here",
      },
      {
        target: "#v-step-list-view",
        content: "You can view the units in list or table view.",
        params: {
          placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
        },
      },
    ],
  }),
  components: {
    UnitsLoading
  },
  methods: {
    getAnalytics() {
      settingsCollection.doc("analytics").onSnapshot(async (doc) => {
        await store.dispatch('handleRealTimeTotal', doc.data().realtime);
      });
    },
    getUnits(orderBy, orderByReverse) {
      unitsCollection
          .where("displayOnHomePage", "==", true)
          .onSnapshot(async (snap) => {
            const units = [];
            snap.forEach((doc) => {
              const data = doc.data();
              units.push({
                id: doc.id,
                unitType: doc.data().type,
                ...data
              });
            });
             if (orderByReverse) {
             this.units = reverse(units);
          } else {  
            this.units = units;
            this.unitsFiltered = units;
            this.unitsFiltered.sort((a, b) => (a.name > b.name) ? 1 : -1)
            this.units.sort((a, b) => (a.name > b.name) ? 1 : -1)
            await store.dispatch('handleUnits', this.units);
            // to make units show as what filter does
            await store.dispatch('handleFilteredUnits', this.units);
            this.componentLoading = false;
          }
          });
        },
      },
    watch: {
    orderBy() {
      this.units(this.orderByDBSetting, this.orderByReverse);
    },
    orderByReverse() {
      this.units(this.orderByDBSetting, this.orderByReverse);
    },
  },
  created() {
    this.getAnalytics();
    this.getUnits();
  },
};
</script>

<style>
.container {
  display: flex;
  justify-content: flex-end;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap");

/* === Global === */

#app {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 5px;
  background: #222; /* make scrollbar transparent */
}

::-webkit-scrollbar-thumb {
  width: 10px;
  background: #efefef; /* make scrollbar transparent */
}

/* === APP === */
/* Buttons */
.lb-primary-button {
  @apply px-5 py-2 text-white text-sm tracking-widest font-semibold uppercase bg-primary hover:bg-primarylight rounded;
}

.lb-warning-button {
  @apply p-2 text-white text-sm tracking-widest font-semibold uppercase bg-red-500 hover:bg-red-400 rounded;
}

.lb-warning-button-disabled {
  @apply p-2 text-white text-sm tracking-widest font-semibold uppercase bg-red-100 rounded cursor-not-allowed;
}

.lb-view-unit {
  @apply px-5 py-2 text-white text-sm uppercase bg-secondary hover:bg-secondarylight rounded-lg;
}

.lb-dialog-close-btn {
  @apply px-6 py-3 font-bold tracking-wider uppercase text-sm rounded outline-none text-black bg-gray-400 border hover:bg-gray-500 focus:outline-none;
}

.lb-submit-btn {
  @apply px-4 py-2 text-white font-light tracking-wider bg-primary rounded;
}

.lb-dialog-close-btn-x {
  @apply fixed z-50 top-3 right-3 px-2 text-white text-4xl leading-none font-semibold border-0 bg-primary rounded-full outline-none focus:outline-none;
}

/* Forms */
.lb-icon-text-icon {
  @apply w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center;
}

.lb-icon-text-label {
  @apply text-xs font-semibold px-1;
}

.lb-text-label {
  @apply text-xs font-semibold;
}

/* Tooltips */
.tooltip {
  visibility: hidden;
  position: absolute;
  @apply w-32 text-center rounded shadow-lg p-1 bg-secondary text-white;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}

/* Dialog */
.lb-dialog-background {
  @apply opacity-50 fixed inset-0 z-31 bg-black;
}

/* TODO: bug - some classes not being applied e.g. rounded */
/* .lb-icon-text-input {
  @apply -ml-10 pl-10 pr-3 py-2 border border-gray-400 rounded outline-none focus:border-primary focus:ring-primary;
} */

.input-tel__input[data-v-e59be3b4] {
  @apply border-2 border-gray-400 outline-none focus:border-primary focus:ring-primary;
}

/* Pills */
.lb-icon-pill {
  @apply px-3 py-2 text-black bg-gray-300 rounded-full;
}

/* === ADMIN === */
/* Buttons */
.lb-admin-primary-button-large {
  @apply py-3 px-2 font-semibold text-white text-center bg-adminprimary hover:bg-adminprimarylight rounded-lg shadow;
}

.lb-admin-secondary-button-large {
  @apply py-3 px-2 font-semibold text-white text-center bg-adminsecondary hover:bg-adminsecondarylight rounded-lg shadow duration-150;
}

/* Forms */
.lb-admin-form-input {
  @apply block;
  border-radius: 0.25rem !important;
}
.lb-admin-form-input-select {
  @apply block;
  width: 75% !important;
  border-radius: 0.25rem !important;
}

/* Navigation */
.lb-admin-man-nav-section-heading {
  @apply mt-5 text-sm text-white font-semibold tracking-wide uppercase;
}

.lb-admin-main-nav-link {
  @apply flex flex-row items-center;
  margin-top: 3px;
}

.lb-admin-main-nav-link svg {
  @apply p-1 text-white;
}

.lb-admin-main-nav-link p {
  @apply ml-2 text-white font-semibold tracking-wide capitalize;
  font-size: 14px;
}

.lb-admin-main-nav-link.router-link-active:not(.no-highlight) {
  @apply bg-white bg-opacity-50 rounded shadow;
}

.lb-admin-main-nav-logout {
  @apply flex flex-row justify-center items-center mt-5 p-2 font-semibold text-red-800 hover:text-white hover:bg-red-800 border border-red-800 rounded cursor-pointer duration-150;
}

/* === Dashboard === */
.lb-admin-data-summary-card {
  @apply flex flex-col w-full lg:w-1/4 px-2 mb-5;
}

.lb-admin-data-summary-card .card {
  @apply p-5 bg-white rounded-xl shadow-lg;
}

.lb-admin-data-summary-card .title {
  @apply text-sm text-gray-600 font-semibold;
}

.lb-admin-data-summary-card .content {
  @apply text-2xl font-semibold;
}

.lb-admin-data-summary-card .sub-content {
  @apply text-sm font-semibold;
}

.lb-admin-data-summary-card.green .sub-content {
  @apply text-green-500;
}

.lb-admin-data-summary-card.yellow .sub-content {
  @apply text-yellow-500;
}

.lb-admin-data-summary-card.blue .sub-content {
  @apply text-blue-500;
}

.lb-admin-data-summary-card.pink .sub-content {
  @apply text-pink-500;
}

.o-btn {
  @apply bg-primary px-4 py-2 text-white font-semibold rounded;
}

.o-switch__check--checked {
  @apply bg-primary !important;
}

button.slick-prev {
  top: 108.4% !important;
  width: 74px !important;
  height: 30px !important;
  transform: translate(0, -50%);
  color: transparent !important;
  background: transparent !important;
  left: 40.6% !important;
}
button.slick-next {
  top: 108.4% !important;
  width: 74px !important;
  height: 30px !important;
  transform: translate(0, -50%);
  color: transparent !important;
  background: transparent !important;
  right: 40.6% !important;
}

button.slick-prev:before, button.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: transparent!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

container-timer {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  text-transform: uppercase;
  letter-spacing: 0.05em;
  flex-wrap: nowrap;
  flex-direction: row;
}

timer-text {
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}
@media only screen and (max-width: 2560px) {
  button.slick-prev {
    top: 104.4% !important;
    left: 45% !important;
  }

  button.slick-next {
    top: 104.4% !important;
    right: 45% !important;
  }
}
@media only screen and (max-width: 2460px) {
  button.slick-prev {
    top: 104.5% !important;
    left: 44.9% !important;
  }

  button.slick-next {
    top: 104.5% !important;
    right: 44.9% !important;
  }
}
@media only screen and (max-width: 2360px) {
  button.slick-prev {
    top: 105% !important;
    left: 44.5% !important;
  }

  button.slick-next {
    top: 105% !important;
    right: 44.5% !important;
  }
}
@media only screen and (max-width: 2260px) {
  button.slick-prev {
    top: 104.9% !important;
    left: 44.5% !important;
  }

  button.slick-next {
    top: 104.9% !important;
    right: 44.5% !important;
  }
}
@media only screen and (max-width: 2160px) {
  button.slick-prev {
    top: 105.5% !important;
    left: 43.9% !important;
  }

  button.slick-next {
    top: 105.5% !important;
    right: 43.9% !important;
  }
}
@media only screen and (max-width: 2060px) {
  button.slick-prev {
    top: 105.5% !important;
    left: 43.9% !important;
  }

  button.slick-next {
    top: 105.5% !important;
    right: 43.9% !important;
  }
}
@media only screen and (max-width: 1960px) {
  button.slick-prev {
    top: 105.9% !important;
    left: 43.5% !important;
  }

  button.slick-next {
    top: 105.9% !important;
    right: 43.5% !important;
  }
}
@media only screen and (max-width: 1860px) {
  button.slick-prev {
    top: 106.2% !important;
    left: 43.2% !important;
  }

  button.slick-next {
    top: 106.2% !important;
    right: 43.2% !important;
  }
}
@media only screen and (max-width: 1760px) {
  button.slick-prev {
    top: 106.5% !important;
    left: 42.5% !important;
  }

  button.slick-next {
    top: 106.5% !important;
    right: 42.5% !important;
  }
}
@media only screen and (max-width: 1660px) {
  button.slick-prev {
    top: 106.9% !important;
    left: 42.2% !important;
  }

  button.slick-next {
    top: 106.9% !important;
    right: 42.2% !important;
  }
}
@media only screen and (max-width: 1560px) {
  button.slick-prev {
    top: 107.4% !important;
    left: 41.9% !important;
  }

  button.slick-next {
    top: 107.4% !important;
    right: 41.9% !important;
  }
}
@media only screen and (max-width: 1460px) {
  button.slick-prev {
    top: 108% !important;
    left: 41% !important;
  }

  button.slick-next {
    top: 108% !important;
    right: 41% !important
  }
}
@media only screen and (max-width: 1360px) {
  button.slick-prev {
    top: 108.1% !important;
    left: 40.5% !important;
  }

  button.slick-next {
    top: 108.1% !important;
    right: 40.5% !important;
  }
}
@media only screen and (max-width: 1260px) {
  button.slick-prev {
    top: 109.1% !important;
    left: 39.9% !important;
  }

  button.slick-next {
    top: 109.1% !important;
    right: 39.9% !important;
  }
}
@media only screen and (max-width: 1200px) {
  button.slick-prev {
    top: 109.4% !important;
    left: 39% !important;
  }

  button.slick-next {
    top: 109.4% !important;
    right: 39% !important;
  }
}
@media only screen and (max-width: 1024px) {
  button.slick-prev {
    top: 110.4% !important;
    left: 37% !important;
  }

  button.slick-next {
    top: 110.4% !important;
    right: 37% !important;
  }
}
@media only screen and (max-width: 896px) {
  button.slick-prev {
    top: 108.4% !important;
    left: 40% !important;
  }

  button.slick-next {
    top: 108.4% !important;
    right: 40% !important;
  }
}
@media only screen and (max-width: 768px) {
  button.slick-prev {
    top: 109.9% !important;
    left: 38.6% !important;
  }

  button.slick-next {
    top: 109.9% !important;
    right: 38.6% !important;
  }

  container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .25rem .5rem;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
  }

  timer-text {
    padding: .25rem .5rem;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 596px) {
  button.slick-prev {
    top: 112.9% !important;
    left: 35.6% !important;
  }

  button.slick-next {
    top: 112.9% !important;
    right: 35.6% !important;
  }
}
@media only screen and (max-width: 425px) {
  .container {
    display: flex;
    justify-content: center;
  }

  button.slick-prev {
    top: 117.9% !important;
    left: 29.6% !important;
  }

  button.slick-next {
    top: 117.9% !important;
    right: 29.6% !important;
  }

  container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}
@media only screen and (max-width: 415px) {
  button.slick-prev {
    top: 118.9% !important;
    left: 29% !important;
  }

  button.slick-next {
    top: 118.9% !important;
    right: 29% !important;
  }

  container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}
@media only screen and (max-width: 375px) {
  button.slick-prev {
    top: 120.8% !important;
    left: 27% !important;
  }

  button.slick-next {
    top: 120.8% !important;
    right: 27% !important;
  }

  container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}
@media only screen and (max-width: 320px) {
  button.slick-prev {
    top: 124% !important;
    left: 23% !important;
  }

  button.slick-next {
    top: 124% !important;
    right: 23% !important;
  }

  container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}
</style>
