<template>
  <div>
    <button
      class="mb-4 block w-full p-2 text-black font-semibold rounded hover:text-white hover:bg-primary cursor-pointer"
      v-on:click="showDialog"
    >
      <span>Contact Us</span>
    </button>
  </div>
</template>

<script>
import { bus } from "../../main.js";

export default {
  name: "MainNavBarContactButton",
  methods: {
    showDialog() {
      bus.$emit("update:unit-contact-data", { open: true });
    },
  },
};
</script>