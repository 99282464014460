<template>
  <div id="main-unit-reserve-list-cards">
    <div
      class="flex flex-wrap items-center justify-center">
      <!-- Content -->
      <div
        v-for="unit in units.slice(0, this.initialAmount)"
        :key="unit.id"
        class="flex-shrink-0 cardMargin md:mx-2 xl:mx-5 mb-6 relative overflow-hidden bg-gray-100 rounded-lg shadow-lg card-view-container"
      >
        <template v-if="unit.status !== 'sold' && unit.status !== 'reserved'">
          <!-- Shortlist Content -->
          <AddToShortlist
              class="absolute top-2 left-2 z-10"
              :unitID="unit.id"
              :unitName="unit.name"
              :show="true"
              :itemView="false"
              :showTooltip="false"
          />

          <!-- Guaranteed Rental Content -->
          <span
              v-if="unit.isGuaranteedRental"
              class="has-tooltip absolute top-2 right-2 z-10 p-1 font-semibold text-white rounded bg-green-500"
          >
            GR
            <span class="tooltip -bottom-5 right-10 font-normal">
              Guaranteed Rental
            </span>
          </span>

          <!-- Image Content -->
          <img class="image-container" v-if="unit.mainImageURL" :src="unit.mainImageURL" alt="Unit Image">
          <img class="image-container" v-else :src="'../../assets/example-image-2-thumbnail.jpeg'" alt="Unit Image" />

          <!-- Unit Details Content -->
          <div class="relative text-white px-6 pb-6 mt-6">
            <div class="flex justify-between">
              <div class="text-left">
                <!-- Unit Name Content -->
                <span
                    v-if="unit.name"
                    class="block font-bold text-black"
                >
                {{ unit.name }}
              </span>

                <!-- Unit Floor Content -->
                <span
                    v-if="unit.floor"
                    class="block font-semibold text-sm text-black"
                >
                {{ unit.floor }} Floor
              </span>

                <!-- Unit Direction facing and Facing View Content -->
                <!-- Direction facing === aspect -->
                <span
                    v-if="unit.aspect"
                    class="unit-view-text"
                >
                <!-- <p
                    class="mr-1"
                    
                >
                  {{ unit.aspect }}
                </p> -->
                <p>
                   {{ unit.view }}
                </p>
              </span>
              </div>

              <!-- Unit Price Content -->
              <div class="text-right">
                <UnitPrice :unit="unit" :settings="settings" :bigPrice="false" :normalPrice="true" :show="true" :tableStyle="false"/>
              </div>
            </div>
          </div>

          <!-- Unit Spec Content -->
          <div class="unit-spec-icons-container">
            <div
                v-if="unit.bedrooms && unit.bedrooms > 0"
                class="has-tooltip unit-spec-icon"
            >
              <BedroomIcon />
              <span class="font-semibold">{{ unit.bedrooms }}</span>
              <span class="tooltip -top-10 left-0">Bedrooms</span>
            </div>

            <div
                v-if="unit.bathrooms && unit.bathrooms > 0"
                class="has-tooltip unit-spec-icon"
            >
              <BathroomIcon />
              <span class="font-semibold">{{ unit.bathrooms }}</span>
              <span class="tooltip -top-10 left-0">Bathrooms</span>
            </div>

            <div
                v-if="unit.parking && unit.parking > 0 && title === 'The One Stellenbosch'"
                class="has-tooltip unit-spec-icon"
            >
              <CarIcon />
              <span class="font-semibold">{{ unit.parking }}</span>
              <span class="tooltip -top-10 left-0">Parking Spots</span>
            </div>

            <div
                v-if="unit.storeroom && unit.storeroom > 0 && title === 'The Carrington'"
                class="has-tooltip unit-spec-icon"
            >
              <StoreIcon />
              <span class="font-semibold">{{ unit.storeroom }}</span>
              <span class="tooltip -top-10 left-0">Storeroom</span>
            </div>

            <div
                v-if="
              unit.internalArea &&
              unit.internalArea > 0 &&
              unit.externalArea > 0
            "
                class="has-tooltip unit-spec-icon"
            >
              <TotalIntAreaIcon />
              <span class="font-semibold">{{ unit.internalArea }}m&sup2;</span>
              <span class="tooltip -top-10 left-0">Internal Area</span>
            </div>

            <div
                v-if="unit.externalArea && unit.externalArea > 0"
                class="has-tooltip unit-spec-icon"
            >
              <TotalExtAreaIcon />
              <span class="font-semibold">{{ unit.externalArea }}m&sup2;</span>
              <span class="tooltip -top-10 left-0">External Area</span>
            </div>

            <div
                v-if="unit.totalArea && unit.totalArea > 0"
                class="has-tooltip unit-spec-icon"
            >
              <TotalAreaIcon />
              <span class="font-semibold">{{ unit.totalArea }}m&sup2;</span>
              <span class="tooltip -top-10 -left-14">Total Area</span>
            </div>

            <div
                v-if="unit.isAircon && unit.isAircon === true"
                class="has-tooltip unit-spec-icon"
            >
              <SnowflakeIcon />
              <span class="font-semibold">Yes</span>
              <span class="tooltip -top-10 -left-20">Aircon</span>
            </div>
          </div>

          <!-- View Content -->
          <div class="flex flex-row mt-5">
            <div class="w-2/4">
              <button
                  v-if="unit.customIdViewButton"
                  class="bg-secondary w-full items-center px-4 py-2 text-white text-md font-semibold uppercase tracking-wider duration-150 hover:bg-secondarylight"
                  v-on:click="
                $emit('update:unit-dialog-data', { open: true, data: unit })
              "
                  :id="unit.customIdViewButton"
              >
                View
              </button>

              <button
                  v-else
                  class="bg-secondary w-full items-center px-4 py-2 text-white text-md font-semibold uppercase tracking-wider duration-150 hover:bg-secondarylight"
                  v-on:click="
                $emit('update:unit-dialog-data', { open: true, data: unit })
              "
              >
                View
              </button>
            </div>

            <!-- Unit Status Content -->
            <div class="w-2/4" v-if="settings.isGlobalOverrideAction === true">
              <a
                :href="settings.globalOverrideAction"
                target="_blank"
              >
                <button
                  class="bg-primary items-center w-full py-2 text-black fontSizeBtn font-semibold uppercase tracking-wider"
                >
                  {{settings.globalOverrideActionButton}}
                </button>
              </a>
            </div>

            <div class="w-2/4" v-else>
              <div  v-if="unit.isOverrideAction === true">
                <a
                  :href="unit.unitOverrideAction"
                  target="_blank"
                >
                  <button
                    class="bg-primary items-center w-full px-4 py-2 text-black text-md font-semibold uppercase tracking-wider"
                  >
                    {{unit.unitOverrideActionButton}}
                  </button>
                </a>
              </div>

              <div class="has-tooltip " v-else-if="settings.allowReserve">
                <button
                  disabled
                  class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed"
                  v-if="unit.status === 'pending' || unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment'" 
                >
                  Pending
                </button>
                <!-- When the unit is pre-reserved this pending button will take effect -->
                <button
                  disabled
                  class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed"
                  v-if="unit.status === 'available' && !disableReserve && unit.preReserved"
                >
                  Pending
                </button>
                <button
                  disabled
                  class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed"
                  v-if="unit.status === 'reserved'"
                >
                  Reserved
                </button>

                <button
                  disabled
                  class="bg-gray-500 w-full items-center px-4 py-2 text-black text-md font-semibold uppercase tracking-wider cursor-not-allowed"
                  v-if="unit.status === 'sold'"
                >
                  Sold
                </button>

                <button
                  class="bg-green-500 hover:bg-green-600 items-center w-full px-4 py-2 text-white text-md font-semibold uppercase tracking-wider duration-150"
                  @click="unitReserve(unit.name)"
                  v-if="unit.status === 'available' && !disableReserve && !unit.preReserved"
                >
                  Reserve
                </button>
      
                <button
                  class="relative bg-gray-400 items-center w-full px-4 py-2 text-white tracking-wider duration-150"
                  v-if="unit.status === 'available' && disableReserve"
                >
                  <span class="text-md font-semibold uppercase">Reserve</span>
                  <span class="tooltip  -bottom-0 -right-0">This reserve button will become active once sales go live</span>
                </button>
              </div>

              <div v-else>
                <button
                  class="bg-primary items-center w-full px-4 py-2 text-black text-md font-semibold uppercase tracking-wider"
                  @click.prevent="openContactDialog()"
                >
                  Contact
                </button>
              </div>
            </div>

          </div>
        </template>
        <div v-else-if="unit.status === 'reserved'" class="text-center reserved-card">
          <p class="text-white font-bold reserved-label">
            {{ unit.displayName ? unit.displayName : unit.name }}
          </p>
          <p class="text-white font-bold uppercase sold-label">Reserved</p>
        </div>
        <div v-else class="text-center sold-card">
          <p class="text-white font-bold sold-label">
            {{ unit.displayName ? unit.displayName : unit.name }}
          </p>
          <p class="text-white font-bold uppercase sold-label">Sold</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
import { mapGetters } from "vuex";
import {APP_TITLE} from "@/Constants";
import UnitPrice from "./shared/UnitPrice.vue";
import AddToShortlist from "./AddToShortlist.vue";
import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import StoreIcon from "vue-material-design-icons/Store.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";
import TotalExtAreaIcon from "vue-material-design-icons/FlipToBack.vue";
import TotalIntAreaIcon from "vue-material-design-icons/FlipToFront.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";
// import {unitsCollection} from "@/firebase";
export default {
  name: "MainUnitReserveListCards",
  components: {
    UnitPrice,
    AddToShortlist,
    BathroomIcon,
    BedroomIcon,
    CarIcon,
    StoreIcon,
    TotalExtAreaIcon,
    TotalIntAreaIcon,
    TotalAreaIcon,
    SnowflakeIcon,
  },
  props: {
    // units: {
    //   default: [],
    // },
    settings: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: APP_TITLE,
      now: new Date(),
      initialAmount: 50,
      addedAmount: 150,
      roleStatuses: ['admin', 'admin-viewonly', 'superadmin']
    };
  },
  methods: {
      handleScroll(){
       if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight -250) {
       console.log('bottom of the page.')
       this.addToUnitsTotal()
        }
    },
    addToUnitsTotal() {
      this.initialAmount = this.initialAmount + this.addedAmount
      console.log(this.initialAmount)
    },
    updateNow() {
      this.now = Date.now();
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    unitReserve(unitName) {
      this.$router.push({ name: "reserve", params: { name: unitName } });
    },
    openContactDialog() {
      bus.$emit("update:unit-contact-data", { open: true });
    },
  },
   created() {
  window.addEventListener('scroll', this.handleScroll);
  },
   destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      user: "user",
      units: "units",
    }),
    disableReserve: function () {
        if (this.settings && this.settings.launchDate && this.settings.launchDate > this.now) {
          if (this.units.isOneDayOnlyDiscount === true) {
            return false;
          }
          if (this.roleStatuses.includes(this.user.profile.role) && this.settings.bypassAdmin === true) {
            return false;
          }
          return true;
        }
      return false;
    },
  },
  mounted() {
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style lang="css" scoped>
.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.card-view-container {
  max-width: fit-content;
}
.image-container {
  width: 24rem;
  height: 15rem;
  object-fit: cover;
}
.unit-spec-icons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}
.unit-spec-icon {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 .45rem;
  text-align: center;
}
.unit-view-text {
  display: flex;
  flex-direction: row;
  font-weight: 400;
  /*If string is long*/
  /*font-weight: 600;*/
  font-size: 0.875rem;
  /*If string is long*/
  /*font-size: 0.675rem;*/
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.reserved-card {
  min-width: calc(100vw - 12px);
  height: 160px;
  background: #6c727f;
  width: 100%;
}
.sold-card {
  min-width: calc(100vw - 12px);
  height: 160px;
  background: #3e404c;
  width: 100%;
}
.reserved-label {
  position: relative;
  top: 60px;
}
.sold-label {
  position: relative;
  top: 60px;
}

.fontSizeBtn {
  font-size: 15.9px;
}

@media only screen and (max-width: 415px) {
  .image-container {
    width: 24rem;
  }

  .unit-spec-icons-container {
    padding: 0 1rem;
  }
  .unit-spec-icon {
    padding: 0 .4rem;
  }
}
@media only screen and (max-width: 425px) {
  .cardMargin {
    margin: 0 1rem 1.5rem 1rem;
  }

  .reserved-card {
    min-width: auto;
    height: 461px;
    width: 384px;
  }

  .sold-card {
    min-width: auto;
    height: 461px;
    width: 384px;
  }

  .reserved-label {
    position: relative;
    top: 200px;
  }

  .sold-label {
    position: relative;
    top: 200px;
  }

  .image-container {
    width: 24rem;
  }

  .unit-spec-icons-container {
    padding: 0 1rem;
  }

  .unit-spec-icon {
    padding: 0 .45rem;
  }
}
  @media only screen and (max-width: 768px) {
  .unit-spec-icons-container {
    padding: 0 1rem;
  }
  .unit-spec-icon {
    padding: 0 .2rem;
  }
  /* .reserveTooltip{
    display: none;
  } */
}
@media only screen and (min-width: 769px) {
  .reserved-card {
    min-width: auto;
    height: 460px;
    width: 384px;
  }

  .sold-card {
    min-width: auto;
    height: 460px;
    width: 384px;
  }
  .reserved-label {
    position: relative;
    top: 200px;
  }

  .sold-label {
    position: relative;
    top: 200px;
  }
}
@media only screen and (max-width: 375px) {
  .reserved-card {
    min-width: auto;
    height: 485px;
    width: 384px;
  }

  .sold-card {
    min-width: auto;
    height: 461px;
    width: 384px;
  }

  .reserved-label {
    position: relative;
    top: 200px;
  }

  .sold-label {
    position: relative;
    top: 200px;
  }

  .image-container {
    width: 24rem;
  }

  .unit-spec-icons-container {
    padding: 0 1rem;
  }
  .unit-spec-icon {
    padding: 0 .3rem;
  }
}
@media only screen and (max-width: 320px) {
  .reserved-card {
    min-width: auto;
    height: 481px;
    width: 384px;
  }

  .sold-card {
    min-width: auto;
    height: 481px;
    width: 384px;
  }

  .reserved-label {
    position: relative;
    top: 200px;
  }

  .sold-label {
    position: relative;
    top: 200px;
  }

  .image-container {
    width: 24rem;
  }
}

</style>
