<template>
  <div>
    <!--Modal-->
    <button
      class="indexed lb-dialog-close-btn-x"
      v-if="showDialog"
      v-on:click="toggleModal()"
    >
      ×
    </button>

    <div
      v-if="showDialog"
      class="fixed indexed top-10 left-1/2 transform -translate-x-1/2 w-full lg:px-0 lg:max-w-3xl overflow-x-hidden overflow-y-auto p-1 inset-0 outline-none focus:outline-none"
    >
      <div class="relative w-auto mx-auto bg-white rounded-lg shadow-lg">
        <!--content-->
        <div
          class="border-0 relative flex flex-col w-full outline-none focus:outline-none"
        >
          <div class="relative flex-auto p-6">
            <div class="flex flex-wrap">
              <div class="w-full">
                <ul class="flex mb-0 list-none flex-row flex-wrap pt-3 pb-4">
                  <!--Tab 1-->
                  <li class="last:mr-0 w-1/2 text-center">
                    <div class="pr-1">
                      <a
                        class="text-xs font-bold uppercase py-3 rounded block leading-normal border border-primary"
                        v-on:click="toggleTabs(1)"
                        v-bind:class="{
                          'text-black bg-white': openTab !== 1,
                          'text-white bg-secondary': openTab === 1,
                        }"
                      >
                        <i class="fas fa-space-shuttle text-base mr-1"></i>
                        Message
                      </a>
                    </div>
                  </li>
                  <!--Tab 2-->
                  <li class="last:mr-0 w-1/2 text-center">
                    <div class="pl-1">
                      <a
                        class="text-xs font-bold uppercase py-3 rounded block leading-normal border border-primary"
                        v-on:click="toggleTabs(2)"
                        v-bind:class="{
                          'text-black bg-white': openTab !== 2,
                          'text-white bg-secondary': openTab === 2,
                        }"
                      >
                        <i class="fas fa-cog text-base mr-1"></i>
                        Sales Consultants
                      </a>
                    </div>
                  </li>
                </ul>
                <div
                  class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6"
                >
                  <div class="px-4 py-5 flex-auto">
                    <div class="tab-content tab-space">
                      <!--Tab 1 Content-->
                      <div
                        v-bind:class="{
                          hidden: openTab !== 1,
                          block: openTab === 1,
                        }"
                      >
                        <MainNavBarContactButtonMessageTab />
                      </div>
                      <!--Tab 2 Content-->
                      <div
                        v-bind:class="{
                          hidden: openTab !== 2,
                          block: openTab === 2,
                        }"
                      >
                        <MainNavBarContactButtonContactsTab />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showDialog"
      @click="showDialog = false"
      class="opacity-25 fixed inset-0 z-40 bg-black"
    ></div>
  </div>
</template>

<script>
import { bus } from "../../main.js";

import MainNavBarContactButtonContactsTab from "./MainNavBarContactButtonContactsTab.vue";
import MainNavBarContactButtonMessageTab from "./MainNavBarContactButtonMessageTab.vue";

export default {
  name: "MainNavBarContactModal",
  components: {
    MainNavBarContactButtonContactsTab,
    MainNavBarContactButtonMessageTab,
  },
  data() {
    return {
      showDialog: false,
      unitName: null,
      openTab: 1,
    };
  },
  methods: {
    toggleModal: function () {
      this.showDialog = !this.showDialog;
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
  created() {
    // TODO: add unit name in event data
    var vm = this;
    bus.$on("update:unit-contact-data", function (e) {
      vm.showDialog = e.open;
    });
  },
};
</script>
<style scoped>
.indexed {
  z-index: 150;
}
</style>
