<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full" x-data="{ open: true }">
    <button
      @click="displayDropdown = !displayDropdown"
      class="relative flex flex-row px-3 py-2 text-black items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-gray-300 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span v-if="selectedFloors.length === 0">
        {{title}}
      </span>

      <span
      v-else
      class="flex flex-row overflow-ellipsis"
      style="overflow: hidden; white-space: nowrap"
      >
        {{selectedFloors.length}} <SelectedIcon :size="20" class="ml-2 text-primary" />
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-on-clickaway="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right shaddow-lg"
    >
      <div class="pt-8 pb-5 px-5 w-52 bg-white rounded-md shadow-lg">
        <ul class="font-normal tracking-wider cursor-pointer">
          <li
          v-for="floor in floorList"
          :key="floor"
          class="mb-2">
            <label class="inline-flex items-center">
              <input
              v-model="selectedFloors"
              :value="floor"
              @change="setFloor"
              type="checkbox"
              class="rounded border-gray-300 text-primary shadow-sm focus:primary focus:ring focus:ring-primary focus:ring-opacity-50"
              >
              <span class="ml-2">{{floor}}</span>
              </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import SelectedIcon from 'vue-material-design-icons/CheckboxMarked'
//import {unitsCollection} from "@/firebase";
import { mapGetters } from "vuex";


export default {
  name: "MainUnitReserveListFilterFloor",
  props: {
    propFloors: {
      default: Array
    }
  },
  components: {
    SelectedIcon,
  },
  data: () => ({
    displayDropdown: false,
    title: 'Floor',
    selectedFloors: [],
    floors: [],
  }),
  mixins: [ clickaway ],
  methods: {
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='desc'?'asc':'desc';
      }
      this.currentSort = s;
    },
    toggleDropdown() {
      this.displayDropdown = !this.displayDropdown;
    },
    setFloor() {

      const floors = this.selectedFloors

      this.$emit('update:floor-filter', floors)
      Object.assign(this.$route.query, {floor: encodeURI(floors)})
    },
  },
  created() {
    let floors = [];
      this.units.forEach((doc) => {
        floors.push({
          floor: doc.floor,
        });
      });
      this.floors = floors; 
  },
  computed: {
       ...mapGetters({
      units: "filteredUnits",
    }),
    floorList: function() {
      const uniqueFloors = [];
      for(let i =0; i < this.floors.length; i++) {
        if(uniqueFloors.indexOf(this.floors[i].floor) === -1) {
          uniqueFloors.push(this.floors[i].floor)
        }
      }
      const floors = uniqueFloors.sort(function(a, b) {
        return   a - b;
      })
      return floors
    },
  },
  mounted() {
    this.selectedFloors = this.propFloors
  },
  watch: {
    propFloors: function() {
      this.selectedFloors = this.propFloors
    }
  }
};
</script>
