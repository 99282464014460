<template>
  <div class="px-1 md:px-5 pb-3">
    <!-- Content -->
    <div class="tableDivCss">
      <!-- Table Content -->
      <table class="w-full sm:bg-white rounded overflow-hidden sm:shadow-lg">
        <thead
          class="text-white bg-primary rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
        >
        <!-- Table Header Content -->
        <th
          class="heading-text"
          @click="updateSorting('name')"
          :class="{ sorting: sortField === 'name' }"
        >
          Unit
          <MenuDown
            v-if="isSorted('name', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('name', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text"
          @click="updateSorting('price')"
          :class="{ sorting: sortField === 'price' }"
        >
          Price
          <MenuDown
            v-if="isSorted('price', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('price', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('floor')"
          :class="{ sorting: sortField === 'floor' }"
        >
          Floor
          <MenuDown
            v-if="isSorted('floor', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('floor', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('type')"
          :class="{ sorting: sortField === 'type' }"
        >
          Unit Type
          <MenuDown
            v-if="isSorted('type', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('type', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          v-if="windowWidth > 425"
          class="heading-text"
          @click="updateSorting('internalArea')"
          :class="{ sorting: sortField === 'internalArea' }"
        >
          Internal Size
          <MenuDown
            v-if="isSorted('internalArea', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('internalArea', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          v-if="windowWidth > 425"
          class="heading-text"
          @click="updateSorting('externalArea')"
          :class="{ sorting: sortField === 'externalArea' }"
        >
          External Size
          <MenuDown
            v-if="isSorted('externalArea', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('externalArea', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text"
          @click="updateSorting('totalArea')"
          :class="{ sorting: sortField === 'totalArea' }"
        >
          Total Size
          <MenuDown
            v-if="isSorted('totalArea', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('totalArea', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text"
          @click="updateSorting('bedrooms')"
          :class="{ sorting: sortField === 'bedrooms' }"
          style="min-width: 55px"
        >
          Beds
          <MenuDown
            v-if="isSorted('bedrooms', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('bedrooms', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <!-- <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('bathrooms')"
          :class="{ sorting: sortField === 'bathrooms' }"
        >
          Baths
          <MenuDown
            v-if="isSorted('bathrooms', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('bathrooms', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th> -->
        <!--            ____________ Removed to make table fit on screen ____________-->
        <!--          <th-->
        <!--            class="heading-text hidden md:table-cell"-->
        <!--            @click="updateSorting('parking')"-->
        <!--            :class="{ sorting: sortField === 'parking' }"-->
        <!--          >-->
        <!--            Parking-->
        <!--            <MenuDown-->
        <!--            v-if="isSorted('parking', 'desc')"-->
        <!--            class="inline-block absolute top-3 -right-1"-->
        <!--          />-->
        <!--            <MenuUp-->
        <!--              v-if="isSorted('parking', 'asc')"-->
        <!--              class="inline-block absolute top-3 -right-1"-->
        <!--            />-->
        <!--          </th>-->
        <!--        <th-->
        <!--          class="heading-text hidden md:table-cell"-->
        <!--          @click="updateSorting('storeroom')"-->
        <!--          :class="{ sorting: sortField === 'storeroom' }"-->
        <!--        >-->
        <!--          Storeroom-->
        <!--          <MenuDown-->
        <!--            v-if="isSorted('storeroom', 'desc')"-->
        <!--            class="inline-block absolute top-3 -right-1"-->
        <!--          />-->
        <!--          <MenuUp-->
        <!--            v-if="isSorted('storeroom', 'asc')"-->
        <!--            class="inline-block absolute top-3 -right-1"-->
        <!--          />-->
        <!--        </th>-->
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('aspect')"
          :class="{ sorting: sortField === 'aspect' }"
        >
          Direction
          <MenuDown
            v-if="isSorted('aspect', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('aspect', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('view')"
          :class="{ sorting: sortField === 'view' }"
        >
          Outlook
          <MenuDown
            v-if="isSorted('view', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('view', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <!-- Levies&Rates Hidden -->
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('levies')"
          :class="{ sorting: sortField === 'levies' }"
        >
          Levies
          <MenuDown
            v-if="isSorted('levies', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('levies', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('rates')"
          :class="{ sorting: sortField === 'rates' }"
        >
          Rates
          <MenuDown
            v-if="isSorted('rates', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('rates', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('guaranteedRental')"
          :class="{ sorting: sortField === 'guaranteedRental' }"
        >
          <!--          Rental Income-->
          Est. Rental
          <MenuDown
            v-if="isSorted('guaranteedRental', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('guaranteedRental', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th>
        <!--        <th-->
        <!--          class="heading-text hidden md:table-cell"-->
        <!--          @click="updateSorting('guaranteedRental')"-->
        <!--          :class="{ sorting: sortField === 'guaranteedRental' }"-->
        <!--        >-->
        <!--          Est. Rental-->
        <!--          <MenuDown-->
        <!--            v-if="isSorted('guaranteedRental', 'desc')"-->
        <!--            class="inline-block absolute top-3 -right-1"-->
        <!--          />-->
        <!--          <MenuUp-->
        <!--            v-if="isSorted('guaranteedRental', 'asc')"-->
        <!--            class="inline-block absolute top-3 -right-1"-->
        <!--          />-->
        <!--        </th>-->
        <!-- <th
          class="heading-text hidden md:table-cell"
          @click="updateSorting('isGuaranteedRental')"
          :class="{ sorting: sortField === 'isGuaranteedRental' }"
        >
          Guaranteed Rental
          <MenuDown
            v-if="isSorted('isGuaranteedRental', 'desc')"
            class="inline-block absolute top-3 -right-1"
          />
          <MenuUp
            v-if="isSorted('isGuaranteedRental', 'asc')"
            class="inline-block absolute top-3 -right-1"
          />
        </th> -->
        <th class="hidden md:table-cell heading-text-btn">
          Shortlist
        </th>
        <th class="heading-text-btn">
          Details
        </th>
        <th class="hidden md:table-cell heading-text-btn">
          Action
        </th>
        </thead>

        <tbody class="scroll"
               v-for="unit in filteredUnits.slice(0, this.initialAmount)"
               :key="unit.id"
        >
        <!-- Table Body Content -->
        <tr
          class="mb-2 sm:mb-0 bg-white"
          :class="[unit.status === 'reserved' ? 'reserved-list' : null, unit.status === 'sold' ? 'sold-list' : null]"
        >
          <!-- Name/Number Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.name }}
          </td>
          <!-- Price && Status Content -->
          <td v-if="windowWidth > 425"
              class="hover:bg-gray-100 truncate text-sm body-item-container"
              :class="{
              'p-3': user.loggedIn,
              'price-cta-correction':
                !user.loggedIn || !user.profile.answeredDiscountSurvey,
            }"
              style="
              border-bottom: 2px solid rgba(243, 244, 246, 1);
              top: 0px;
              position: relative;
            "
          >
            <UnitPrice
              class="relative"
              :unit="unit"
              :settings="settings"
              :show="true"
              :tableStyle="true"
              :bigPrice="false"
            />
          </td>
          <td
            v-else
            class="hover:bg-gray-100 truncate text-sm body-item-container"
            :class="{
              'p-3': user.loggedIn,
              'price-cta-correction':
                !user.loggedIn || !user.profile.answeredDiscountSurvey,
            }"
            style="
              border-bottom: 2px solid rgba(243, 244, 246, 1);
              top: 0px;
              position: relative;
              justify-content: center;
              align-content: center;
              text-align: center;
            "
          >
            <UnitPrice
              class="relative"
              :unit="unit"
              :settings="settings"
              :show="true"
              :tableStyle="true"
              :bigPrice="false"
            />
          </td>
          <!-- Floor Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.floor }}
          </td>
          <!-- Unit Type Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.type }}
          </td>
          <!-- Area Content -->
          <td
            v-if="windowWidth > 425"
            class="hover:bg-gray-100 p-2 truncate text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.internalArea }}m&sup2;
          </td>
          <td
            v-if="windowWidth > 425"
            class="hover:bg-gray-100 p-2 truncate text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.externalArea }}m&sup2;
          </td>
          <td
            class="hover:bg-gray-100 p-2 truncate text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.totalArea }}m&sup2;
          </td>
          <!-- Unit Spec Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.bedrooms }}
          </td>
          <!-- <td
              class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.bathrooms }}
          </td> -->
          <!--            ____________ Removed to make table fit on screen ____________-->
          <!--            <td-->
          <!--              class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"-->
          <!--              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"-->
          <!--            >-->
          <!--              {{ unit.parking }}-->
          <!--            </td>-->

          <!--          <td-->
          <!--              class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"-->
          <!--              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"-->
          <!--          >-->
          <!--            {{ unit.storeroom }}-->
          <!--          </td>-->

          <!-- Unit Direction facing Content -->
          <!-- Direction facing === aspect -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.aspect }}
          </td>
          <!-- Unit View facing Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            {{ unit.view }}
          </td>
          <!-- Unit Levies Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
              <span v-if="unit.levies && unit.levies > 0">
                {{ unit.levies | prettyCurrency }}
              </span>

            <span v-else class="opacity-0 p-0"></span>
          </td>
          <!-- Unit Rates Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
              <span v-if="unit.rates && unit.rates > 0">
                {{ unit.rates | prettyCurrency }}
              </span>

            <span v-else class="opacity-0 p-0"></span>
          </td>
          <!-- Unit Rental Income Content -->
          <td
            class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
              <span v-if="unit.guaranteedRental && unit.guaranteedRental > 0">
                {{ unit.guaranteedRental | prettyCurrency }}
              </span>

            <span
              v-else
              class="z-10 pt-2 pb-2 pr-5 pl-5 text-black rounded"
            >
                NA
              </span>
          </td>
          <!-- Unit Guaranteed Rental Content -->
          <!-- <td
              class="hover:bg-gray-100 p-2 truncate hidden md:table-cell text-sm align-center justify-center text-center body-item-container"
              style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
              <span
                  v-if="unit.isGuaranteedRental"
                  class="z-10 pt-2 pb-2 pr-5 pl-5"
              >
                Yes
              </span>

            <span
                v-else
                class="z-10 pt-2 pb-2 pr-5 pl-5 text-black rounded"
            >
                No
              </span>
          </td> -->
          <!-- Unit Shortlist Content -->
          <td
            class="p-2 cursor-pointer hidden md:table-cell text-sm body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
          <span>
            <AddToShortlistTable
              :unitID="unit.id"
              :unitName="unit.name"
              :show="false"
            />
          </span>
          </td>
          <!-- Unit View Content -->
          <td
            class="p-2 cursor-pointer body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            <button
              v-if="unit.customIdViewButton"
              class="p-2 w-full text-white text-sm md:text-sm font-semibold uppercase tracking-wider bg-secondary rounded duration-150 hover:bg-secondarylight"
              v-on:click="
                  $emit('update:unit-dialog-data', { open: true, data: unit })
                "
              :id="unit.customIdViewButton"
            >
              View
            </button>

            <button
              v-else
              class="p-2 w-full text-white text-sm md:text-sm font-semibold uppercase tracking-wider bg-secondary rounded duration-150 hover:bg-secondarylight"
              v-on:click="
                  $emit('update:unit-dialog-data', { open: true, data: unit })
                "
            >
              View
            </button>
          </td>
          <!-- Unit Status Content -->
          <td
            class="p-2 cursor-pointer hidden md:table-cell body-item-container"
            style="border-bottom: 2px solid rgba(243, 244, 246, 1)"
          >
            <div v-if="settings.isGlobalOverrideAction === true">
              <a
                :href="settings.globalOverrideAction"
                target="_blank"
              >
                <button
                  class="bg-primary w-full items-center p-2 text-black text-sm font-semibold uppercase tracking-wider cursor-pointer rounded"
                >
                  {{settings.globalOverrideActionButton}}
                </button>
              </a>
            </div>

            <div v-else>
              <div v-if="unit.isOverrideAction === true">
                <a
                  :href="unit.unitOverrideAction"
                  target="_blank"
                >
                  <button
                    class="bg-primary w-full items-center p-2 text-black text-sm font-semibold uppercase tracking-wider cursor-pointer rounded"
                  >
                    {{unit.unitOverrideActionButton}}
                  </button>
                </a>
              </div>

              <div class="has-tooltip" v-else-if="settings.allowReserve">
                <button
                  disabled
                  class="bg-gray-500 w-full items-center p-2 text-black text-sm font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                  v-if="unit.status === 'pending' || unit.status === 'pendingUnitDetails' || unit.status === 'pendingPayment'"
                >
                  Pending
                </button>

                <button
                  disabled
                  class="bg-gray-500 w-full items-center p-2 text-black text-sm font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                  v-if="unit.status === 'reserved'"
                >
                  Reserved
                </button>

                <button
                  disabled
                  class="bg-gray-500 w-full items-center p-2 text-black text-sm font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                  v-if="unit.status === 'sold'"
                >
                  Sold
                </button>

                <button
                  class="bg-green-500 hover:bg-green-600 p-2 w-full text-white text-sm font-semibold uppercase tracking-wider duration-150 rounded"
                  @click="unitReserve(unit.name)"
                  v-if="unit.status === 'available' && !disableReserve && !unit.preReserved"
                >
                  Reserve
                </button>

                <button
                  class="relative bg-gray-400 rounded items-center w-full px-4 py-2 text-white text-sm tracking-wider duration-150"
                  v-if="unit.status === 'available' && disableReserve"
                >
                  <span class="text-md font-semibold uppercase">
                    Reserve
                  </span>
                  <span class="tooltip -bottom-0 -right-0">This reserve button will become active once sales go live</span>
                </button>
                <!-- When the unit is pre-reserved this pending button will take effect -->
                <button
                  disabled
                  class="bg-gray-500 w-full items-center p-2 text-black text-sm font-semibold uppercase tracking-wider cursor-not-allowed rounded"
                  v-if="unit.status === 'available' && !disableReserve && unit.preReserved"
                >
                  Pending
                </button>
              </div>

              <div v-else>
                <button
                  class="bg-primary w-full p-1 text-black text-sm font-semibold uppercase tracking-wider duration-150 hover:bg-primarylight rounded"
                  @click.prevent="openContactDialog()"
                >
                  Contact
                </button>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- Content -->
    <div
      class="table-footer md:hidden flex flex-row items-center justify-center pb-1"
      style="box-shadow: 0 -5px 5px -5px #ccc"
    >
      <div
        class="uppercase font-semibold mx-1 text-sm cursor-pointer"
        :class="{ active: status === 'all' }"
        @click="$emit('update-status', 'all')"
      >
        Show All <span class="text-gray-400">{{ total }}</span>
      </div>
      <div
        class="uppercase font-semibold mx-1 text-sm cursor-pointer"
        :class="{ active: status === 'available-only' }"
        @click="$emit('update-status', 'available-only')"
      >
        Available Only <span class="text-gray-400">{{ availableOnly }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import UnitPrice from "./shared/UnitPrice.vue";
import { bus } from "../../main";
import { mapGetters } from "vuex";
import AddToShortlistTable from "./AddToShortlistTable.vue";
import MenuDown from "vue-material-design-icons/MenuDown.vue";
import MenuUp from "vue-material-design-icons/MenuUp.vue";
import {APP_TITLE, APP_ADMIN_PAGINATION} from "@/Constants";
export default {
  name: "MainUnitReserveListTable",
  components: {
    AddToShortlistTable,
    UnitPrice,
    MenuDown,
    MenuUp,
  },
  props: {
    status: {
      type: String,
    },
    total: {
      type: Number,
    },
    availableOnly: {
      type: Number,
    },
    guaranteedRentals: {
      type: Boolean,
    },
    settings: {
      type: Object,
    },
    sortField: {
      type: String,
    },
    sortDirection: {
      type: String,
    },
    unitDialogData: {
      type: Object,
    },
  },
  data() {
    return {
      currentSort:'name',
      currentSortDir:'asc',
      pageSize: APP_ADMIN_PAGINATION,
      currentPage:1,
      title: APP_TITLE,
      windowWidth: window.innerWidth,
      now: new Date(),
      initialAmount: 50,
      addedAmount: 150,
      roleStatuses: ['admin', 'admin-viewonly', 'superadmin']
    };
  },
  methods: {
    handleScroll(){
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight -250) {
        this.addToUnitsTotal()
      }
    },
    addToUnitsTotal() {
      this.initialAmount = this.initialAmount + this.addedAmount
    },
    // isSorted(field, direction) {
    //   if (this.currentSort === field && this.currentSortDir === direction) {
    //     return true;
    //   }
    //   return false;
    // },
    isSorted(field, direction) {
      if (this.sortField === field && this.sortDirection === direction) {
        return true;
      }
      return false;
    },

    // sort:function(s) {
    //   if(s === this.currentSort) {
    //     this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
    //   }
    //   this.currentSort = s;
    // },
    updateSorting(field) {
      if (this.sortField === field && this.sortDirection === "asc") {
        this.$emit("update-sorting", { field: field, direction: "desc" });
      } else {
        this.$emit("update-sorting", { field: field, direction: "asc" });
      }
    },
    updateNow() {
      this.now = Date.now();
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    unitReserve(unitName) {
      this.$router.push({ name: "reserve", params: { name: unitName } });
    },
    openContactDialog() {
      bus.$emit("update:unit-contact-data", { open: true });
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters({
      user: "user",
      units: "units",
    }),
    filteredUnits:function() {
      let units = this.units.filter((item) => item.price)
      return units.sort((a,b) => {
        let modifier = 1;
        if(this.sortDirection === 'desc') modifier = -1;
        if(a[this.sortField] < b[this.sortField]) return -1 * modifier;
        if(a[this.sortField] > b[this.sortField]) return 1 * modifier;
        return 0;
      })
      //   .filter((row, index) => {
      //     let start = (this.currentPage-1)*this.pageSize;
      //     let end = this.currentPage*this.pageSize;
      //     if(index >= start && index < end) return true;
      //   });
    },
    // filteredUnits() {
    //   return this.units.filter((item) => item.price);
    // },
    disableReserve: function () {
      if (this.settings && this.settings.launchDate && this.settings.launchDate > this.now) {
        if (this.units.isOneDayOnlyDiscount === true) {
          return false;
        }
        if (this.roleStatuses.includes(this.user.profile.role) && this.settings.bypassAdmin === true) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style>
.priceContainer {
  font-size: 14px;
}

.table-footer {
  height: 50px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 2px solid #fff;
}
.tableDivCss {
  width: 100%;
  overflow-x:auto;
}
.table-footer .active {
  border-bottom: 2px solid #aa9461;
}

.price-cta-correction {
  padding-top: 8px;
  padding-left: 10px;
  padding-bottom: 15px;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

thead tr:not(:first-child) {
  display: none;
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  position: relative;
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #ffffff33;
}

.scroll {
  overflow-x: scroll;
}
.body-item-container {
  line-height: 1rem;
  text-align: center;
  padding: 0.5rem;
}

.heading-text-btn {
  padding: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}

.reserved-list {
  background: #6c727f;
}

.sold-list {
  background: #3e404c;
}
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }
}
@media (min-width: 768px) {
  .heading-text {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .heading-text-btn{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}
</style>
