<template>
  <div>
    <!-- *** HomePage View *** -->
    <div v-if="homepageView">
    <button v-if="!isUnitInShortlist"
      @click="addToShortlist(unitID)"
      class=" styling-button  extra-styling-homepageView has-tooltipHomepage  justify-center text-sm text-black shadow-border bg-gray-100 hover:bg-secondarylight hover:text-white tracking-wider "
    >
    <span>
      <div class="flex justify-center align-center">
        <h1 class="mediumText">
          ADD TO LIST
        </h1>
        <div class="starIconCss"><ShortlistIcon class="text-primary" /></div>
        </div>
        <div v-if="shortlistCount >= settings.shortlistUnitShow">
         <p class="span_paragraph smallText">
           Shortlisted by <span class="is-bold ">{{ shortlistCount }} others</span>
         </p>
        </div>
      </span>
      <!-- <span v-if="show && showTooltip" class="tooltip -bottom-7 -left-2">ADD TO LIST</span> -->
    </button> 
    <button
      v-else
      @click="deleteFromShortlist(unitID, unitName)"
      class="styling-button extra-styling-homepageView has-tooltip justify-center text-sm text-white shadow-border bg-secondary "
    >
      <span v-if="show">
      <div class="flex justify-center align-center">
        <h1 class="mediumText">
          SHORTLISTED
        </h1>
        <div class="starIconCss"><ShortlistIcon class="text-primary" /></div>
        </div>
       <div v-if="shortlistCount >= settings.shortlistUnitShow">
         <p class="span_paragraph smallText">
           Shortlisted by <span class="is-bold ">{{ shortlistCount -1 }} others</span>
         </p>
       </div>
      </span>
    </button>
    </div>
    <!-- &&& item View &&& -->
    <div v-if="itemView">
    <button v-if="!isUnitInShortlist"
      @click="addToShortlist(unitID)"
      class=" styling-button  extra-styling-itemView has-tooltip justify-center  text-sm  text-black shadow-border bg-gray-100 hover:bg-secondarylight hover:text-white tracking-wider "
    >
      <span v-if="show">
        <div class="flex justify-center align-center">
        <h1 class="mediumTextItemView">
          ADD TO LIST
        </h1>
        <div class="starIconCss"><ShortlistIcon class="text-primary" /></div>
        </div>
        <div v-if="shortlistCount >= settings.shortlistUnitShow">
         <p class="span_paragraph smallTextItemView">
           Shortlisted by <span class="is-bold ">{{ shortlistCount }} others</span>
         </p>
        </div>
      </span>
    </button> 
    <button
      v-else
      @click="deleteFromShortlist(unitID, unitName)"
      class="styling-button extra-styling-itemView has-tooltip justify-center  text-sm text-white shadow-border bg-secondary "
    >
      <span v-if="show">
      <div class="flex justify-center align-center">
        <h1 class="mediumTextItemView">
          SHORTLISTED
        </h1>
        <div class="starIconCss"><ShortlistIcon class="text-primary" /></div>
        </div>
       <div v-if="shortlistCount >= settings.shortlistUnitShow">
         <p class="span_paragraph smallTextItemView">
           Shortlisted by <span class="is-bold ">{{ shortlistCount -1 }} others</span>
         </p>
       </div>
      </span>
    </button>
    </div>
  </div>

</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import {profilesCollection, settingsCollection} from "../../firebase.js";

import isNil from "lodash/isNil";
import indexOf from "lodash/indexOf";
import ShortlistIcon from "vue-material-design-icons/Star.vue";

export default {
  name: "AddToShortlist",
  components: {
    ShortlistIcon,
  },
  props: {
    unit: {
      type: Array,
        default: () => []
    },
    unitID: {
      default: null,
    },
    unitName: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false,
    },
      showTooltip: {
      type: Boolean,
      default: true,
    },
    homepageView: {
      type: Boolean,
      default: true,
    },
    itemView: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    settings: {
      shortlistUnitShow: null,
    },
    shortlistCount: 0
  }),
  methods: {
    returnID(id)
    {
      alert(id);
    },
    getShortlistCollection() {
      profilesCollection.onSnapshot((profiles) => {
        this.shortlistCount = 0;
        profiles.forEach((profile) => {
          if (
            profile.data().unitShortlistIDs &&
            profile.data().unitShortlistIDs.length > 0
          ) {
            profile.data().unitShortlistIDs.map((unit) => {
              if (unit === this.unitID)
                this.shortlistCount++
            });
          }
        });
      });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.shortlistUnitShow = doc.data().shortlistUnitShow || null;
      });
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    addToShortlist(unitID) {
      if (isNil(this.user) || !this.user.loggedIn) {
        this.$router.push('/login')
        this.$notify({
          group: "global",
          type: "error",
          text:
            "Please create an account and sign in to add a unit to your shortlist",
        });

        return;
      }

      const shortlistData = {
        unitID: unitID,
        unitName: this.unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayUnion(unitID),
          unitShortlist: firebase.firestore.FieldValue.arrayUnion(
            shortlistData
          ),
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            text: "Unit added to your shortlist",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            text: error.message,
          });
        });
    },
    deleteFromShortlist(unitID, unitName) {
      const shortlistData = {
        unitID: unitID,
        unitName: unitName,
      };

      profilesCollection
        .doc(this.user.data.uid)
        .update({
          unitShortlistIDs: firebase.firestore.FieldValue.arrayRemove(unitID),
          unitShortlist: firebase.firestore.FieldValue.arrayRemove(
            shortlistData
          ),
        })
        .then(() => {
          this.$notify({
            group: "global",
            type: "success",
            text: "Unit removed to your shortlist",
          });
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            text: error.message,
          });
        });
    },
  },
  created() {
    this.getSettings();
    // this.getShortlistCollection();
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    isUnitInShortlist() {
      if (
        !isNil(this.user) &&
        !isNil(this.user.profile) &&
        !isNil(this.user.profile.unitShortlistIDs)
      ) {
        return indexOf(this.user.profile.unitShortlistIDs, this.unitID) >= 0;
      }
      return false;
    },
  },
  mounted() {
    this.getShortlistCollection();
  },
};
</script>
<style  lang="css" scoped>
.tooltip .tooltip-text {
  visibility: hidden;
  z-index: 9999;
  position: relative;
}
.tooltipHomepage .tooltip-text {
  visibility: hidden;
  z-index: 9999;
  position: relative;
}
.styling-button {
  height: 41px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 600;
  letter-spacing: 1px;
}
.extra-styling-itemView {
    width:100%;
    position: relative;
}
.extra-styling-homepageView {
    width:165px;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.span_paragraph {
  font-size: 10px;
}
.is-bold {
  font-weight: bold;
}
.smallText {
  font-size: 9px;
  font-weight: 400;
}
.mediumText {
  font-size: 15px;
  font-weight: 700px;
  padding-top:5px;
  text-align: center;
}
.smallTextItemView {
  font-size: 8px;
  font-weight: 400;
  margin-bottom: 2px;
}
.mediumTextItemView {
  font-size: 12px;
  font-weight: 700px;
  padding-top:5px;
  text-align: center;
}
.starIconCss {
  padding-top:3px
}
svg {
  display: inline-block;
  vertical-align: middle;
  width: 22px !important;
  height: 22px !important;
  margin-left: 0px;
  margin-top: 0px !important;
}

  @media only screen and (max-width: 320px) {

  .smallTextItemView {
    font-size: 7px !important
  }
    .mediumTextItemView {
    font-size: 10px !important
  }
  }
  @media only screen and (min-width: 425px) {
  .smallTextItemView {
    font-size: 11px
  }
  .mediumTextItemView {
    font-size: 13px
  }
    .svg {
    margin-left: 5px !important;
  }
  }
  @media only screen and (max-width: 375px) {
  .smallTextItemView {
    font-size: 9px
  }
  .mediumTextItemView {
    font-size: 12px
  }
  }
  @media only screen and (min-width: 1024px) {
  .smallTextItemView {
    font-size: 9px
  }
  .mediumTextItemView {
    font-size: 12px
  }
  }
   @media only screen and (min-width: 1440px) {
  .smallTextItemView {
    font-size: 11px
  }
  .mediumTextItemView {
    font-size: 12px
  }
  } 

</style>