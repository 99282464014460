<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full" x-data="{ open: true }">
    <button
      @click="displayDropdown = !displayDropdown"
      class="relative flex flex-row px-3 py-2 text-black items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-gray-300 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span
        v-if="selectedMinPrice === 0 && selectedMaxPrice === 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        {{ title }}
      </span>

      <!-- Filter Subname Content -->
      <span
        v-if="selectedMinPrice > 0 && selectedMaxPrice === 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        {{ formatPrice(selectedMinPrice) }} - Max
      </span>

      <span
        v-if="selectedMinPrice > 0 && selectedMaxPrice < 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        {{ formatPrice(selectedMinPrice) }} - {{ formatPrice(selectedMaxPrice) }}
      </span>

      <span
        v-if="selectedMinPrice === 0 && selectedMaxPrice < 99000000"
        style="overflow: hidden; white-space: nowrap"
      >
        Min - {{ formatPrice(selectedMaxPrice) }}
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-on-clickaway="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right w-full lg:w-96 shadow-lg bg-white rounded-md"
    >
      <div class="flex">
        <div class="w-1/2 lg:w-48 pt-6 pl-4 pb-3 pr-4">
          <div v-if="(user.loggedIn && user.profile.answeredDiscountSurvey && this.settings.allowDiscount )">
            <ul
              v-for="global in globals"
              :key="global.globalDiscountType"
            >
              <!-- Filter Drop Down MIN Content && Global DiscountType === Amount -->
              <div
                v-if="global.globalDiscountType === 'amount' && global.allowDiscount"
              >
                <ul
                  v-for="global in globals"
                  :key="global.globalDiscountAmount"
                >
                  <p class="mb-4 font-semibold">Min</p>
                  <ul class="max-h-28 md:max-h-36 lg:max-h-36 overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100">
                    <li
                      v-for="price in priceListMin"
                      :key="price"
                      @click="setMinPrice( (price - global.globalDiscountAmount) -10 )" class="mb-2"
                    >
                      {{ (price - global.globalDiscountAmount) | prettyCurrency }}
                    </li>
                    <li
                      v-for="price in priceListMinTwoMillion"
                      :key="price"
                      @click="setMinPrice( (price - global.globalDiscountAmount) -10 )" class="mb-2"
                    >
                      {{ (price - global.globalDiscountAmount) | prettyCurrency }}
                    </li>
                  </ul>

                  <!-- Filter Drop Down MIN Content && Global DiscountType === Amount && Custom Price -->
                  <div class="w-full">
                    <input
                      @keyup.enter="setMinPrice(customMinPriceInput)"
                      v-model="customMinPriceInput"
                      type="number"
                      placeholder="Custom Price"
                      class="relative w-full mt-4 p-2 placeholder-black text-black bg-transparent text-sm border rounded border-black outline-none focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </ul>
              </div>
              <!-- Filter Drop Down MIN Content && Global DiscountType === Percentage -->
              <div
                v-if="global.globalDiscountType === 'percentage' && global.allowDiscount"
              >
                <ul
                  v-for="global in globals"
                  :key="global.globalDiscountAmount"
                >
                  <p class="mb-4 font-semibold">Min</p>
                  <ul class="max-h-28 md:max-h-36 lg:max-h-36 overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100">
                    <li
                      v-for="price in priceListMin"
                      :key="price"
                      @click="setMinPrice( (price * ((100 - global.globalDiscountAmount) / 100)) - 10 )" class="mb-2"
                    >
                      {{ (price * ((100 - global.globalDiscountAmount) / 100)) | prettyCurrency }}
                    </li>
                    <li
                      v-for="price in priceListMinTwoMillion"
                      :key="price"
                      @click="setMinPrice( (price * ((100 - global.globalDiscountAmount) / 100)) - 10 )" class="mb-2"
                    >
                      {{ (price * ((100 - global.globalDiscountAmount) / 100)) | prettyCurrency }}
                    </li>
                  </ul>

                  <!-- Filter Drop Down MIN Content && Global DiscountType === Percentage && Custom Price -->
                  <div class="w-full">
                    <input
                      @keyup.enter="setMinPrice(customMinPriceInput)"
                      v-model="customMinPriceInput"
                      type="number"
                      placeholder="Custom Price"
                      class="relative w-full mt-4 p-2 placeholder-black text-black bg-transparent text-sm border rounded border-black outline-none focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </ul>
              </div>
            </ul>

          </div>

          <!-- Filter Drop Down MIN Content && Global DiscountType === NONE -->
          <div v-else>
            <p class="mb-4 font-semibold">Min</p>
            <ul
              class="max-h-28 md:max-h-36 lg:max-h-36 overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100">
              <li
                v-for="price in priceListMin"
                :key="price"
                @click="setMinPrice(price - 10)" class="mb-2"
              >
                {{ price | prettyCurrency }}
              </li>
              <li
                v-for="price in priceListMinTwoMillion"
                :key="price"
                @click="setMinPrice(price - 10)" class="mb-2"
              >
                {{ price | prettyCurrency }}
              </li>
            </ul>

            <!-- Filter Drop Down MIN Content && Global DiscountType === NONE && Custom Price -->
            <div class="w-full">
              <input
                @keyup.enter="setMinPrice(customMinPriceInput)"
                v-model="customMinPriceInput"
                type="number"
                placeholder="Custom Price"
                class="relative w-full mt-4 p-2 placeholder-black text-black bg-transparent text-sm border rounded border-black outline-none focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>

        </div>
        <div class="w-1/2 lg:w-48 pt-6 pl-4 pb-3 pr-4">
          <div v-if="(user.loggedIn && user.profile.answeredDiscountSurvey && this.settings.allowDiscount )">
            <ul
              v-for="global in globals"
              :key="global.globalDiscountType"
            >
              <!-- Filter Drop Down MAX Content && Global DiscountType === Amount -->
              <div
                v-if="global.globalDiscountType === 'amount' && global.allowDiscount"
              >
                <ul
                  v-for="global in globals"
                  :key="global.globalDiscountAmount"
                >
                  <p class="mb-4 font-semibold">Max</p>
                  <ul class="max-h-28 md:max-h-36 lg:max-h-36 overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100">
                    <li
                      v-for="price in priceListMax"
                      :key="price"
                      @click="setMaxPrice( (price - global.globalDiscountAmount) + 10 )" class="mb-2"
                    >
                      {{ (price - global.globalDiscountAmount) | prettyCurrency }}
                    </li>
                    <li
                      v-for="price in priceListMaxTwoMillion"
                      :key="price"
                      @click="setMaxPrice( (price - global.globalDiscountAmount) + 10 )" class="mb-2"
                    >
                      {{ (price - global.globalDiscountAmount) | prettyCurrency }}
                    </li>
                  </ul>

                  <!-- Filter Drop Down MAX Content && Global DiscountType === Amount && Custom Price -->
                  <div class="w-full">
                    <input
                      @keyup.enter="setMaxPrice(customMaxPriceInput)"
                      v-model="customMaxPriceInput"
                      type="number"
                      placeholder="Custom Price"
                      class="relative w-full mt-4 p-2 placeholder-black text-black bg-transparent text-sm border rounded border-black outline-none focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </ul>
              </div>

              <!-- Filter Drop Down MAX Content && Global DiscountType === Percentage -->
              <div
                v-if="global.globalDiscountType === 'percentage' && global.allowDiscount"
              >
                <ul
                  v-for="global in globals"
                  :key="global.globalDiscountAmount"
                >
                  <p class="mb-4 font-semibold">Max</p>
                  <ul class="max-h-28 md:max-h-36 lg:max-h-36 overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100">
                    <li
                      v-for="price in priceListMax"
                      :key="price"
                      @click="setMaxPrice( (price * ((100 - global.globalDiscountAmount) / 100)) + 10 )" class="mb-2"
                    >
                      {{ (price * ((100 - global.globalDiscountAmount) / 100)) | prettyCurrency }}
                    </li>
                    <li
                      v-for="price in priceListMaxTwoMillion"
                      :key="price"
                      @click="setMaxPrice( (price * ((100 - global.globalDiscountAmount) / 100)) + 10 )" class="mb-2"
                    >
                      {{ (price * ((100 - global.globalDiscountAmount) / 100)) | prettyCurrency }}
                    </li>
                  </ul>

                  <!-- Filter Drop Down MAX Content && Global DiscountType === Percentage && Custom Price -->
                  <div class="w-full">
                    <input
                      @keyup.enter="setMaxPrice(customMaxPriceInput)"
                      v-model="customMaxPriceInput"
                      type="number"
                      placeholder="Custom Price"
                      class="relative w-full mt-4 p-2 placeholder-black text-black bg-transparent text-sm border rounded border-black outline-none focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </ul>
              </div>
            </ul>
          </div>

          <!-- Filter Drop Down MAX Content && Global DiscountType === NONE -->
          <div v-else>
            <p class="mb-4 font-semibold">Max</p>
            <ul
              class="max-h-28 md:max-h-36 lg:max-h-36 overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100"
            >
              <li
                v-for="price in priceListMax"
                :key="price"
                @click="setMaxPrice(price + 10)" class="mb-2"
              >{{ price | prettyCurrency }}
              </li>
              <li
                v-for="price in priceListMaxTwoMillion"
                :key="price"
                @click="setMaxPrice(price + 10)" class="mb-2"
              >{{ price | prettyCurrency }}
              </li>
            </ul>

            <!-- Filter Drop Down MAX Content && Global DiscountType === NONE && Custom Price -->
            <div class="w-full">
              <input
                @keyup.enter="setMaxPrice(customMaxPriceInput)"
                v-model="customMaxPriceInput"
                type="number"
                placeholder="Custom Price"
                class="relative w-full mt-4 p-2 placeholder-black text-black bg-transparent text-sm border rounded border-black outline-none focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Filter Drop Down MIN MAXContent &&  Custom Price Btns-->
      <div class="px-4 pb-4">
        <button
          v-if="customePriceHasInput"
          @click="setCustomPrice()"
          class="p-1 mt-2 w-full border rounded"
        >
          <span>
            Go
          </span>
        </button>
        <button
          v-else
          @click="toggleDropdown()"
          class="p-1 mt-2 w-full border rounded"
        >
          <span>
            Close
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import {settingsCollection} from "@/firebase";
import {mapGetters} from "vuex";
import {FILTER_INCREMENT, FILTER_INCREMENT_MILLION, FILTER_BOUND} from "@/Constants";


export default {
  name: "MainUnitReserveListFilterMinMaxPrice",
  props: {
    selectedPrice: {
      default: null
    },
    selectedMinPrice: {
      default: 0
    },
    selectedMaxPrice: {
      default: 99000000
    },
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
    },
    globals: [],
    displayDropdown: false,
    placeholderText: "Min Price",
    customPriceInput: null,
    customMinPriceInput: null,
    customMaxPriceInput: null,
    priceList: [],
    prices: [
      {price: '',}
    ],
  }),
  mixins: [ clickaway ],
  methods: {
    getPriceList(){
        let priceList = [];
      this.units.forEach((doc) => {
        priceList.push({
          price: doc.price,
          originalPrice: doc.originalPrice,
          purchasePrice: doc.purchasePrice,
        });
      });
      this.priceList = priceList; 
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        this.settings.launchDate = doc.data().launchDate
          ? doc.data().launchDate.toDate()
          : null;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
        this.settings.allowGlobalDiscount =
          doc.data().allowGlobalDiscount || false;
        this.settings.globalDiscountAmount =
          doc.data().globalDiscountAmount || null;
        this.settings.globalDiscountType =
          doc.data().globalDiscountType || null;
      });
    },
    formatPrice(num) {
      if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    },
    toggleDropdown() {
      this.displayDropdown = !this.displayDropdown;
    },
    setCustomPrice() {
      if (this.customMinPriceInput >= 0 && this.customMaxPriceInput == null) {
        this.setMinPrice(this.customMinPriceInput)
        this.toggleDropdown()
      } else if (this.customMinPriceInput == null && this.customMaxPriceInput <= 99000000) {
        this.setMaxPrice(this.customMaxPriceInput)
        this.toggleDropdown()
      } else if (this.customMinPriceInput >= 0 && this.customMaxPriceInput <= 99000000) {
        this.setMinPrice(this.customMinPriceInput)
        this.setMaxPrice(this.customMaxPriceInput)
        this.toggleDropdown()
      } else {
        this.toggleDropdown()
      }
    },
    checkPriceState() {
      if (this.selectedMinPrice > 0 && this.selectedMaxPrice < 99000000) {
        this.toggleDropdown()
      }
    },
    setMinPrice(price) {
      this.$emit('update:min-price-filter', {price: price, code: 'minprice'})
      this.$route.query.minprice = encodeURI(price)
      // this.checkPriceState()
    },
    setMaxPrice(price) {
      this.$emit('update:max-price-filter', {price: price, code: 'maxprice'})
      this.$route.query.maxprice = encodeURI(price)
      // this.checkPriceState()
    },
  },
  created() {
    this.getSettings();
    this.getPriceList();
   
    settingsCollection.orderBy("globalDiscountAmount").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          displaySplashScreen: doc.data().displaySplashScreen,
          splashScreenMessage: doc.data().splashScreenMessage,
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
        });
      });
      this.globals = globals;
    });
  },
  computed: {
    ...mapGetters({
      user: "user",
      units: "units"
    }),
    price: function() {
      return this.selectedPrice
    },
    customePriceHasInput: function() {
      if(!isNil(this.customMinPriceInput) && !isEmpty(this.customMinPriceInput) || !isNil(this.customMaxPriceInput) && !isEmpty(this.customMaxPriceInput)) {
        return true
      }

      return false
    },
    priceListMin: function() {
      const uniquePrices = [];
      for(let i =0; i < this.priceList.length; i++) {
        if(uniquePrices.indexOf(this.priceList[i].price) === -1) {
          uniquePrices.push(parseFloat(this.priceList[i].price))
        }
      }
      const prices = uniquePrices.sort(function(a, b) {
        return   a - b;
      })
      const increment = FILTER_INCREMENT
      let accumulator = 0
      const upperBound = FILTER_BOUND
      const lowerBound = prices[0]
      const incrementedPrices = []
      while (accumulator < upperBound) {
        if (accumulator === 0) {
          accumulator = lowerBound
          incrementedPrices.push(accumulator)
        } else {
          accumulator += increment
          incrementedPrices.push(accumulator)
        }
      }
      return incrementedPrices
    },
    priceListMinTwoMillion: function() {
      const uniquePrices = [];
      for(let i =0; i < this.priceList.length; i++) {
        if(uniquePrices.indexOf(this.priceList[i].price) === -1) {
          uniquePrices.push(parseFloat(this.priceList[i].price))
        }
      }
      const prices = uniquePrices.sort(function(a, b) {
        return   a - b;
      })
      const increment = FILTER_INCREMENT_MILLION
      let accumulator = 0
      const upperBound = prices[prices.length -1]
      const lowerBound = FILTER_BOUND
      const incrementedPrices = []
      while (accumulator < upperBound) {
        if (accumulator === 0) {
          accumulator = lowerBound
          incrementedPrices.push(accumulator)
        } else {
          accumulator += increment
          incrementedPrices.push(accumulator)
        }
      }
      return incrementedPrices
    },
    priceListMax: function() {
      const uniquePrices = [];
      for(let i =0; i < this.priceList.length; i++) {
        if(uniquePrices.indexOf(this.priceList[i].price) === -1) {
          uniquePrices.push(parseFloat(this.priceList[i].price))
        }
      }
      const prices = uniquePrices.sort(function(a, b) {
        return   a - b;
      })
      const increment = FILTER_INCREMENT
      let accumulator = 0
      const upperBound = FILTER_BOUND
      const lowerBound = prices[0]
      const incrementedPrices = []
      while (accumulator < upperBound) {
        if (accumulator === 0) {
          accumulator = lowerBound
          incrementedPrices.push(accumulator)
        } else {
          accumulator += increment
          incrementedPrices.push(accumulator)
        }
      }
      return incrementedPrices
    },
    priceListMaxTwoMillion: function() {
      const uniquePrices = [];
      for(let i =0; i < this.priceList.length; i++) {
        if(uniquePrices.indexOf(this.priceList[i].price) === -1) {
          uniquePrices.push(parseFloat(this.priceList[i].price))
        }
      }
      const prices = uniquePrices.sort(function(a, b) {
        return   a - b;
      })
      const increment = FILTER_INCREMENT_MILLION
      let accumulator = 0
      const upperBound = prices[prices.length -1]
      const lowerBound = FILTER_BOUND
      const incrementedPrices = []
      while (accumulator < upperBound) {
        if (accumulator === 0) {
          accumulator = lowerBound
          incrementedPrices.push(accumulator)
        } else {
          accumulator += increment
          incrementedPrices.push(accumulator)
        }
      }
      return incrementedPrices
    },
  },
};
</script>
