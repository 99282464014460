const permissions = {
  units: {
    add: ['admin', 'superadmin'],
    update: ['agent-principal', 'admin', 'superadmin'],
    delete: ['admin', 'superadmin'],
    import: ['admin', 'superadmin']
  },
  leads: {
    add: ['admin', 'superadmin', 'agent-principal'],
    update: ['agent-principal', 'admin', 'superadmin'],
    delete: ['admin', 'superadmin', 'agent-principal'],
    import: ['admin', 'superadmin', 'agent-principal', 'agent'],
    'allocate-agent': ['admin', 'superadmin', 'agent-principal'],
    'add-contact-entry': ['admin', 'superadmin', 'agent-principal', 'agent'],
  },
  users: {
    view: ['admin', 'superadmin'],
    'change-user-role': ['admin', 'superadmin']
  },
  agents: {
    view: ['admin', 'superadmin' ],
    add: ['admin', 'superadmin', 'agent-principal'],
    update: ['agent-principal', 'admin', 'superadmin'],
    delete: ['admin', 'superadmin', 'agent-principal']
  },
  attendants: {
    view: ['admin', 'superadmin' ],
    add: ['admin', 'superadmin', 'agent-principal'],
    update: ['agent-principal', 'admin', 'superadmin'],
    delete: ['admin', 'superadmin', 'agent-principal']
  },
  'email-templates': {
    view: ['admin', 'superadmin' ],
    add: ['admin', 'superadmin'],
    update: [ 'admin', 'superadmin'],
    delete: ['admin', 'superadmin']
  },
  data: {
    export: ['admin', 'superadmin', 'admin-viewonly']
  },
  settings: {
    view: ['admin', 'superadmin'],
    update: ['admin', 'superadmin']
  },
  downloads: {
    view: ['admin', 'superadmin', 'admin-viewonly'],
    add: ['admin', 'superadmin'],
    update: ['admin', 'superadmin'],
    delete: ['admin', 'superadmin']
  }
}

export function hasPermission(area, action, role) {
  if (!permissions[area]) {
    return true;
  }

  if (permissions[area] && !permissions[area][action]) {
    return true;
  }

  return permissions[area] && permissions[area][action].includes(role);
}
