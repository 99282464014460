<template>
  <!-- Filter Menu Item Content -->
  <div class="relative w-full" x-data="{ open: true }">
    <button
      @click="displayDropdown = !displayDropdown"
      class="block flex flex-row px-3 py-2 text-black items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-gray-300 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline overflow-ellipsis"
      style="overflow: hidden; white-space: nowrap"
    >
      <!-- Filter Name Content -->
      <span v-if="selectedOrder === 'default'">
        {{ title }}
      </span>

      <span
        v-else
        class="flex flex-row overflow-ellipsis"
        style="overflow: hidden; white-space: nowrap"
      >
        {{ getSortName() }}
      </span>

      <svg
        fill="currentColor"
        viewBox="0 0 20 20"
        :class="{ 'rotate-180': displayDropdown, 'rotate-0': !displayDropdown }"
        class="absolute top-0 right-1 w-5 h-full transition-transform duration-200 transform"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <!-- Filter Drop Down Content -->
    <div
      v-if="displayDropdown"
      v-on-clickaway="toggleDropdown"
      class="absolute z-20 left-0 origin-top-right shaddow-lg"
    >
      <div class="pt-8 pb-5 px-5 w-52 bg-white rounded-md shadow-lg">
        <ul
          class="overflow-scroll font-normal tracking-wider cursor-pointer scrollbar scrollbar-thumb-primary scrollbar-track-gray-100"
        >
          <li
            class="mb-2 text-sm"
            v-for="option in options"
            :key="option.id"
            :class="{ active: selectedOrder === option.id }"
            @click="setOrderBy(option.id)"
          >
            {{ option.text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
  name: "MainUnitReserveListFilterSort",
  components: {},
  props: {
    propOrderBy: {
      type: String,
      default: "default",
    },
  },
  data: () => ({
    displayDropdown: false,
    title: "Sort",
    selectedOrder: "default",
    options: [
      { id: "default", text: "None" },
      { id: "name", text: "Unit Number" },
      { id: "low-to-high", text: "Price: Low To High" },
      { id: "high-to-low", text: "Price: High to Low" },
    ],
  }),
  mixins: [ clickaway ],
  methods: {
    getSortName() {
      return this.options.find((item) => item.id === this.selectedOrder).text;
    },
    toggleDropdown() {
      this.displayDropdown = !this.displayDropdown;
    },
    setOrderBy(selectedOrder) {
      this.selectedOrder = selectedOrder;

      this.$emit("update:order-by-filter", selectedOrder);
      Object.assign(this.$route.query, { order: encodeURI(selectedOrder) });
    },
  },
  mounted() {
    this.selectedOrder = this.propOrderBy;
  },
  watch: {
    propOrderBy: function () {
      this.selectedOrder = this.propOrderBy;
    },
  },
};
</script>

<style scoped>
.active {
  font-weight: bold;
}
</style>
