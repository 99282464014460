<template>
  <nav v-if="settings.isUsersOnline" class="navContainer">
    <div v-if="realtimeTotal === 1">
      {{ realtimeTotal }} User Online
    </div>
    <div v-else>
      {{ realtimeTotal }} Users Online
    </div>
  </nav>
</template>

<script>


// import axios from "axios";
// import { GA_REALTIME_URL} from "@/Constants";
// import {sum} from "lodash";
import {settingsCollection} from "@/firebase";
import {mapGetters} from 'vuex'

export default {
  name: "MainNavBarUserOnline",
  data() {
    return {
      // realtimeTotal: 0,
      settings: {
        isUsersOnline: false,
      },

    };
  },
  created() {
    this.getSettings();
  },
  computed: {
    ...mapGetters({
      realtimeTotal: 'realtimeTotal'
    }),
  },
  methods: {
    // async realtime() {
    //   let result = await axios.get(GA_REALTIME_URL);
    //   if (result.data && result.data.length > 0) {
    //     this.realtimeTotal = sum(
    //         result.data.map((item) => parseInt(item.metricValues[0].value, 10))
    //     );
    //     return;
    //   }
    //   this.realtimeTotal = 0;
    // },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.isUsersOnline = doc.data().isUsersOnline;
      });
    },
  },
  mounted() {
    // this.realtime();
    // setInterval(this.realtime, 30000);
  },
};
</script>

<style>
.navContainer {
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
  padding: 0.25rem 0.5rem;
  background-color: #12b981;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 11;
}
</style>
