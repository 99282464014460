var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"relative flex flex-col",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-col md:flex-row -mx-3"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-5"},[_c('label',{staticClass:"text-xs font-semibold px-1",attrs:{"for":"contact-first-name"}},[_vm._v("First name*")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"},[_c('NameIcon',{staticClass:"text-gray-400 text-lg"})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-primary",attrs:{"id":"contact-first-name","type":"text","placeholder":""},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "firstName", $event.target.value)}}})]),_c('span',{staticClass:"block w-full mt-1 text-xs text-red-600",class:{
          '': _vm.$v.form.firstName.$error,
          invisible: !_vm.$v.form.firstName.$error,
        }},[_vm._v(" Required")])]),_c('div',{staticClass:"w-full md:w-1/2 px-3 b-5"},[_c('label',{staticClass:"text-xs font-semibold px-1",attrs:{"for":"contact-last-name"}},[_vm._v("Last name*")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"},[_c('NameIcon',{staticClass:"text-gray-400 text-lg"})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-primary",attrs:{"id":"contact-last-name","type":"text","placeholder":""},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "lastName", $event.target.value)}}})]),_c('span',{staticClass:"block w-full mt-1 text-xs text-red-600",class:{
          '': _vm.$v.form.lastName.$error,
          invisible: !_vm.$v.form.lastName.$error,
        }},[_vm._v(" Required")])])]),_c('div',{staticClass:"flex flex-col md:flex-row -mx-3"},[_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-5"},[_c('label',{staticClass:"text-xs font-semibold px-1",attrs:{"for":"contact-email"}},[_vm._v("Email*")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"},[_c('EmailIcon',{staticClass:"text-gray-400 text-lg"})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-primary",attrs:{"id":"contact-email","type":"email","placeholder":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('span',{staticClass:"block w-full mt-1 text-xs text-red-600",class:{
          '': _vm.$v.form.email.$error,
          invisible: !_vm.$v.form.email.$error,
        }},[_vm._v(" Valid email required")])]),_c('div',{staticClass:"w-full md:w-1/2 px-3 mb-5"},[_c('label',{staticClass:"text-xs font-semibold px-1",attrs:{"for":"contact-phone"}},[_vm._v(" Contact Number* ")]),_c('div',{staticClass:"flex"},[_c('VuePhoneNumberInput',{staticClass:"phone-container",attrs:{"id":"contact-phone","size":"md","color":"#aa9461","default-country-code":"ZA"},on:{"update":_vm.updatePhoneNumber},model:{value:(_vm.form.contactNumber),callback:function ($$v) {_vm.$set(_vm.form, "contactNumber", $$v)},expression:"form.contactNumber"}})],1),_c('span',{staticClass:"block w-full mt-1 text-xs text-red-600",class:{
          '': _vm.$v.form.contactNumber.$error,
          invisible: !_vm.$v.form.contactNumber.$error,
        }},[_vm._v(" Valid phone number required ")])])]),_c('div',{staticClass:"flex -mx-3"},[_c('div',{staticClass:"w-full px-3 mb-8"},[_c('label',{staticClass:"text-xs font-semibold px-1",attrs:{"for":"contact-message"}},[_vm._v("Message*")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"},[_c('MessageIcon',{staticClass:"text-gray-400 text-lg"})],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.message),expression:"form.message"}],staticClass:"w-full -ml-10 pl-10 pr-3 h-32 rounded-lg border-2 border-gray-200 outline-none focus:border-primary",attrs:{"id":"contact-message","type":"text","placeholder":""},domProps:{"value":(_vm.form.message)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "message", $event.target.value)}}})]),_c('span',{staticClass:"block w-full mt-1 text-xs text-red-600",class:{
          '': _vm.$v.form.message.$error,
          invisible: !_vm.$v.form.message.$error,
        }},[_vm._v(" At least 5 letters required")])])]),_c('div',{staticClass:"flex -mx-3 justify-end"},[_c('div',{staticClass:"px-3 mb-2"},[_c('input',{staticClass:"block w-full bg-primary hover:bg-primary focus:bg-primary text-white uppercase rounded-lg px-3 py-3 font-semibold",attrs:{"type":"submit","value":"Send"}}),(_vm.successMessage)?_c('p',{staticClass:"absolute top-0 left-0 z-50 flex flex-col justify-center items-center h-full w-full bg-white duration-150"},[_c('MessageSentIcon',{staticClass:"text-green-500",attrs:{"size":80}}),_vm._v(" Thank you for contacting us. We will be in touch shortly. ")],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }