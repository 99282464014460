<template>
  <div class="home bg-gray-200">
    <PageBanner />
    <MainUnitReserveList />
  </div>
</template>

<script>

import PageBanner from '../../components/app/PageBanner.vue'
import MainUnitReserveList from '../../components/app/MainUnitReserveList.vue'

export default {
  name: 'Home',
  components: {
    PageBanner,
    MainUnitReserveList,
  },
}
</script>
