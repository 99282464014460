<template>
  <div id="main-unit-reserve-list" class="min-h-screen">
    <!-- Filter -->
    <div
      class="flex flex-col lg:flex-row py-4 px-2 bg-white top-0 z-30 shadow-lg"
      :class="settings.isUsersOnline ? 'filter-sticky' : 'isUsersOnline'"
    >
      <!-- Non hidden Filter all screens -->
      <div class="flex flex-row centerContainer lg:w-32">
        <div class="w-1/2 lg:w-full px-1">
          <div class="flex flex-row">
            <input
              class="w-full px-2 pb-1 placeholder-gray text-black relative bg-transparent text-sm border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
              type="text"
              placeholder="Unit No"
              v-model="searchFilter"
              @keydown.enter="filterName(searchFilter)"
            />
            <SearchIcon class="mt-2" @click="filterName(searchFilter)" />
          </div>
        </div>

        <div class="w-1/2 lg:hidden px-1">
          <button
            @click="displayFilters = !displayFilters"
            class="relative flex flex-row px-4 py-2 text-black items-center w-full text-sm font-semibold text-left bg-transparent border rounded-md border-gray-300 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          >
            Filters

            <svg
              fill="currentColor"
              viewBox="0 0 20 20"
              :class="{
                'rotate-180': displayFilters,
                'rotate-0': !displayFilters,
              }"
              class="absolute top-0 right-2 w-5 h-full transition-transform duration-200 transform"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Hidden mobile filter options -->
      <div
        class="flex-grow mt-5 lg:mt-0"
        :class="{ 'hidden lg:flex': !displayFilters }"
      >
        <div v-if="this.units.length" class="filterContainer">
          <div
            class="filter-container-price lg:order-1"
            id="v-step-filter-name"
          >
            <PriceFilterMinMax
              :selectedMinPrice="minPrice"
              :selectedMaxPrice="maxPrice"
              @update:min-price-filter="filterPrice($event)"
              @update:max-price-filter="filterPrice($event)"
              title="Price"
            />
          </div>

          <div class="filter-container lg:order-2">
            <UnitFilter
              :propUnits="unitTypes"
              @update:unit-filter="filterUnitType($event)"
            />
          </div>

          <div class="filter-container lg:order-3">
            <AspectFilter
              :propAspects="aspects"
              @update:aspect-filter="filterAspect($event)"
            />
          </div>

          <div class="filter-container lg:order-3">
            <ViewFilter
              :propViews="views"
              @update:view-filter="filterView($event)"
            />
          </div>

          <div class="filter-container lg:order-4">
            <FloorFilter
              :propFloors="floors"
              @update:floor-filter="filterFloor($event)"
            />
          </div>
          <div class="filter-container lg:order-5">
            <SortFilter
              :propOrderBy="orderBy"
              @update:order-by-filter="filterOrderBy($event)"
            />
          </div>

          <div class="filter-container lg:order-6">
            <StatusFilter
              :propStatus="status"
              @update:status-filter="filterStatus($event)"
            />
          </div>

          <div class="filter-container lg:order-7">
            <Share />
          </div>
          <div
            class="filter-container lg:order-10 reset-filters"
          >
            <button
              :disabled="clearButtonDisabled"
              @click="resetFilters"
              class="w-full px-1 py-2 text-sm font-semibold focus:outline-none focus:border-none disabled:opacity-50"
              style="color: #dc0002"
            >
              <CloseCircleOutlineIcon :size="24" class="inline-block" />
              <span class="inline-block ml-1 relative"
              >Reset Filter</span
              >
            </button>
          </div>

          <div class="filter-container lg:order-9">
            <div
              @click="displayFilters = false"
              class="w-full p-2 font-semibold text-center text-sm text-white bg-green-500 border border-green-500 rounded"
            > 
           <span class="font-bold">
                <span v-if="totalUnits > 0">
                  <span class="lg:hidden">Show 
                  </span>{{ totalUnits }} /
                </span>
                  {{units.length}}
                </span
              >
              Units
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Layout Select -->
    <div
      class="set-spacing flex flex-row justify-center w-full mt-7 mb-7"
      id="v-step-list-view"
    >
    <div class="has-tooltip">
      <!-- <span class="tooltip">CARD VIEW</span> -->
      <button 
       v-on:click="setCardLoadingTrue()"
        :class="
          currentListComponent == 'Cards'
            ? 'flex flex-row items-center text-white bg-primary mr-2 px-4 py-2 text-sm font-semibold text-left uppercase rounded-lg hover:text-white focus:text-white hover:bg-primary focus:bg-primary focus:outline-none'
            : 'flex flex-row items-center text-gray-900 bg-white mr-2 px-4 py-2 text-sm font-semibold text-left uppercase rounded-lg hover:text-white focus:text-white hover:bg-primarylight focus:bg-primary focus:outline-none'
        "

      >
        <span class="mr-1">Card View</span>
        <CardButtonIcon />
      </button>
    </div>
    <div class="has-tooltip">
      <!-- <span class="tooltip">LIST VIEW</span> -->
      <button
       v-on:click="setListLoadingTrue()"
        :class="
          currentListComponent == 'Table'
            ? 'flex flex-row items-center text-white bg-primary mr-2 px-4 py-2 text-sm font-semibold text-left uppercase rounded-lg hover:text-white focus:text-white hover:bg-primary focus:bg-primary focus:outline-none'
            : 'flex flex-row items-center text-gray-900 bg-white mr-2 px-4 py-2 text-sm font-semibold text-left uppercase rounded-lg hover:text-white focus:text-white hover:bg-primarylight focus:bg-primary focus:outline-none'
        "
      >
        <span class="mr-1">List View</span>
        <TableButtonIcon />
      </button>
    </div>  
    </div>

    <div v-if="componentLoading">
      <UnitsLoading />
    </div>

    <div v-if="listLoading">
      <UnitsLoading />
    </div>

    <div v-if="cardsLoading">
      <UnitsLoading />
    </div>

    <component
      :is="currentListComponent"
      :units="filteredUnits"
      :settings="settings"
      :status="status"
      :total="totalUnitsWithoutStatus"
      :available-only="availableOnly"
      :guaranteed-rentals="isGuaranteedRentals"
      :sort-field="orderByDBSetting"
      :sort-direction="orderByReverse ? 'desc' : 'asc'"
      @update-status="filterStatus"
      @update-sorting="updateSorting"
      @update:unit-dialog-data="updateUnitDialogData($event)"
      @update:reserve-dialog-data="updateReserveDialogData($event)"
      @update:tooltip="updateTooltip($event)"
    ></component>

    <div
      class="w-full mt-12 text-xs pb-12 text-center"
      v-if="!filteredUnits.length > 0 && !componentLoading"
    >
      <p class="font-semibold">No Units avaliable</p>
      <p class="text-sm">Please check your filter settings</p>
      <button
        class="mt-3 px-3 py-2 text-white bg-primary rounded-xl"
        @click="resetFilters"
      >
        Reset Filters
      </button>
    </div>

    <UnitDialog
      :unitDialogData="unitDialogData"
      :settings="settings"
      @update:tooltip="updateTooltip($event)"
    />
    <div
      v-if="
        settings.displaySplashScreen && !hideSplashScreen && !componentLoading
      "
      class="fixed bottom-10 right-10 text-white z-60 px-6"
    >
      <div class="relative max-w-5xl">
        <button
          @click="setSplashSessionStorage()"
          class="z-50 absolute -top-5 -right-4 px-2 text-white text-4xl leading-none font-semibold border-0 bg-primary rounded-full outline-none focus:outline-none"
        >
          ×
        </button>
      <div id="container">
        <div id="block"
          class=" rounded"
          @click="setSplashSessionStorage()"
        >
        </div>
        <div id="text" v-html="settings.splashScreenMessage"></div>
      </div>
      </div>
    </div>

    <!-- Reserve tooltip -->
    <div
      v-if="showTooltip"
      class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-60"
    >
      <div class="relative max-w-5xl">
        <button
          @click="showTooltip = !showTooltip"
          class="z-50 absolute -top-5 -right-4 px-2 text-white text-4xl leading-none font-semibold border-0 bg-primary rounded-full outline-none focus:outline-none"
        >
          ×
        </button>
        <div class="w-full bg-black text-white py-4 px-12 rounded opacity-75">
          Available when sales go live
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center">
      <button 
        class="loadMoreButton lb-admin-primary-button-large mt-5 margin-bottom:15px"
        v-if="this.totalUnits>units.length"
        @click="getNextUnits(orderByDBSetting, orderByReverse)">
          Load more
      </button>
    </div>
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import { settingsCollection, } from "../../firebase.js";
import { APP_TITLE, PAGINATED_TOTAL_UNITS_URL } from "../../Constants";
import {
  conforms,
  filter,
  includes,
  indexOf,
  isEmpty,
  toInteger,
  isNil, reverse,
} from "lodash";
import { mixin as clickaway } from "vue-clickaway";
import UnitsLoading from "./MainUnitReserveListUnitsLoading.vue";
import AspectFilter from "./MainUnitReserveListFilterAspect";
import ViewFilter from "./MainUnitReserveListFilterView";
import FloorFilter from "./MainUnitReserveListFilterFloor";
import SortFilter from "./MainUnitReserveListFilterSort";
import StatusFilter from "./MainUnitReserveListFilterStatus";
import UnitFilter from "./MainUnitReserveListFilterUnitType";
import PriceFilterMinMax from "./MainUnitReserveListFilterPriceMinMax";
import ShareLink from "./MainUnitReserveListFilterShareLink";
import Share from "./MainUnitReserveListShare";
import CardButtonIcon from "vue-material-design-icons/Cellphone.vue";
import SearchIcon from "vue-material-design-icons/Magnify.vue";
import TableButtonIcon from "vue-material-design-icons/Laptop.vue";
import Cards from "./MainUnitReserveListCards.vue";
import Table from "./MainUnitReserveListTable.vue";
import UnitDialog from "./UnitDialog.vue";
import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import EmailIcon from "vue-material-design-icons/EmailOutline.vue";
import CloseCircleOutlineIcon from "vue-material-design-icons/CloseCircleOutline.vue";
import store from '../../../store';
import { unitsCollection } from "../../firebase.js";

// import axios from "axios";

export default {
  name: "MainMenuReserveList",
  components: {
    AspectFilter,
    ViewFilter,
    FloorFilter,
    PriceFilterMinMax,
    ShareLink,
    SortFilter,
    StatusFilter,
    UnitFilter,
    CardButtonIcon,
    Cards,
    SearchIcon,
    Table,
    TableButtonIcon,
    UnitDialog,
    UnitsLoading,
    LinkIcon,
    EmailIcon,
    CloseCircleOutlineIcon,
    Share,
  },
  mixins: [clickaway],

  data: () => ({
    // totalUnits: 0,
    searchFilter: "",
    minPrice: 0,
    maxPrice: 99000000,
    aspects: [],
    views: [],
    floors: [],
    unitTypes: [],
    orderBy: "default",
    orderByReverse: false,
    orderByDBSetting: "name",
    status: "all",
    displayAvailableOnly: false,
    displayFilters: false,
    clearButtonDisabled: false,
    unitsPopUp: [],
    units: [],
    filteredUnits: [],
    filteredUnitsWithoutStatus: [],
    filters: {},
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
      hidePriceOnSold: false,
      setOneDayOnlyDiscount: false,
      allowGlobalDiscount: false,
      globalDiscountAmount: null,
      globalDiscountType: '',

      isGlobalOverrideAction: false,
      globalOverrideAction: null,
      globalOverrideActionButton: null,

      showAvailableUnits: '',

      isUsersOnline: false,
      allowDiscountNoSurvey: false,

      isLaunchDate: false,
      interestRate: null,
      logo: null,
      headerImage: null,
      allowDeposit: false,
      depositType: null,
      depositAmount: null,
    },
    hideSplashScreen: false,
    showTooltip: false,
    currentListComponent: "Cards",
    dropdownPopoverShow: false,
    componentLoading: false,
    listLoading: false,
    cardsLoading: false,
    unitDialogData: {
      open: false,
      id: "",
    },
    reserveDialogData: {
      open: false,
      id: "",
    },
    limit: 18,
    startAt: 0,
    lastVisible: 0,
    paginatedTotalUnitsUrl: PAGINATED_TOTAL_UNITS_URL,
  }),
  methods: {
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
    updateSorting(sortCriteria) {
      this.orderByDBSetting = sortCriteria.field;
      this.orderByReverse = sortCriteria.direction === "desc";
      this.getUnits(this.orderByDBSetting, this.orderByReverse);
    },
    setSplashSessionStorage() {
      sessionStorage.setItem("hideSplashScreen", "true");
      this.hideSplashScreen = true;
    },
    getSplashSessionStorage() {
      const session = sessionStorage.getItem("hideSplashScreen");
      if (session) {
        this.hideSplashScreen = true;
      } else {
        this.hideSplashScreen = false;
      }
    },
    setListLoadingTrue(){
      this.listLoading = true
        setTimeout(() => {
          this.swopUnitToList()
        }, 200)
		},
      setCardLoadingTrue(){
      this.cardsLoading = true
        setTimeout(() => {
          this.swopUnitToCards()
        }, 200)
		},
     swopUnitToList() {
      this.currentListComponent = 'Table';
      this.listLoading = false;
    },
    swopUnitToCards() {
      this.currentListComponent = 'Cards';
      this.cardsLoading = false;
    },
    updateUnitDialogData(e) {
      if (e.open) {
        const el = document.body;
        el.style.overflow = "hidden";
      }
      this.unitDialogData = {
        open: e.open,
        data: e.data,
      };
    },
    // Never Been Called
    notEmptyObject(object) {
      return Object.keys(object).length > 0;
    },
    updateTooltip(e) {
      this.showTooltip = e;
    },
    // === Filter ===
    removeFilter(property) {
      delete this.filters[property];
    },
    resetFilters() {
      const query = this.$route.query;
      this.filters = {};
      this.searchFilter = null;
      this.minPrice = 0;
      this.maxPrice = 99000000;
      this.aspects = [];
      this.views = [];
      this.floors = [];
      this.unitTypes = [];
      this.orderBy = "default";
      this.status = "all";
      this.removeFilter("isGuaranteedRental");

      Object.keys(this.$route.query).forEach(function (key) {
        delete query[key];
      });

      this.applyFilters();
    },
    startFiltersApply(){
      this.filteredUnits = this.units
    },
    applyFilters() {
      this.filteredUnits = filter(this.units, conforms(this.filters));
      let clone = Object.assign({}, this.filters);
      delete clone.status;
      delete clone.isGuaranteedRental;
      this.filteredUnitsWithoutStatus = filter(this.units, conforms(clone));
      // to make units show as what filter does
      store.dispatch('handleUnits', this.filteredUnits);
      
    },
    filterName(value) {
      Object.assign(this.$route.query, { search: encodeURI(value) });
      this.filters.name = (val) => includes(val, value);
      this.searchFilter = value;
      this.applyFilters();
    },
    filterPrice(e) {
      // eslint-disable-next-line no-unused-vars
      let minPrice = 0;
      // eslint-disable-next-line no-unused-vars
      let maxPrice = 99000000;

      const selectedPrice = toInteger(e.price);

      if (
        e.code === "minprice" &&
        selectedPrice > 0 &&
        this.minPrice !== null
      ) {
        minPrice = selectedPrice;
        this.minPrice = selectedPrice;
      }

      if (
        e.code === "maxprice" &&
        selectedPrice > 0 &&
        selectedPrice < 99000000
      ) {
        maxPrice = selectedPrice;
        this.maxPrice = selectedPrice;
      }

      if (this.user.profile && this.user.profile.answeredDiscountSurvey) {
        this.filters.price = (unitPrice) => {
          let globalDiscountPrice = unitPrice;
          if (this.settings.globalDiscountType === 'amount') {
            const discountAmount= this.settings.globalDiscountAmount;
            globalDiscountPrice = unitPrice - discountAmount;
          }

          if (this.settings.globalDiscountType === 'percentage') {
            const discountPercentage = this.settings.globalDiscountAmount;
            globalDiscountPrice = unitPrice * ((100 - discountPercentage) / 100);
          }

          return (
            toInteger(globalDiscountPrice) > toInteger(this.minPrice + 1) &&
            toInteger(globalDiscountPrice) < toInteger(this.maxPrice + 1)
          );
        };
      } else {
        this.filters.price = (val) => {
          return (
            toInteger(val) > toInteger(this.minPrice + 1) &&
            toInteger(val) < toInteger(this.maxPrice + 1)
          );
        };
      }

      this.applyFilters();
    },
    filterView(e) {
      // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
      let selectedViews = e;

      if (typeof selectedViews === "string") {
        this.views = selectedViews.split(",");
      } else {
        this.views = selectedViews;
      }

      if (isEmpty(selectedViews) && !isNil(this.filters.view)) {
        this.removeFilter("view");
      } else {
        this.filters.view = (val) => indexOf(this.views, val) >= 0;
      }

      this.applyFilters();
    },
    filterAspect(e) {
      // TODO: Update to be dynamic with multiple values e.g. aspect or view/direction
      var selectedAspects = e;

      if (typeof selectedAspects === "string") {
        this.aspects = selectedAspects.split(",");
      } else {
        this.aspects = selectedAspects;
      }

      if (isEmpty(selectedAspects) && !isNil(this.filters.aspect)) {
        this.removeFilter("aspect");
      } else {
        this.filters.aspect = (val) => indexOf(this.aspects, val) >= 0;
      }
      this.applyFilters();
    },
    filterFloor(e) {
      var selectedFloors = e;

      if (typeof selectedFloors === "string") {
        this.floors = selectedFloors.split(",");
      } else {
        this.floors = selectedFloors;
      }

      if (isEmpty(selectedFloors) && !isNil(this.filters.floor)) {
        this.removeFilter("floor");
      } else {
        this.filters.floor = (val) => indexOf(this.floors, val) >= 0;
      }

      this.applyFilters();
    },
    filterUnitType(e) {
      var selectedUnitType = e;

      if (typeof selectedUnitType === "string") {
        this.unitTypes = selectedUnitType.split(",");
      } else {
        this.unitTypes = selectedUnitType;
      }

      if (isEmpty(selectedUnitType) && !isNil(this.filters.unitType)) {
        this.removeFilter("unitType");
      } else {
        this.filters.unitType = (val) => indexOf(this.unitTypes, val) >= 0;
      }

      this.applyFilters();
    },
    filterOrderBy(e) {
      if (e === "name") {
        this.orderBy = "name";
         this.orderByReverse = false;
         this.orderByDBSetting = "name";
                this.units.sort((a, b) => (a.name > b.name) ? 1 : -1)
      }

      if (e === "low-to-high") {
        this.orderBy = "low-to-high";
         this.orderByDBSetting = "price";
         this.orderByReverse = false;
        this.units.sort((a, b) => (a.price > b.price) ? 1 : -1)

      }

      if (e === "high-to-low") {
        this.orderBy = "high-to-low";
         this.orderByDBSetting = "price";
         this.orderByReverse = true;
            this.units.sort((a, b) => (a.price < b.price) ? 1 : -1)
      }
            this.applyFilters();
    },
    filterStatus(e) {
      if (e === "all") {
        this.status = "all";
        this.displayAvailableOnly = false;
      }
      if (e === "available-only") {
        this.status = "available-only";
        this.displayAvailableOnly = true;
      }
      if (e === "gr-only") {
        this.status = "gr-only";
        this.displayAvailableOnly = true;
      }
      if (this.status === "all") {
        this.removeFilter("status");
        this.removeFilter("isGuaranteedRental");
      } else if (this.status === "available-only") {
        this.filters.status = (val) => includes(val, "available");
        this.removeFilter("isGuaranteedRental");
      } else {
        this.filters.isGuaranteedRental = (val) => {
          return val === true;
        };
        this.filters.status = (val) => includes(val, "available");
      }
      this.applyFilters();
    },
    getUnits(orderBy, orderByReverse) {
      this.filteredUnits = this.units

      unitsCollection
        .where("displayOnHomePage", "==", true)
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let units = [];

          snap.forEach((doc) => {
            units.push({
              id: doc.id,
              unitType: doc.data().type,
              ...doc.data(),

            });
          });

          if (orderByReverse) {
            this.units = reverse(units);
          } else {
            this.units = units;
          }

          this.applyFilters();
          this.componentLoading = false;
        });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        this.settings.launchDate = doc.data().launchDate ? doc.data().launchDate.toDate() : null;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
        this.settings.allowGlobalDiscount = doc.data().allowGlobalDiscount || false;
        this.settings.globalDiscountAmount = doc.data().globalDiscountAmount || null;
        this.settings.globalDiscountType = doc.data().globalDiscountType || null;
        this.settings.isGlobalOverrideAction = doc.data().isGlobalOverrideAction || false;
        this.settings.globalOverrideAction = doc.data().globalOverrideAction || null;
        this.settings.globalOverrideActionButton = doc.data().globalOverrideActionButton || null;
        this.settings.showAvailableUnits = doc.data().showAvailableUnits;
        if (doc.data().showAvailableUnits && !this.status) {
          this.filterStatus('available-only')
        }
        this.settings.isUsersOnline = doc.data().isUsersOnline;
        this.settings.allowDiscountNoSurvey = doc.data().allowDiscountNoSurvey;
        (this.settings.isLaunchDate = doc.data().isLaunchDate || false);
        this.settings.interestRate = doc.data().interestRate;
        this.settings.logo = doc.data().logo;
        this.settings.headerImage = doc.data().headerImage;
        (this.settings.allowDeposit = doc.data().allowDeposit || false);
        (this.settings.depositType = doc.data().depositType || null);
        (this.settings.depositAmount = doc.data().depositAmount || null);

        (this.settings.bypassAdmin = doc.data().bypassAdmin || false);
      });
    },
    handleLinkClipboardCopy() {
      this.$notify({
        group: "global",
        type: "info",
        title: "Link added to Clipboard",
      });
    },
    generateShareLink() {
      // --- Links ---
      // https://www.facebook.com/sharer/sharer.php?u=google.com
      // https://twitter.com/intent/tweet?url=google.com&text=
      // mailto:info@example.com?&subject=&cc=&bcc=&body=google.com%0A

      // https://www.linkedin.com/shareArticle?mini=true&url=google.com
      // https://pinterest.com/pin/create/button/?url=google.com&media=&description=

      // <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share">Share via Whatsapp</a>

      this.displayDropdown = true;

      let url = window.location.origin + this.$route.path;
      let query = this.$route.query;
      let queryBuilder = "";
      var queryCounter = 0;

      if (query !== null || !isEmpty(query)) {
        // minprice
        if (query.minprice && query.minprice > 0) {
          queryBuilder += `minprice=${query.minprice}&`;
          queryCounter += 1;
        }

        // maxprice
        if (query.maxprice && query.maxprice < 99999999) {
          queryBuilder += `maxprice=${query.maxprice}&`;
          queryCounter += 1;
        }

        // aspect
        if (query.aspect) {
          queryBuilder += `aspect=${query.aspect}&`;
          queryCounter += 1;
        }

        // floor
        if (query.floor) {
          queryBuilder += `floor=${query.floor}&`;
          queryCounter += 1;
        }

        // order
        if (query.order) {
          queryBuilder += `order=${query.order}&`;
          queryCounter += 1;
        }

        // status
        if (query.status) {
          queryBuilder += `status=${query.status}&`;
          queryCounter += 1;
        }

        if (query.search) {
          queryBuilder += `search=${query.search}&`;
          queryCounter += 1;
        }

        if (query.unit) {
          queryBuilder += `unit=${query.unit}&`;
          queryCounter += 1;
        }

        if (queryCounter > 0) {
          url += "?";
          url += queryBuilder.slice(0, -1);
        }
      }

      return url;
    },
    shareEmail: function () {
      let url = this.generateShareLink()
        // .replace(/&/g, "%26")
        // .replace(/ /g, "%20");
      url = encodeURI(url);
      const title = APP_TITLE;
      const emailSubject = `I found apartments you may be interested in at ${title}.`;
      const emailBody = `Click here to view it online: ${url}`;
      const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
      window.location = emailLink;
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
      units: "filteredUnits",
    }),
    totalUnits: function () {
      if (this.filteredUnits !== null) {
        return this.filteredUnits.length;
      }
      return 0;
    },
    totalUnitsWithoutStatus() {
      if (this.filteredUnitsWithoutStatus !== null) {
        return this.filteredUnitsWithoutStatus.length;
      }
      return 0;
    },
    availableOnly() {
      if (this.filteredUnitsWithoutStatus !== null) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) => item.status === "available"
        ).length;
      }
      return 0;
    },
    isGuaranteedRentals() {
      if (this.filteredUnitsWithoutStatus !== null) {
        return this.filteredUnitsWithoutStatus.filter(
          (item) =>
            item.isGuaranteedRental === true
        ).length;
      }
      return 0;
    },
  },
  watch: {
    orderBy() {
      this.getUnits(this.orderByDBSetting, this.orderByReverse);
    },
    orderByReverse() {
      this.getUnits(this.orderByDBSetting, this.orderByReverse);
    },
  },
async created() {
    await this.getUnits("name", false);
    await this.getSettings();

    let interval = setInterval(() => {
      if (this.units.length > 0) {
        console.log('clear interval')
        clearInterval(interval)

        // Apply filters.
        this.startFiltersApply()

        let minPriceQuery = toInteger(this.$route.query.minprice);
        let maxPriceQuery = toInteger(this.$route.query.maxprice);
        let aspect = this.$route.query.aspect;
        let view = this.$route.query.view;

        let floor = this.$route.query.floor;
        let order = this.$route.query.order;
        let status = this.$route.query.status;
        let unitType = this.$route.query.unit;
        let search = this.$route.query.search;

        if (minPriceQuery && minPriceQuery > 0) {
          this.filterPrice({ price: minPriceQuery, code: "minprice" });
        }

        if (maxPriceQuery) {
          this.filterPrice({ price: maxPriceQuery, code: "maxprice" });
        }

        if (!isNil(aspect)) {
          this.filterAspect(aspect);
        }

        if (!isNil(view)) {
          this.filterView(view);
        }

        if (!isNil(floor)) {
          this.filterFloor(floor);
        }

        if (!isNil(order)) {
          this.filterOrderBy(order);
        }

        if (!isNil(status)) {
          this.filterStatus(status);
        }

        if (!isNil(unitType)) {
          this.filterUnitType(unitType);
        }

        if (!isNil(search)) {
          this.filterName(search);
        }        
      }
    }, 2000)
  },
  mounted: function () {
    this.units("name", false);
    this.getSplashSessionStorage();
  },
};
</script>

<style lang="css" scoped>
.centerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
}

.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

.isUsersOnline {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 30;
  top: 4.8rem;
}

.filter-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 7.3rem;
}
.filter-container-price {
  width: 9.75%;
  margin: 0.25rem 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}
.loadMoreButton {
  margin-bottom: 15px;
}
.filter-container {
  width: 9.75%;
  margin: 0.25rem 0;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}
.set-spacing {
    height: 40px;
}
#container {
  position: flex;
  width: 370px;
  height: 150px;
}
#block {
  background-color: black;
  filter: alpha(opacity=75);
  /* IE */
  -moz-opacity: 0.75;
  /* Mozilla */
  opacity: 0.75;
  /* CSS3 */
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
#text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 10%;
  padding-top: 5%;
}
@media only screen and (max-width: 1024px) {
  .filter-container-price {
    margin: 0 0;
    width: 9.75%;
  }

  .filter-container {
    margin: 0 0;
    width: 9.75%;
  }
}
@media only screen and (max-width: 768px) {
  .filter-container-price {
    width: 50%;
  }
  .filter-sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 5.8rem;
  }
  .isUsersOnline {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    z-index: 30;
    top: 4rem;
  }

  .filter-container {
    width: 50%;
  }
  /* .tooltip {
    display: none;
  } */
}
@media (min-width: 640px) {
  .reset-filters {
    width: 12%;
  }
}
@media (max-width: 425px) {
  .isUsersOnline {
    top: 3.7rem;
  }
  .filter-sticky {
    top: 5.65rem;
  }
  #text {
    font-size: 12px;
      padding-left: 6%;

  }
  #container {
  width: 250px;
  height: 120px;
}


  .filterContainer {
    flex-direction: row;
  }

  .filter-container-price {
    margin: 0.25rem 0;
    width: 100%;
  }

  .filter-container {
    margin: 0.25rem 0;
    width: 50%;
  }
} 
</style>
