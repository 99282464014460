import {firebase} from '@firebase/app'
// import 'firebase/auth'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID
}

// utils
const db = firebase.initializeApp(firebaseConfig).firestore()
// const auth = firebase.auth()

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    }
  )};

// collection references
// const usersCollection = db.collection('users')
const unitsCollection = db.collection('units')
const shortlistedUnitsCollection = db.collection('shortlistedUnits')
const downloadsCollection = db.collection('downloads')
const profilesCollection = db.collection('profiles')
const settingsCollection = db.collection('settings')
const reportsCollection = db.collection('reports')
const messagesCollection = db.collection('messages')
const formsCollection = db.collection('forms')
const agentsCollection = db.collection("agents")
const socialCollection = db.collection("agents")
const dealsCollection = db.collection('deals')
const optimisticLocksCollection = db.collection('optimisticLocks')
const paystackAuditCollection = db.collection('paystackAudit')
const leadsCollection = db.collection('leads')
const leadNotesCollection = db.collection('leadNotes')
const leadRemindersCollection = db.collection('leadReminders')
const emailTemplateCollection = db.collection('emailTemplates')

export {
  db,
  shortlistedUnitsCollection,
  unitsCollection,
  downloadsCollection,
  profilesCollection,
  settingsCollection,
  reportsCollection,
  messagesCollection,
  formsCollection,
  agentsCollection,
  socialCollection,
  dealsCollection,
  optimisticLocksCollection,
  leadsCollection,
  leadNotesCollection,
  leadRemindersCollection,
  emailTemplateCollection,
  paystackAuditCollection,
}
