<template>
  <!-- Filter Drop Down Content -->
  <div class="relative w-full">
    <button
      @click="generateShareLink"
      v-clipboard:copy="shareURL"
      class="flex flex-row w-full py-2 justify-center text-white bg-secondary border border-bg-secondary rounded focus:outline-none focus:shadow-outline"
    >
      <LinkIcon :size=20 />
    </button>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import LinkIcon from "vue-material-design-icons/ExportVariant.vue"
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'MainUnitReserveListFilterShareLink',
  components: {
    LinkIcon,
  },
  data: () => ({
    componentLoading: false,
    shareURL: '',
    addedToClipboard: false,
  }),
  mixins: [ clickaway ],
  methods: {
    closeDropdown() {
      this.displayDropdown = false
    },
    generateShareLink() {
      // --- LINKS ---
      // https://www.facebook.com/sharer/sharer.php?u=google.com
      // https://twitter.com/intent/tweet?url=google.com&text=
      // mailto:info@example.com?&subject=&cc=&bcc=&body=google.com%0A

      // https://www.linkedin.com/shareArticle?mini=true&url=google.com
      // https://pinterest.com/pin/create/button/?url=google.com&media=&description=

      // <a href="whatsapp://send?text=The text to share!" data-action="share/whatsapp/share">Share via Whatsapp</a>

      this.displayDropdown = true

      let url = window.location.origin + this.$route.path
      let query = this.$route.query
      let queryBuilder = ''
      var queryCounter = 0

      if(query !== null || !isEmpty(query)) {
        // minprice
        if(query.minprice && query.minprice > 0) {
          queryBuilder += `minprice=${query.minprice}&`
          queryCounter += 1
        }

        // maxprice
        if(query.maxprice && query.maxprice < 99999999) {
          queryBuilder += `maxprice=${query.maxprice}&`
          queryCounter += 1
        }

        // aspect
        if(query.aspect) {
          queryBuilder += `aspect=${query.aspect}&`
          queryCounter += 1
        }

        // floor
        if(query.floor) {
          queryBuilder += `floor=${query.floor}&`
          queryCounter += 1
        }

        // order
        if(query.order) {
          queryBuilder += `order=${query.order}&`
          queryCounter += 1
        }

        // status
        if(query.status) {
          queryBuilder += `status=${query.status}&`
          queryCounter += 1
        }

        if (query.unit) {
          queryBuilder += `unit=${query.unit}&`;
          queryCounter += 1;
        }
        if(queryCounter > 0) {
          url += '?'
          url += queryBuilder.slice(0, -1)
        }
      }

      this.shareURL = url

      this.$notify({
        group: 'global',
        type: 'info',
        title: 'Link added to Clipboard',
      });
    },
  },
}
</script>
