<template>
  <div id="app">
    <MainNavBarUserOnline />
    <MainNavBar />
    <MainNavBarContactModal />
    <SocialChat />
    <router-view />
  </div>
</template>

<script>
import MainNavBar from "@/components/app/MainNavBar.vue";
import MainNavBarContactModal from "@/components/app/MainNavBarContactModal.vue";
// import { SocialChat } from 'vue-social-chat';
import SocialChat from '@/components/SocialChat.vue';
import MainNavBarUserOnline from "@/components/app/shared/MainNavBarUsersOnline";

export default {
  name: "App",
  components: {
    MainNavBarUserOnline,
    MainNavBar,
    MainNavBarContactModal,
    SocialChat,
  },
};
</script>
<style>
.vsc-popup {
  bottom: 30px !important;
}
</style>