var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.settings.isLaunchDate)?_c('div',[(
        Math.trunc((this.settings.launchDate - this.now) / 1000 / 60 / 60 / 24) >= 0 &&
        Math.trunc((this.settings.launchDate - this.now) / 1000 / 60 / 60) % 24 >= 0 &&
        Math.trunc((this.settings.launchDate - this.now) / 1000 / 60) % 60 >= 0 &&
        Math.trunc((this.settings.launchDate - this.now)/1000) % 60 >= 0
       )?_c('div',[(_vm.windowWidth > 425)?_c('div',{staticClass:"container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline",attrs:{"id":"timerTextContainerScreen"}},[_c('div',{staticClass:"container-timer",attrs:{"id":"timerTextContainer1Screen"}},[_vm._m(0),_c('span',{staticClass:"timer-text",attrs:{"id":"timerNumber","day":_vm.day,"hour":_vm.hour,"min":_vm.min,"sec":_vm.sec}},[_vm._v(" "+_vm._s(_vm.day)+" : "+_vm._s(_vm.hour)+" : "+_vm._s(_vm.min)+" : "+_vm._s(_vm.sec)+" ")])])]):_c('div',{staticClass:"container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline"},[_c('div',{staticClass:"container-timer"},[_c('span',{staticClass:"timer-text",attrs:{"day":_vm.day,"hour":_vm.hour,"min":_vm.min,"sec":_vm.sec}},[_vm._v(" "+_vm._s(_vm.day)+" : "+_vm._s(_vm.hour)+" : "+_vm._s(_vm.min)+" : "+_vm._s(_vm.sec)+" ")])])])]):_c('div',[_c('div',{staticClass:"container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline"},[_c('span',{staticClass:"timer-text"},[_c('p',{attrs:{"id":"salesText"}},[_vm._v(_vm._s(_vm.totalUnits)+" UNITS SOLD")])])])])]):_c('div',[_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"timer-text",attrs:{"id":"timerTextContainer2Screen"}},[_c('h1',{attrs:{"id":"timerTextScreen"}},[_vm._v("LAUNCHING IN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline"},[_c('span',{staticClass:"timer-text"})])
}]

export { render, staticRenderFns }