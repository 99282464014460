<template>
  <!-- modal -->
  <div>
    <ul
      v-for="global in globals"
      :key="global.interestRate"
    >
      <button
        class="lb-dialog-close-btn-x fixed z-40 b-0 r-0"
        v-if="unitDialogData.open"
        v-on:click="toggleModal()"
      >
        ×
      </button>
      <div
        v-if="unitDialogData.open"
        class="zIdex fixed z-40 top-8 left-1/2 w-11/12 lg:w-11/12 transform -translate-x-1/2 rounded"
        style="
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 50px;
      "
      >
        <div>
          <!-- wrapper -->
          <div class="h-full overflow-y-scroll overflow-x-hidden" style="">
            <div class="flex flex-col lg:flex-row">
              <!-- carousel -->
              <div
                class="relative bg-white w-full lg:w-2/3 order-first lg:order-last outline-none"
                style="min-height: 200px"
              >
                <VueSlickCarousel
                  class="mb-8 css-zindex"
                  ref="carousel"
                  :dots="true"
                  :arrows="true"
                  :infinite="true"
                  :useCSS="true"
                  :useTransform="true"
                >
                  <div v-if="unitDialogData.data.mainImageURL">
                    <img
                      :src="unitDialogData.data.mainImageURL"
                      alt="Unit Overview"
                    />
                  </div>

                  <div v-if="unitDialogData.data.slide2ImageURL">
                    <img
                      :src="unitDialogData.data.slide2ImageURL"
                      alt="Unit Second Slide"
                    />
                  </div>

                  <div v-if="unitDialogData.data.slide3ImageURL">
                    <img
                      :src="unitDialogData.data.slide3ImageURL"
                      alt="Unit Third Slide"
                    />
                  </div>
                   <div v-if="unitDialogData.data.slide4ImageURL">
                    <img
                      :src="unitDialogData.data.slide4ImageURL"
                      alt="Unit Third Slide"
                    />
                  </div>
                  <div v-if="unitDialogData.data.slide5ImageURL">
                    <img
                      :src="unitDialogData.data.slide5ImageURL"
                      alt="Unit Third Slide"
                    />
                  </div>
                </VueSlickCarousel>
                <!-- carousel btns -->
                <div class="grid grid-cols-2">
                  <div class="text-right">
                    <button
                      @click="prev"
                      class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
                    >
                      &lt; Prev
                    </button>
                  </div>
                  <div>
                    <button
                      @click="next"
                      class="mb-3 px-2 py-1 ml-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
                    >
                      Next &gt;
                    </button>
                  </div>
                </div>
              </div>

              <!-- data -->
              <div
                class="flex flex-col w-full lg:w-1/3 pt-5 pb-5 lg:pt-10 px-5 bg-gray-200 z-40"
              >
                <!-- specs -->
                <div
                  class="w-full z-40">
                <span
                  v-if="unitDialogData.data.isGuaranteedRental"
                  class="has-tooltip guaranteed-rental-container z-40"
                >
                  GR
                  <span class="tooltip -bottom-5 left-10 font-normal z-40">
                    Guaranteed Rental
                  </span>
                </span>
                  <h2 class="headerCss text-2xl font-semibold tracking-widest">
                    {{ title }}
                  </h2>
                  <p v-if="unitDialogData.data.name" class="mb-2 text-xl">
                    Unit {{ unitDialogData.data.name }}
                  </p>

                  <UnitPrice :unit="unitDialogData.data" :settings="settings" :normalPrice="false" :bigPrice="true" :show="true" :tableStyle="false"/>

                  <p class="mt-2 text-sm">
                    <span v-if="unitDialogData.data.floor">
                      {{ unitDialogData.data.floor }} Floor |
                    </span>
                    <span v-if="unitDialogData.data.aspect">
                      {{ unitDialogData.data.aspect }} |
                    </span>
                    <span v-if="unitDialogData.data.aspect">
                      {{ unitDialogData.data.view }}
                    </span>
                  </p>
                  <!-- pills -->
                  <div class="flex justify-start flex-wrap mt-2">
                    <span
                      v-if="
                        unitDialogData.data.bedrooms &&
                        unitDialogData.data.bedrooms > 0
                      "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <BedroomIcon class="mr-1" />
                      {{ unitDialogData.data.bedrooms }}
                      <span class="tooltip -top-10 left-0">Bedrooms</span>
                    </span>

                    <span
                      v-if="
                      unitDialogData.data.bathrooms &&
                      unitDialogData.data.bathrooms > 0
                    "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <BathroomIcon class="mr-1" />
                      {{ unitDialogData.data.bathrooms }}
                      <span class="tooltip -top-10 left-0">Bathrooms</span>
                    </span>

                    <span
                      v-if="
                      unitDialogData.data.parking &&
                      unitDialogData.data.parking > 0 &&
                      title === 'The One Stellenbosch'
                    "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <CarIcon class="mr-1" />
                      {{ unitDialogData.data.parking }}
                      <span class="tooltip -top-10 left-0">Parking Spot</span>
                    </span>

                    <span
                      v-if="
                      unitDialogData.data.storeroom &&
                      unitDialogData.data.storeroom > 0 &&
                      title === 'The Carrington'
                    "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <StoreIcon class="mr-1" />
                      {{ unitDialogData.data.storeroom }}
                      <span class="tooltip -top-10 left-0">Storeroom</span>
                    </span>

                    <span
                      v-if="
                      unitDialogData.data.internalArea &&
                      unitDialogData.data.internalArea > 0 &&
                      unitDialogData.data.externalArea > 0
                    "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <TotalIntAreaIcon class="mr-1" />
                      {{ unitDialogData.data.internalArea }}m&sup2;
                      <span class="tooltip -top-10 left-0">Internal Area</span>
                    </span>

                    <span
                      v-if="
                      unitDialogData.data.externalArea &&
                      unitDialogData.data.externalArea > 0 &&
                      unitDialogData.data.externalArea > 0
                    "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <TotalExtAreaIcon class="mr-1" />
                      {{ unitDialogData.data.externalArea }}m&sup2;
                      <span class="tooltip -top-10 left-0">External Area</span>
                    </span>

                    <span
                      v-if="
                      unitDialogData.data.totalArea &&
                      unitDialogData.data.totalArea > 0
                    "
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <TotalAreaIcon class="mr-1" />
                      {{ unitDialogData.data.totalArea }}m&sup2;
                      <span class="tooltip -top-10 left-0">Total Area</span>
                    </span>

                    <span
                      v-if="unitDialogData.data.isAircon"
                      class="relative has-tooltip lb-icon-pill flex flex-row mt-3 mr-2"
                    >
                      <SnowflakeIcon class="mr-1" />
                      Yes
                      <span class="tooltip -top-10 left-0">Aircon</span>
                    </span>
                  </div>
                </div>

                <!-- Unit Financials Content Left Side -->
                <div
                  class="unit-spec-amounts"
                >
                  <div>
                    <p
                      v-if="unitDialogData.data.levies"
                      class="mt-2 unit-detail-text"
                    >
                      Levies:
                      <span class="font-semibold text-primary">
                        {{ unitDialogData.data.levies | prettyCurrency }}
                        <sup>pm</sup>
                      </span>
                    </p>

                    <p
                      v-if="unitDialogData.data.rates"
                      class="mt-2 unit-detail-text"
                    >
                      Rates:
                      <span class="font-semibold text-primary">
                        {{ unitDialogData.data.rates | prettyCurrency }}
                        <sup>pm</sup>
                      </span>
                    </p>

                    <div v-if="settings.allowGlobalDiscount &&
                                user.loggedIn &&
                                user.profile.answeredDiscountSurvey"
                    >
                      <!-- Unit Financials Bond Cost Global Discount Type === Amount -->
                      <div v-if="settings.globalDiscountType === 'amount'">
                        <p class="mt-2 unit-detail-text">
                          Est. Bond Cost:
                          <span class="font-semibold text-primary">
                            {{
                              PMT(
                                (global.interestRate /100 ) / 12,
                                20 * 12,
                                ((unitDialogData.data.price - settings.globalDiscountAmount) * 0.9) * -1,
                                0,
                                0
                              ) | prettyCurrency
                            }}
                            <sup>pm</sup>
                          </span>
                        </p>
                      </div>
                      <!-- Unit Financials Bond Cost Global Discount Type === Percentage -->
                      <div v-if="settings.globalDiscountType === 'percentage'">
                        <p class="mt-2 unit-detail-text">
                          Est. Bond Cost:
                          <span class="font-semibold text-primary">
                            {{
                              PMT(
                                (global.interestRate /100 ) / 12,
                                20 * 12,
                                ((unitDialogData.data.price * ((100 - settings.globalDiscountAmount) / 100)) * 0.9) * -1,
                                0,
                                0
                              ) | prettyCurrency
                            }}
                            <sup>pm</sup>
                          </span>
                        </p>
                      </div>
                    </div>
                    <!-- Unit Financials Bond Cost Global Discount Type === NONE -->
                    <div v-else>
                      <p class="mt-2 unit-detail-text">
                        Est. Bond Cost:
                        <span class="font-semibold text-primary">
                          {{
                            PMT(
                              (global.interestRate /100 ) / 12,
                              20 * 12,
                              (unitDialogData.data.price * 0.9) * -1,
                              0,
                              0
                            ) | prettyCurrency
                          }}
                          <sup>pm</sup>
                        </span>
                      </p>
                    </div>

                    <div v-if="settings.allowGlobalDiscount &&
                                user.loggedIn &&
                                user.profile.answeredDiscountSurvey"
                    >
                      <!-- Unit Financials Monthly Cost Global Discount Type === Amount -->
                      <div v-if="settings.globalDiscountType === 'amount' &&
                                  unitDialogData.data.levies &&
                                  unitDialogData.data.rates"
                      >
                        <p class="mt-2 unit-detail-text">
                          Total Monthly Cost:
                          <span class="font-semibold text-primary">
                            {{
                              (
                                unitDialogData.data.levies +
                                unitDialogData.data.rates +
                                ((-(global.interestRate / 100) / 12) *
                                  ((((unitDialogData.data.price - settings.globalDiscountAmount) * 0.9) * -1) *
                                    Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                                (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                              ).toFixed(0)

                                | prettyCurrency
                            }}
                            <sup>pm</sup>
                          </span>
                        </p>
                      </div>
                      <!-- Unit Financials Monthly Cost Global Discount Type === Percentage -->
                      <div v-if="settings.globalDiscountType === 'percentage' &&
                                  unitDialogData.data.levies &&
                                  unitDialogData.data.rates"
                      >
                        <p class="mt-2 unit-detail-text">
                          Total Monthly Cost:
                          <span class="font-semibold text-primary">
                            {{
                              (
                                unitDialogData.data.levies +
                                unitDialogData.data.rates +
                                ((-(global.interestRate / 100) / 12) *
                                  ((((unitDialogData.data.price * ((100 - settings.globalDiscountAmount) / 100)) * 0.9) * -1) *
                                    Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                                (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                              ).toFixed(0)

                                | prettyCurrency
                            }}
                            <sup>pm</sup>
                          </span>
                        </p>
                      </div>
                    </div>
                    <!-- Unit Financials Monthly Cost Global Discount Type === NONE -->
                    <div v-else>
                      <p class="mt-2 unit-detail-text">
                        Total Monthly Cost:
                        <span class="font-semibold text-primary">
                          {{
                            (
                              unitDialogData.data.levies +
                              unitDialogData.data.rates +
                              ((-(global.interestRate / 100) / 12) *
                                (((unitDialogData.data.price * 0.9) * -1) *
                                  Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                              (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                            ).toFixed(0)

                              | prettyCurrency
                          }}
                          <sup>pm</sup>
                        </span>
                      </p>
                    </div>
                  </div>
                  <!-- Unit Financials Content Right Side -->
                  <div>
                    <p
                      v-if="unitDialogData.data.unitRentalGuaranteed &&
                            unitDialogData.data.isGuaranteedRental"
                      class="mt-2 unit-detail-text"
                    >
                      <span class="font-semibold rental-guaranteed">
                        {{ unitDialogData.data.unitRentalGuaranteed }} Rental Guaranteed
                      </span>
                    </p>

                    <div>
                      <p
                        v-if="unitDialogData.data.guaranteedRental &&
                            parseInt(unitDialogData.data.guaranteedRental, 10) > 0"
                        class="mt-2 unit-detail-text"
                      >
                        Est. Rental:
                        <span class="font-semibold text-primary">
                        {{ unitDialogData.data.guaranteedRental | prettyCurrency }}
                        <sup>pm</sup>
                      </span>
                      </p>
                      <p
                        v-else
                        class="mt-2 unit-detail-text"
                      >
                        Est. Rental:
                        <span class="font-semibold text-primary">
                        {{ unitDialogData.data.guaranteedRental | prettyCurrency }}
                        <sup>pm</sup>
                      </span>
                      </p>
                    </div>

                    <div v-if="settings.allowGlobalDiscount &&
                                user.loggedIn &&
                                user.profile.answeredDiscountSurvey"
                    >
                      <!-- Unit Financials Net Return Global Discount Type === Amount -->
                      <div v-if="settings.globalDiscountType === 'amount'">
                        <p
                          v-if="unitDialogData.data.guaranteedRental &&
                                unitDialogData.data.levies &&
                                unitDialogData.data.rates"
                          class="mt-2 unit-detail-text"
                        >
                          Net Return:
                          <span class="font-semibold text-primary">
                            {{
                              (
                                ((unitDialogData.data.guaranteedRental - unitDialogData.data.levies - unitDialogData.data.rates) * 12) /
                                (unitDialogData.data.price - settings.globalDiscountAmount) * 100
                              ).toFixed(2)
                            }}
                            <sup>%</sup>
                          </span>
                        </p>
                      </div>
                      <!-- Unit Financials Net Return Global Discount Type === Percentage -->
                      <div v-if="settings.globalDiscountType === 'percentage'">
                        <p
                          v-if="unitDialogData.data.guaranteedRental &&
                                unitDialogData.data.levies &&
                                unitDialogData.data.rates"
                          class="mt-2 unit-detail-text"
                        >
                          Net Return:
                          <span class="font-semibold text-primary">
                            {{
                              (
                                ((unitDialogData.data.guaranteedRental - unitDialogData.data.levies - unitDialogData.data.rates) * 12) /
                                (unitDialogData.data.price * ((100 - settings.globalDiscountAmount) / 100)) * 100
                              ).toFixed(2)
                            }}
                            <sup>%</sup>
                          </span>
                        </p>
                      </div>
                    </div>
                    <!-- Unit Financials Net Return Global Discount Type === NONE -->
                    <div v-else>
                      <p
                        v-if="unitDialogData.data.levies &&
                              unitDialogData.data.rates"
                        class="mt-2 unit-detail-text"
                      >
                        Net Return:
                        <span class="font-semibold text-primary">
                            {{
                            (
                              ((unitDialogData.data.guaranteedRental - unitDialogData.data.levies - unitDialogData.data.rates) * 12) / unitDialogData.data.price * 100
                            ).toFixed(2)
                          }}
                          <sup>%</sup>
                        </span>
                      </p>
                    </div>

                    <div v-if="settings.allowGlobalDiscount &&
                                user.loggedIn &&
                                user.profile.answeredDiscountSurvey"
                    >
                      <!-- Unit Financials Monthly Shortfall Global Discount Type === Amount -->
                      <div v-if="settings.globalDiscountType === 'amount'">
                        <p v-if="unitDialogData.data.guaranteedRental &&
                                  unitDialogData.data.levies &&
                                  unitDialogData.data.rates"
                           class="mt-2 unit-detail-text"
                        >
                          Monthly shortfall:
                          <span class="font-semibold text-primary">
                            {{
                              (
                                (
                                  unitDialogData.data.levies +
                                  unitDialogData.data.rates +
                                  ((-(global.interestRate / 100) / 12) *
                                    ((((unitDialogData.data.price - settings.globalDiscountAmount) * 0.9) * -1) *
                                      Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                                  (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                                ) - unitDialogData.data.guaranteedRental
                              ).toFixed(0)

                                | prettyCurrency
                            }}
                            <sup>pm</sup>
                          </span>
                        </p>
                      </div>
                      <!-- Unit Financials Monthly Shortfall Global Discount Type === Percentage -->
                      <div v-if="settings.globalDiscountType === 'percentage'">
                        <p v-if="unitDialogData.data.guaranteedRental &&
                                  unitDialogData.data.levies &&
                                  unitDialogData.data.rates"
                           class="mt-2 unit-detail-text"
                        >
                          Monthly shortfall:
                          <span class="font-semibold text-primary">
                            {{
                              (
                                (
                                  unitDialogData.data.levies +
                                  unitDialogData.data.rates +
                                  ((-(global.interestRate / 100) / 12) *
                                    ((((unitDialogData.data.price * ((100 - settings.globalDiscountAmount) / 100)) * 0.9) * -1) *
                                      Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                                  (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                                ) - unitDialogData.data.guaranteedRental
                              ).toFixed(0)

                                | prettyCurrency
                            }}
                            <sup>pm</sup>
                          </span>
                        </p>
                      </div>
                    </div>
                    <!-- Unit Financials Monthly Shortfall Global Discount Type === NONE -->
                    <div v-else>
                      <p  v-if="unitDialogData.data.levies &&
                                unitDialogData.data.rates"
                          class="mt-2 unit-detail-text"
                      >
                        Monthly shortfall:
                        <span class="font-semibold text-primary">
                          {{
                            (
                              (
                                unitDialogData.data.levies +
                                unitDialogData.data.rates +
                                ((-(global.interestRate / 100) / 12) *
                                  (((unitDialogData.data.price * 0.9) * -1) *
                                    Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) + 0)) /
                                (Math.pow(1 + ((global.interestRate / 100) / 12), (20 * 12)) - 1)
                              ) - unitDialogData.data.guaranteedRental
                            ).toFixed(0)

                              | prettyCurrency
                          }}
                          <sup>pm</sup>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <!-- action buttons -->
                <div class="flex flex-col mt-5" v-if="settings.isGlobalOverrideAction === true">
                  <a
                    :href="settings.globalOverrideAction"
                    target="_blank"
                  >
                    <button
                      class="bg-primary w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                    >
                      {{settings.globalOverrideActionButton}}
                    </button>
                  </a>
                </div>

                <div v-else>
                  <div class="flex flex-col mt-5">
                    <div v-if="unitDialogData.data.isOverrideAction === true">
                      <a
                        :href="unitDialogData.data.unitOverrideAction"
                        target="_blank"
                      >
                        <button
                          class="bg-primary w-full mb-3 px-6 py-4 uppercase text-sm font-semibold tracking-wider rounded outline-none border border-solid focus:outline-none"
                        >
                          {{unitDialogData.data.unitOverrideActionButton}}
                        </button>
                      </a>
                    </div>

                    <div class="has-tooltip" v-else-if="settings.allowReserve">
                      <button
                        disabled
                        class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                        v-if="unitDialogData.data.status === 'pending' || unitDialogData.data.status === 'pendingUnitDetails' || unitDialogData.data.status === 'pendingPayment'"
                      >
                        Pending
                      </button>
                      <button
                        disabled
                        class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                        v-if="unitDialogData.data.status === 'reserved'"
                      >
                        Reserved
                      </button>

                      <button
                        disabled
                        class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                        v-if="unitDialogData.data.status === 'sold'"
                      >
                        Sold
                      </button>

                      <button
                        class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-green-500 border hover:bg-green-600 border-solid border-green-500 focus:outline-none"
                        @click="unitReserve(unitDialogData.data.name)"
                        v-if="
                      unitDialogData.data.status === 'available' && !disableReserve  && !unitDialogData.data.preReserved
                    "
                      >
                        Reserve
                      </button>
                                            <button
                        disabled
                        class="w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-black tracking-wider rounded outline-none bg-gray-500 border border-solid border-primary focus:outline-none cursor-not-allowed"
                        v-if="
                      unitDialogData.data.status === 'available' && !disableReserve  && unitDialogData.data.preReserved
                    ">
                        Pending
                      </button>
                      <button
                        class="relative bg-gray-400 w-full mb-3 px-6 py-4 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none focus:outline-none"
                        v-if="
                      unitDialogData.data.status === 'available' &&
                      disableReserve
                    "
                      >
                      <span class="text-md font-semibold uppercase">
                        Reserve
                      </span>
                        <span class="tooltip sentenceCaseCss lightweight -bottom-0 -right-0">This reserve button will become active once sales go live</span>
                      </button>
                    </div>
                </div>


                </div>

                <div class="flex flex-row">
                  <div class="w-1/2 pr-2">
                    <button
                      @click.prevent="openContactDialog()"
                      class="flex flex-row items-center justify-center w-full mb-3 px-6 py-2 font-bold tracking-wider uppercase text-sm rounded outline-none text-white bg-secondary hover:bg-secondarydark border border-solid border-seondary hover:text-white focus:outline-none"
                    >
                      <ContactIcon :size="23" class="mr-1" />
                      Contact
                    </button>
                  </div>

                  <div class="w-1/2 pl-2">
                    <AddToShortlist
                      :unitID="unitDialogData.data.id"
                      :unitName="unitDialogData.data.name"
                      :show="true"
                      :showTooltip="true"
                      :homepageView="false"
                    />
                  </div>
                </div>

                <!-- Share -->
                <div
                  class="flex flex-row justify-center pt-2 pb-2 border-t border-b border-gray-600"
                >
                  <span class="mr-2 uppercase">Share Listing</span>
                  <a
                    :href="shareLinks.mail"
                    class="mr-2 cursor-pointer hover:text-primary"
                  >
                    <EmailIcon />
                  </a>
                  <a
                    class="mr-2 cursor-pointer hover:text-primary"
                    v-clipboard:copy="shareLinks.link"
                    @click="handleLinkClipboardCopy"
                  >
                    <LinkIcon />
                  </a>
                </div>

                <!-- Disclaimer -->

                <div class="mt-4 text-xs text-center">
                  <p class="text-xs py-1 text-left"><b>Please note: </b></p>
<!--                  <p class="text-xs text-left">Guaranteed rentals are available for units secured on launch, calculated at 7% of the final purchase price.</p>-->
                  <p class="text-xs text-left">Estimated bond cost is calculated on 90% finance over 20 years, using the current prime lending rate.</p>
                  </div>
<!--                <div class="mt-2 text-xs text-center">-->
<!--                  <p class="text-xs text-left">Estimated bond cost is calculated on 90% finance over 20 years, using the current prime lending rate.</p>-->
<!--                </div>-->

                <div class="mt-2 text-xs text-center">
                  <p class="text-xs text-left">Unit layout may differ slightly from axonometric.</p>
                </div>
                <div class="mt-2 text-xs text-center">
                  <p class="text-xs text-left">T&C's apply</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="unitDialogData.open"
        @click="toggleModal()"
        class="lb-dialog-background"
      ></div>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "@/main";
import { APP_TITLE} from "@/Constants";

import AddToShortlist from "./AddToShortlist.vue";
import UnitPrice from "./shared/UnitPrice.vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

import BathroomIcon from "vue-material-design-icons/ShowerHead.vue";
import BedroomIcon from "vue-material-design-icons/BedKingOutline.vue";
import CarIcon from "vue-material-design-icons/Car.vue";
import StoreIcon from "vue-material-design-icons/Store.vue";
import TotalAreaIcon from "vue-material-design-icons/RulerSquare.vue";
import TotalExtAreaIcon from "vue-material-design-icons/FlipToBack.vue";
import TotalIntAreaIcon from "vue-material-design-icons/FlipToFront.vue";
import SnowflakeIcon from "vue-material-design-icons/Snowflake.vue";

import ContactIcon from "vue-material-design-icons/FaceAgent.vue";

import EmailIcon from "vue-material-design-icons/EmailOutline.vue";

import LinkIcon from "vue-material-design-icons/LinkVariant.vue";
import {settingsCollection} from "@/firebase";

export default {
  name: "UnitDialog",
  components: {
    AddToShortlist,
    UnitPrice,
    BathroomIcon,
    BedroomIcon,
    CarIcon,
    StoreIcon,
    TotalAreaIcon,
    TotalExtAreaIcon,
    TotalIntAreaIcon,
    ContactIcon,
    EmailIcon,
    LinkIcon,
    SnowflakeIcon,
    VueSlickCarousel,
  },
  props: {
    units: {
      type: Array,
        default: () => []
    },
    unitDialogData: {
      type: Object,
    },
    settings: {
      type: Object,
    },
  },
  data() {
    return {
      title: APP_TITLE,
      globals: [],
      roleStatuses: ['admin', 'admin-viewonly', 'superadmin']
    };
  },
  methods: {
    updateNow() {
      this.now = Date.now();
    },
    PMT(ir, np, pv, fv, type) {
      let pmt, pvif;

      fv || (fv = 0);
      type || (type = 0);

      if (ir === 0) return -(pv + fv) / np;

      pvif = Math.pow(1 + ir, np);
      pmt = (-ir * (pv * pvif + fv)) / (pvif - 1);

      if (type === 1) pmt /= 1 + ir;

      return pmt.toFixed(0);
    },
    toggleModal: function () {
      this.unitDialogData.open = !this.unitDialogData.open;
      const el = document.body;
      el.style.overflow = "auto";
    },
    prev() {
      this.$refs.carousel.prev();
    },
    next() {
      this.$refs.carousel.next();
    },
    unitReserve(unitName) {
      this.toggleModal();
      this.$router.push({ name: "reserve", params: { name: unitName } });
    },
    handleLinkClipboardCopy() {
      this.$notify({
        group: "global",
        type: "info",
        title: "Link added to Clipboard",
      });
    },
    openContactDialog() {
      bus.$emit("update:unit-contact-data", { open: true });
      this.toggleModal();
      this.unitDialogData.open = false;
    },
    setTooltip() {
      this.$emit("update:tooltip", true);
    },
  },
  created() {
    settingsCollection.orderBy("interestRate").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          interestRate: doc.data().interestRate,
        });
      });
      this.globals = globals;
    });


  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    disableReserve: function () {
      if (this.settings && this.settings.launchDate && this.settings.launchDate > this.now) {
        if (this.unitDialogData.isOneDayOnlyDiscount === true) {
          return false;
        }
        if (this.roleStatuses.includes(this.user.profile.role) && this.settings.bypassAdmin === true) {
          return false;
        }
        return true;
      }
      return false;
    },
    shareLinks: function () {
      // --- LINKS ---
      // https://twitter.com/intent/tweet?url=google.com&text=
      // https://www.linkedin.com/shareArticle?mini=true&url=google.com
      // https://pinterest.com/pin/create/button/?url=google.com&media=&description=

      let url = `${window.location.origin}/unit/${this.unitDialogData.data.name}`;

      const title = APP_TITLE;
      const emailSubject = `I found an apartment you may be interested in at ${title}.`;
      const emailBody = `Click here to view it online: ${url}`;
      const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;

      return {
        mail: encodeURI(emailLink),
        // --- LINKS ---
        // facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
        // twitter: `https://twitter.com/intent/tweet?url=${url}`,
        link: url,
      };
    },
  },
  mounted() {
    setInterval(this.updateNow, 1000);
  },
};
</script>

<style lang="css" scoped>

.zIdex {
  z-index: 99999 !important;
}
.lb-dialog-close-btn-x {
  z-index: 999999 !important;
}
.tooltip .tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 9999;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
}

.slick-slider {
  touch-action: auto !important;
}
  @media only screen and (max-width: 768px) {
  /* .reserveTooltip {
      display: none;
} */
  }
.guaranteed-rental-container {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  font-weight: 600;
  padding: 0.25rem;
  border-radius: 0.25rem;
  z-index: 999999 !important;
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.lightweight {
  font-weight: 400;
}


.rental-guaranteed {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.unit-detail-text {
  font-size: .750rem;
  line-height: 1.25rem;
}
.headerCss {
  letter-spacing: 0.0rem;
}
.unit-spec-amounts {
  display: flex;
  border-top-width: 1px;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  margin-top: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.5rem;
  justify-content: space-between;
}
.sentenceCaseCss {
    text-transform: none !important;
  }

@media only screen and (max-width: 425px) {
  .css-zindex {
    z-index: 38;
  }
  .unit-spec-amounts {
    display: flex;
    border-top-width: 1px;
    border-bottom-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
    margin-top: 0.75rem;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    justify-content: space-between;
    flex-direction: column;
  }
}
</style>
