import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    filteredUnits: [],
    images: [],
    units: [],
    realtimeTotal: 0,
    user: {
      loggedIn: false,
      data: null,
      profile: null,
    },
    leads: {
      showNotesModal: false,
      showContactModal: false,
      showReminderModal: false,
      contactMethod: '',
      lead: {}
    }
  },
  getters: {
    user(state){
      return state.user
    },
    units(state){
      return state.units
    },
    filteredUnits(state){
      return state.filteredUnits
    },
    profile: state => {
      return state.profile
    },
    leads: state => {
      return state.leads
    },
    realtimeTotal(state){
      return state.realtimeTotal
    },
    images(state){
      return state.images
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_USER(state, data) {
      state.user.data = data
    },
    SET_PROFILE(state, data) {
      state.user.profile = data
    },
    SHOW_NOTES_MODAL(state, data) {
      state.leads.showNotesModal = data
    },
    SHOW_CONTACT_MODAL(state, data) {
      state.leads.showContactModal = data
    },
    SHOW_REMINDER_MODAL(state, data) {
      state.leads.showReminderModal = data
    },
    SET_CONTACT_METHOD(state, data) {
      state.leads.contactMethod = data
    },
    SET_LEAD(state, data) {
      state.leads.lead = data
    },
    SET_REALTIME_TOTAL(state, data) {
      state.realtimeTotal = data;
    },
    SET_UNITS(state, data) {
      state.units = data;
    },
    SET_FILTERED_UNITS(state, data) {
      state.filteredUnits = data;
    },
    SET_IMAGES(state, images) {
      state.images = images;
    }
  },
  actions: {
    handleRealTimeTotal({commit}, total) {
      commit('SET_REALTIME_TOTAL', total || 10)
    },
    handleUnits({commit}, payload) {
      commit('SET_UNITS', payload)
    },
    handleFilteredUnits({commit}, payload) {
      commit('SET_FILTERED_UNITS', payload)
    },
    handleImages({commit}, images) {
      commit('SET_IMAGES', images)
    },
    fetchUser({commit}, user) {
      commit('SET_LOGGED_IN', user !==null)
      if(user) {
        commit('SET_USER', {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
        })
      }
    },
    setProfile({commit}, profile) {
      commit('SET_PROFILE', profile)
    }
  }
})