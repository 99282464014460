<template>
<div>
  <div v-if="normalPrice">
    <!-- TODO: Use the settings prop data -->
    <ul
    >

      <div v-if="
          settings.setOneDayOnlyDiscount &&
          unit.isOneDayOnlyDiscount &&
          (unit.status === 'available' || pendingStatuses.includes(unit.status) || unit.status === 'reserved')
      ">
         <span
           :class="{
                'line-through': user.loggedIn || !user.loggedIn,
              }"
           class="block font-bold text-xs text-black"
           v-if="(user.loggedIn || !user.loggedIn && unit.isOneDayOnlyDiscount)"
         >
            {{ unit.price | prettyCurrency }}
         </span>

        <!-- User signed in but did not answer the buyers profile -->
        <div
          class="inline-block"
          v-if="user.loggedIn || !user.loggedIn && unit.isOneDayOnlyDiscount"
        >

          <span
            v-bind:class="{
              'block font-extrabold textSize text-primary': !tableStyle,
              'block md:mt-2': tableStyle,
            }"
          >
            {{ unit.price - unit.oneDayOnlyDiscount | prettyCurrency }}
          </span>
        </div>
      </div>

      <div v-else-if="settings.allowDiscountNoSurvey">
        <div v-if="unit.status === 'sold' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div v-else-if="unit.status === 'reserved' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div v-else-if="pendingStatuses.includes(unit.status) && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <div v-else-if="unit.status === 'available' && settings.hidePriceOnSold">
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
            }"
            class="block font-bold text-xs text-black"
            v-if="(user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey)"
          >
            {{ unit.price | prettyCurrency }}
          </span>

          <div v-if="user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey">
            <ul

            >
              <div
                v-if="settings.globalDiscountType === 'amount'"
              >
                <ul

                >
              <span
                class="block font-extrabold text-md text-primary"
                v-if="settings.allowDiscountNoSurvey"
              >
                {{ unit.price - settings.globalDiscountAmount | prettyCurrency }}
              </span>
                </ul>
              </div>
              <div
                v-if="settings.globalDiscountType === 'percentage'"
              >
                <ul
                >
               <span
                 class="block font-extrabold text-md text-primary"
                 v-if="settings.allowDiscountNoSurvey"
               >
                 {{ unit.price * ((100 - settings.globalDiscountAmount) / 100) | prettyCurrency }}
               </span>
                </ul>
              </div>
            </ul>
          </div>
        </div>
        <div v-if="settings.allowDiscountNoSurvey && !settings.hidePriceOnSold">
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
            }"
            class="block font-bold text-xs text-black"
            v-if="(user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey)"
          >
            {{ unit.price | prettyCurrency }}
          </span>

          <div v-if="user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey">
            <ul

            >
              <div
                v-if="settings.globalDiscountType === 'amount'"
              >
                <ul

                >
              <span
                class="block font-extrabold text-md text-primary"
                v-if="settings.allowDiscountNoSurvey"
              >
                {{ unit.price - settings.globalDiscountAmount | prettyCurrency }}
              </span>
                </ul>
              </div>
              <div
                v-if="settings.globalDiscountType === 'percentage'"
              >
                <ul
                >
               <span
                 class="block font-extrabold text-md text-primary"
                 v-if="settings.allowDiscountNoSurvey"
               >
                 {{ unit.price * ((100 - settings.globalDiscountAmount) / 100) | prettyCurrency }}
               </span>
                </ul>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="unit.status === 'sold' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div v-else-if="unit.status === 'reserved' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div v-else-if="pendingStatuses.includes(unit.status) && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
           >
            Pending
          </span>
        </div>
        <!-- If allowDiscount -->
        <div v-else-if="settings.allowGlobalDiscount">
          <span
            :class="{
              'line-through': user.loggedIn,
            }"
            class="block font-bold text-xs text-black"
            v-if="(user.loggedIn && user.profile.answeredDiscountSurvey)"
          >
            {{ unit.price | prettyCurrency }}
          </span>

          <!-- User signed in but did not answer the buyers profile -->
          <div
            class="inline-block"
            v-if="
              !user.loggedIn ||
              (user.loggedIn && !user.profile.answeredDiscountSurvey)
            "
          >
          <span
            class="block font-extrabold text-primary"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            {{ unit.price | prettyCurrency }}
          </span>
            <router-link
              :to="{ name: !user.loggedIn ? 'login' : 'survey' }"
              class="flex flex-row px-2 py-1 font-extrabold text-xs text-white uppercase bg-green-500 rounded"
              v-if="show"
            >
              <UnlockIcon class="text-xs" />
              <span class="mt-1 ml-1">discount</span>
            </router-link>
          </div>

          <div v-if="user.loggedIn && user.profile.answeredDiscountSurvey">
            <ul
            >
              <div
                v-if="settings.globalDiscountType === 'amount'"
              >
                <ul
                >
              <span
                class="block font-extrabold text-md text-primary"
                v-if="settings.allowGlobalDiscount"
              >
                {{ unit.price - settings.globalDiscountAmount | prettyCurrency }}
              </span>
                </ul>
              </div>
              <div
                v-if="settings.globalDiscountType === 'percentage'"
              >
                <ul

                >
               <span
                 class="block font-extrabold text-md text-primary"
                 v-if="settings.allowGlobalDiscount"
               >
                 {{ unit.price * ((100 - settings.globalDiscountAmount) / 100) | prettyCurrency }}
               </span>
                </ul>
              </div>
            </ul>
          </div>
        </div>

        <!-- If allowDiscount = false -->
        <div v-else>
          <span
            class="block font-extrabold text-primary"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            {{ unit.price | prettyCurrency }}
          </span>
        </div>
      </div>
    </ul>
  </div>
    <div v-if="bigPrice">
    <ul

    >

      <div v-if="
          settings.setOneDayOnlyDiscount &&
          unit.isOneDayOnlyDiscount &&
          (unit.status === 'available' || pendingStatuses.includes(unit.status) || unit.status === 'reserved')
      ">
         <span
           :class="{
                'line-through': user.loggedIn || !user.loggedIn,
              }"
           class="block font-bold text-xs text-black"
           v-if="(user.loggedIn || !user.loggedIn && unit.isOneDayOnlyDiscount)"
         >
            {{ unit.price | prettyCurrency }}
         </span>

        <!-- User signed in but did not answer the buyers profile -->
        <div
          class="inline-block"
          v-if="user.loggedIn || !user.loggedIn && unit.isOneDayOnlyDiscount"
        >
          <span
            class="block font-extrabold text-primary"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            {{ unit.price - unit.oneDayOnlyDiscount | prettyCurrency }}
          </span>
        </div>
      </div>

      <div v-else-if="settings.allowDiscountNoSurvey">
        <div v-if="unit.status === 'sold' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div v-else-if="unit.status === 'reserved' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div v-else-if="pendingStatuses.includes(unit.status) && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <div v-if="unit.status === 'available' && settings.hidePriceOnSold">
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
            }"
            class="block font-bold text-xs text-black"
            v-if="(user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey)"
          >
            {{ unit.price | prettyCurrency }}
          </span>

          <div v-if="user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey">
            <ul
            >
              <div
                v-if="settings.globalDiscountType === 'amount'"
              >
                <ul
                >
              <span
                class="block font-extrabold text-md text-primary"
                v-if="settings.allowDiscountNoSurvey"
              >
                {{ unit.price - settings.globalDiscountAmount | prettyCurrency }}
              </span>
                </ul>
              </div>
              <div
                v-if="settings.globalDiscountType === 'percentage'"
              >
                <ul
                >
               <span
                 class="block font-extrabold text-md text-primary"
                 v-if="settings.allowDiscountNoSurvey"
               >
                 {{ unit.price * ((100 - settings.globalDiscountAmount) / 100) | prettyCurrency }}
               </span>
                </ul>
              </div>
            </ul>
          </div>
        </div>
        <div v-if="settings.allowDiscountNoSurvey && !settings.hidePriceOnSold">
          <span
            :class="{
              'line-through': user.loggedIn || !user.loggedIn,
            }"
            class="block font-bold text-xs text-black"
            v-if="(user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey)"
          >
            {{ unit.price | prettyCurrency }}
          </span>

          <div v-if="user.loggedIn || !user.loggedIn && settings.allowDiscountNoSurvey">
            <ul
            >
              <div
                v-if="settings.globalDiscountType === 'amount'"
              >
                <ul
                >
              <span
                class="block font-extrabold text-md text-primary"
                v-if="settings.allowDiscountNoSurvey"
              >
                {{ unit.price - settings.globalDiscountAmount | prettyCurrency }}
              </span>
                </ul>
              </div>
              <div
                v-if="settings.globalDiscountType === 'percentage'"
              >
                <ul
                >
               <span
                 class="block font-extrabold text-md text-primary"
                 v-if="settings.allowDiscountNoSurvey"
               >
                 {{ unit.price * ((100 - settings.globalDiscountAmount) / 100) | prettyCurrency }}
               </span>
                </ul>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="unit.status === 'sold' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Sold
          </span>
        </div>
        <div v-else-if="unit.status === 'reserved' && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Reserved
          </span>
        </div>
        <div v-else-if="pendingStatuses.includes(unit.status) && settings.hidePriceOnSold">
          <span
            class="block font-extrabold text-black uppercase"
            :class="tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            Pending
          </span>
        </div>
        <!-- If allowDiscount -->
        <div v-else-if="settings.allowGlobalDiscount">
          <span
            :class="{
              'line-through': user.loggedIn,
            }"
            class="block priceBlackCss font-bold text-xs text-black"
            v-if="(user.loggedIn && user.profile.answeredDiscountSurvey)"
          >
            {{ unit.price | prettyCurrency }}
          </span>

          <!-- User signed in but did not answer the buyers profile -->
          <div
            class="inline-block"
            v-if="
              !user.loggedIn ||
              (user.loggedIn && !user.profile.answeredDiscountSurvey)
            "
          >
          <span
            class="block font-extrabold text-primary"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            {{ unit.price | prettyCurrency }}
          </span>
            <router-link
              :to="{ name: !user.loggedIn ? 'login' : 'survey' }"
              class="flex flex-row px-2 py-1 font-extrabold text-xs text-white uppercase bg-green-500 rounded"
              v-if="show"
            >
              <UnlockIcon class="text-xs" />
              <span class="mt-1 ml-1">discount</span>
            </router-link>
          </div>

          <div v-if="user.loggedIn && user.profile.answeredDiscountSurvey">
            <ul
            >
              <div
                v-if="settings.globalDiscountType === 'amount'"
              >
                <ul
                >
              <span
                class="block priceGoldCss font-extrabold text-md text-primary"
                v-if="settings.allowGlobalDiscount"
              >
                {{ unit.price - settings.globalDiscountAmount | prettyCurrency }}
              </span>
                </ul>
              </div>
              <div
                v-if="settings.globalDiscountType === 'percentage'"
              >
                <ul
                >
               <span
                 class="block font-extrabold text-md text-primary"
                 v-if="settings.allowGlobalDiscount"
               >
                 {{ unit.price * ((100 - settings.globalDiscountAmount) / 100) | prettyCurrency }}
               </span>
                </ul>
              </div>
            </ul>
          </div>
        </div>

        <!-- If allowDiscount = false -->
        <div v-else>
          <span
            class="block font-extrabold text-primary"
            :class="!tableStyle ? 'textSize' : 'block md:mt-2'"
          >
            {{ unit.price | prettyCurrency }}
          </span>
        </div>
      </div>
    </ul>
  </div>
</div>
</template>


<script>
import { mapGetters } from "vuex";
import UnlockIcon from "vue-material-design-icons/Lock.vue";
// import {settingsCollection} from "@/firebase";

export default {
  name: "UnitPrice",
  components: {
    UnlockIcon,
  },
  props: {
    unit: {
      default: null,
    },
    // TODO: add prop that will allow different styling based on where this component is placed and not specific style props
    tableStyle: {
      type: Boolean,
      default: false,
    },
    settings: {
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
    bigPrice: {
      type: Boolean,
      default: true
    },
    normalPrice: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    globals: [],
    now: new Date(),
    pendingStatuses: ['pending', 'pendingUnitDetails', 'pendingPayment']
  }),
  computed: {
    ...mapGetters({
      user: "user",
    }),
    globalDiscountedPrice() {
      if (this.settings.allowGlobalDiscount && this.unit && this.unit.price) {
        if (this.settings.globalDiscountType === "percentage") {
          return (
            this.unit.price * ((100 - this.settings.globalDiscountAmount) / 100)
          );
        }
        return this.unit.price - this.settings.globalDiscountAmount;
      }
      return 0;
    },
  },
  methods: {
    isDiscountedUnit(unit) {
      let discountedUnits = [
        // "0801",
        // "0804",
        // "0807",
        // "0810",
        // "1102",
        // "1105",
        // "1106",
        // "1211",
        // "1209",
      ];
      return discountedUnits.includes(unit.name);
    },
  },
};
</script>

<style>
.textSize2 {
  font-size: 14px;
  line-height: 1.75rem;
}
.textSize {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
span.material-design-icon {
  margin-top: 5px !important;
}
 .priceGoldCss {
    font-size: 28px !important;
    font-weight: 600;
  } 
     .priceBlackCss {
    font-size: 18px !important;
    font-weight: 550;
  }
@media only screen and (max-width: 425px) {
  svg.material-design-icon__svg {
    padding: 5px 0 0 0 !important;
  }
}
@media only screen and (max-width: 375px) {
  .textSize {
    font-size: .8rem;
    line-height: 1.75rem;
  }
}
</style>
