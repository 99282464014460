<template>
  <div>
    <!-- Global Menu Content -->
    <div class="global-Menu-Content" x-data="{ open: true }">
      <span class="relative inline-flex rounded-md shadow-sm">
      <!-- Table & computer -->
        <div v-if="windowWidth > 425">
          <button
            @click="
              displayDropdown = !displayDropdown;
              open = !open;
            "
            type="button"
            class="inline-flex items-center px-4 py-2 text-white text-sm font-semibold uppercase tracking-wider transition ease-in-out duration-150 rounded hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          >
            <h1 id="menuText" class="font-bold text-3xl pr-4">MENU</h1>
            <div>
            <MenuIcon v-if="!open" :size="30" />
            <CloseIcon v-if="open" :size="30" />
            </div>
          </button>
        </div>

        <!-- Mobi -->
        <div v-else>
          <button
            @click="
              displayDropdown = !displayDropdown;
              open = !open;
            "
            type="button"
            class="inline-flex items-center px-2 py-1 text-white text-sm font-semibold uppercase tracking-wider transition ease-in-out duration-150 rounded hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          >
            <MenuIcon v-if="!open" :size="25" />
            <CloseIcon v-if="open" :size="25" />
          </button>
        </div>

      </span>

      <div v-if="user && user.loggedIn">
        <!-- Drop Down Menu Content -->
        <div
          v-if="displayDropdown"
          class="menu-dropdown-container"
        >
          <div class="menu-dropdown-content shadow-lg">
            <ul
              v-on-clickaway="closeDropdown"
              class="pt-4 pr-4 pb-0 pl-4 text-center font-semibold"
            >
              <!-- Global Discount Content -->
              <div
                v-for="global in globals"
                :key="global.allowDiscountNoSurvey"
              >
                <li v-if="settings.allowDiscount && user.loggedIn && !global.allowDiscountNoSurvey">
                  <MainNavBarProfileDropdownDiscount :close-menu="closeDropdown" />
                </li>
              </div>

              <!-- Shortlist Content -->
              <li
                class="mb-4"
              >
                <MainNavBarProfileDropdownShortlist
                  :close-menu="closeDropdown"
                  :user="user"
                  :settings="settings"
                  @update:tooltip="updateTooltip($event)"
                />
              </li>
              <!-- Contact Us Content -->
              <li>
                <MainNavBarContactButton />
              </li>

              <!-- Dynamic Management Menu Content from Admin -->
              <li  v-on:click="closeDropdown" class="mb-4" v-for="item in menuItems" :key="item.id" >
                <div v-if="item.active === true">
                  <a
                    v-if="showDownloadMenuItems(item)"
                    :href="item.filePath"
                    target="_blank"
                    class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="showLinkMenuItems(item)"
                    :href="item.url"
                    :target="item.showinnewtab ? '_blank' : '_self'"
                    class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </li>

              <!-- User Profile Content -->
              <li  v-on:click="closeDropdown" class="mb-4">
                <router-link
                  :to="{ name: 'profile' }"
                  @click="displayDropdown = false"
                  class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                >
                  My Profile
                </router-link>
              </li>

              <!-- Admin Btn Content -->
              <li v-on:click="closeDropdown" class="mb-4" v-if="user && user.loggedIn && userRoleStatuses.includes(user.profile.role)">
                <router-link
                  :to="{ name: 'admin' }"
                  @click="displayDropdown = false"
                  class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                >
                  Admin Dashboard
                </router-link>
              </li>

              <!-- Menu Content -->
              <li v-on:click="closeDropdown" class="mb-4">
                <a
                  class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                  @click="signOut"
                >
                  Sign Out</a
                >
              </li>
              <!-- Menu Footer Content -->
              <li
                v-on:click="closeDropdown"
                v-if="title === 'The One Stellenbosch'"
                class="mb-4"
              >
                <a
                  href="https://www.launchbase.co.za"
                  target="_blank"
                  class="block w-full text-xs text-gray-400 p-2 rounded hover:text-white hover:bg-primary cursor-pointer"
                >
                  About LaunchBase</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-if="displayDropdown"
          class="menu-dropdown-container"
        >
          <div class="menu-dropdown-content shadow-lg">
            <ul
              v-on-clickaway="closeDropdown"
              class="pt-4 pr-4 pb-0 pl-4 text-center font-semibold"
            >
              <!-- Auth Content -->
              <li
                v-on:click="closeDropdown"
                class="mb-4"
              >
                <router-link
                  class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                  :to="{ name: 'login' }"
                >
                  Login / Sign Up
                </router-link>
              </li>

              <!-- Contact Us Content -->
              <li>
                <MainNavBarContactButton />
              </li>

              <!-- Dynamic Management Menu Content from Admin -->
              <li  v-on:click="closeDropdown" class="mb-4" v-for="item in menuItems" :key="item.id" >
                <div v-if="item.active === true">
                  <a
                    v-if="showDownloadMenuItems(item)"
                    :href="item.filePath"
                    target="_blank"
                    class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                  <a
                    v-if="showLinkMenuItems(item)"
                    :href="item.url"
                    :target="item.showinnewtab ? '_blank' : '_self'"
                    class="block w-full p-2 text-black rounded hover:text-white hover:bg-primary cursor-pointer"
                  >
                    {{ item.title }}
                  </a>
                </div>
              </li>

              <!-- Menu Footer Content -->
              <li
                v-on:click="closeDropdown"
                v-if="title === 'The One Stellenbosch'"
                class="mb-4"
              >
                <a
                  href="https://www.launchbase.co.za"
                  target="_blank"
                  class="block w-full text-xs text-gray-400 p-2 rounded hover:text-white hover:bg-primary cursor-pointer"
                >
                  About LaunchBase</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>

    </div>

    <!-- Reserve tooltip -->
    <div
      v-if="showTooltip"
      class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center z-60"
    >
      <div class="relative max-w-5xl">
        <button
          @click="showTooltip = !showTooltip"
          class="z-50 absolute -top-5 -right-4 px-2 text-white text-4xl leading-none font-semibold border-0 bg-primary rounded-full outline-none focus:outline-none"
        >
          ×
        </button>
        <div class="w-full bg-black text-white py-4 px-12 rounded opacity-75">
          Available when sales go live
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import {settingsCollection, downloadsCollection} from "../../firebase.js";
import { mapGetters } from "vuex";
import { mixin as clickaway } from "vue-clickaway";
import isNil from "lodash/isNil";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";

import MainNavBarProfileDropdownDiscount from "./MainNavBarProfileDropdownDiscount.vue";
import MainNavBarProfileDropdownShortlist from "./MainNavBarProfileDropdownShortlist.vue";
import MainNavBarContactButton from "./MainNavBarContactButton.vue";
import {APP_TITLE} from "@/Constants";

export default {
  name: "MainNavBarDownloadsDropdown",
  props: {
    units: {
      type: Array,
        default: () => []
    },
    // user: {
    //   type: Object,
    // },
  },
  components: {
    MenuIcon,
    CloseIcon,
    MainNavBarProfileDropdownDiscount,
    MainNavBarProfileDropdownShortlist,
    MainNavBarContactButton,
  },
  data: () => ({
    title: APP_TITLE,
    globals: [],
    windowWidth: window.innerWidth,
    // downloads: [],
    displayDropdown: false,
    componentLoading: false,
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
      hidePriceOnSold: false,
      setOneDayOnlyDiscount: false,
      allowGlobalDiscount: false,
      globalDiscountAmount: null,
      globalDiscountType: '',

      isGlobalOverrideAction: false,
      globalOverrideAction: null,
      globalOverrideActionButton: null,

      showAvailableUnits: '',

      isUsersOnline: false,
      allowDiscountNoSurvey: false,

      isLaunchDate: false,
      interestRate: null,
      logo: null,
      headerImage: null,
      allowDeposit: false,
      depositType: null,
      depositAmount: null,
    },
    showTooltip: false,
    open: false,
    menuItems: [],
    userRoleStatuses: ['admin', 'superadmin', 'agent-principal', 'agent', 'admin-viewonly']
  }),
  mixins: [clickaway],

  methods: {
    showDownloadMenuItems(item) {
      let result = false;
      if (item.filePath && (!item.url || (item.url && item.url === ""))) {
        result = true;
        if (item.loggedinonly && !this.user.loggedIn) {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    showLinkMenuItems(item) {
      let result = false;
      if (item.showupload && item.url || !item.showupload && item.url) {
        result = true;
        if (item.loggedinonly && !this.user.loggedIn) {
          result = false;
        }
      } else {
        result = false;
      }
      return result;
    },
    loadMenuItems() {
      downloadsCollection
        .orderBy("order")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.menuItems.push({
              id: doc.id,
              title: doc.data().title,
              order: doc.data().order,
              active: doc.data().active,
              created: doc.data().created,
              modified: doc.data().modified,
              filePath: doc.data().filePath,
              url: doc.data().url,
              showinnewtab: doc.data().showinnewtab,
              showupload: doc.data().showupload,
              loggedinonly: doc.data().loggedinonly || false,
            });
          });
        });
    },
    updateTooltip(e) {
      this.showTooltip = e;
    },
    closeDropdown() {
      this.displayDropdown = false;
      this.open = false;
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (this.settings.dataCollection === true) {
            this.$router.replace({
              name: "login",
            });
          } else {
            this.$router.replace({
              name: "home",
            });
          }
        });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        this.settings.launchDate = doc.data().launchDate ? doc.data().launchDate.toDate() : null;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
        this.settings.allowGlobalDiscount = doc.data().allowGlobalDiscount || false;
        this.settings.globalDiscountAmount = doc.data().globalDiscountAmount || null;
        this.settings.globalDiscountType = doc.data().globalDiscountType || null;

        this.settings.isGlobalOverrideAction = doc.data().isGlobalOverrideAction || false;
        this.settings.globalOverrideAction = doc.data().globalOverrideAction || null;
        this.settings.globalOverrideActionButton = doc.data().globalOverrideActionButton || null;

        this.settings.showAvailableUnits = doc.data().showAvailableUnits;
        if (doc.data().showAvailableUnits) {
          this.filterStatus('available-only')
        }

        this.settings.isUsersOnline = doc.data().isUsersOnline;

        this.settings.allowDiscountNoSurvey = doc.data().allowDiscountNoSurvey;

        (this.settings.isLaunchDate = doc.data().isLaunchDate || false);
        this.settings.interestRate = doc.data().interestRate;
        this.settings.logo = doc.data().logo;
        this.settings.headerImage = doc.data().headerImage;
        (this.settings.allowDeposit = doc.data().allowDeposit || false);
        (this.settings.depositType = doc.data().depositType || null);
        (this.settings.depositAmount = doc.data().depositAmount || null);

        (this.settings.bypassAdmin = doc.data().bypassAdmin || false);
        (this.settings.dataCollection = doc.data().dataCollection || false);
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    // --- Never Been Called ---
    userCompletedBuyersProfile: function () {
      if (
        !isNil(this.user) &&
        !isNil(this.user.profile) &&
        !isNil(this.user.profile.answeredDiscountSurvey)
      ) {
        return this.user.profile.answeredDiscountSurvey;
      }
      return false;
    },
  },
  created() {
    this.getSettings();
    this.loadMenuItems();

    settingsCollection.orderBy("globalDiscountAmount").onSnapshot((snap) => {
      let globals = [];

      snap.forEach((doc) => {
        globals.push({
          allowReserve: doc.data().allowReserve,
          allowDiscount: doc.data().allowDiscount,
          dataCollection: doc.data().dataCollection,
          displaySplashScreen: doc.data().displaySplashScreen,
          splashScreenMessage: doc.data().splashScreenMessage,
          allowDiscountNoSurvey: (doc.data().allowDiscountNoSurvey || false),
          allowGlobalDiscount: (doc.data().allowGlobalDiscount || false),
          globalDiscountType: (doc.data().globalDiscountType || null),
          globalDiscountAmount: (doc.data().globalDiscountAmount || null),
          launchDate: (doc.data().launchDate ? doc.data().launchDate.toDate() : null),
          logo: doc.data().logo,
          headerImage: doc.data().headerImage,
          hidePriceOnSold: (doc.data().hidePriceOnSold || false),
        });
      });
      this.globals = globals;
    });
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  }
};
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  // console.log(document.documentElement.scrollTop);
  if (document.documentElement.scrollTop > 50) {   
    document.getElementById("menuText").style.fontSize = "20px"; 
  } else if(document.documentElement.scrollTop < 10) {
    document.getElementById("menuText").style.fontSize = "25px";
  }
}
</script>

<style>
.menu-dropdown-container {
  position: absolute;
  z-index: 40;
  right: 0;
  margin-top: 0.5rem;
  transform-origin: top right;
}

.menu-dropdown-content {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  border-radius: 0.375rem;
  width: 16rem;
  overflow-y: auto;
}

.global-Menu-Content {
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 425px) {
  .menu-dropdown-container {
    margin: 0.5rem 0.5rem;
    left: -898%;
    width: 997%;
  }

  .menu-dropdown-content {
    width: 100%;
  }
}

@media only screen and (max-width: 417px) {
  .menu-dropdown-container {
    left: -877%;
    width: 976%;
  }
}

@media only screen and (max-width: 408px) {
  .menu-dropdown-container {
    left: -856%;
    width: 955%;
  }
}

@media only screen and (max-width: 398px) {
  .menu-dropdown-container {
    left: -831%;
    width: 930%;
  }
}

@media only screen and (max-width: 389px) {
  .menu-dropdown-container {
    left: -809%;
    width: 908%;
  }
}

@media only screen and (max-width: 381px) {
  .menu-dropdown-container {
    left: -790%;
    width: 889%;
  }
}

@media only screen and (max-width: 370px) {
  .menu-dropdown-container {
    left: -763%;
    width: 861%;
  }
}

@media only screen and (max-width: 360px) {
  .menu-dropdown-container {
    left: -739%;
    width: 838%;
  }
}

@media only screen and (max-width: 350px) {
  .menu-dropdown-container {
    left: -713%;
    width: 810%;
  }
}

@media only screen and (max-width: 340px) {
  .menu-dropdown-container {
    left: -687%;
    width: 787%;
  }
}

@media only screen and (max-width: 330px) {
  .menu-dropdown-container {
    left: -665%;
    width: 763%;
  }
}

@media only screen and (max-width: 320px) {
  .menu-dropdown-container {
    left: -641%;
    width: 740%;
  }
}
</style>
