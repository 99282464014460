<template>
  <div>
    <div v-if="settings.isLaunchDate">
      <!--      When Counter is > 0 show counter -->
      <div v-if=
       "
        Math.trunc((this.settings.launchDate - this.now) / 1000 / 60 / 60 / 24) >= 0 &&
        Math.trunc((this.settings.launchDate - this.now) / 1000 / 60 / 60) % 24 >= 0 &&
        Math.trunc((this.settings.launchDate - this.now) / 1000 / 60) % 60 >= 0 &&
        Math.trunc((this.settings.launchDate - this.now)/1000) % 60 >= 0
       "
      >
        <!--      Table & computer -->
        <div
          id="timerTextContainerScreen"
          v-if="windowWidth > 425"
          class="container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline"
        >
          <div id="timerTextContainer1Screen" class="container-timer">
          <span
            id="timerTextContainer2Screen"
            class="timer-text"
          >
            <h1 id="timerTextScreen">LAUNCHING IN</h1>
          </span>
            <span
              id="timerNumber"
              class="timer-text"
              :day="day"
              :hour="hour"
              :min="min"
              :sec="sec"
            >
            {{day}} : {{hour}} : {{min}} : {{sec}}
          </span>
          </div>
        </div>
        <!--      Mobi -->
        <div
          v-else
          class="container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline"
        >
          <div class="container-timer">
            <span
              class="timer-text"
              :day="day"
              :hour="hour"
              :min="min"
              :sec="sec"
            >
            {{day}} : {{hour}} : {{min}} : {{sec}}
          </span>
          </div>
        </div>
      </div>
      <!--      When Counter is === 0 show Message -->
      <div v-else>
        <div
        class="container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline">
        <span
          class="timer-text"
        >
<!--          <p id="salesText">{{ totalUnits }} / {{ allTotalUnits }} UNITS SOLD</p>-->
          <p id="salesText">{{ totalUnits }} UNITS SOLD</p>
        </span>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="container-timer focus:bg-gray-200 focus:outline-none focus:shadow-outline">
        <span
          class="timer-text"
        >
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import {settingsCollection, unitsCollection} from "@/firebase";
import isNil from "lodash/isNil";
import {reverse} from "lodash";

export default {
  props : {
    endDate : {
      // pass date object till when you want to run the timer
      type : Date,
      default(){
        return new Date()
      }
    },
    negative : {
      // optional, should count down after 0 to negative
      type : Boolean,
      default : false
    }
  },
  data(){
    return{
      totalUnitsAll: [],
      unitsPending: [],
      unitsPendingUnitDetails: [],
      unitsPendingPayment: [],
      unitsReserved: [],
      unitsSold: [],
      windowWidth: window.innerWidth,
      now : new Date(),
      timer : null,
      settings: {
        allowReserve: true,
        allowDiscount: false,
        launchDate: null,
        isLaunchDate: false,
      },
    }
  },
  computed: {
    totalUnits: function () {
      if (!isNil(this.unitsReserved)) {
        return this.totalUnitsPendingUnitDetails + this.totalUnitsPendingPayment + this.totalUnitsPending + this.totalUnitsReserved + this.totalUnitsSold
      }
      return 0;
    },
    allTotalUnits: function () {
      if (!isNil(this.totalUnitsAll)) {
        return this.totalUnitsAll.length;
      }

      return 0;
    },
    totalUnitsPending: function () {
      if (!isNil(this.unitsPending)) {
        return this.unitsPending.length;
      }

      return 0;
    },
    totalUnitsPendingUnitDetails: function () {
      if (!isNil(this.unitsPendingUnitDetails)) {
        return this.unitsPendingUnitDetails.length;
      }

      return 0;
    },
    totalUnitsPendingPayment: function () {
      if (!isNil(this.unitsPendingPayment)) {
        return this.unitsPendingPayment.length;
      }

      return 0;
    },
    totalUnitsReserved: function () {
      if (!isNil(this.unitsReserved)) {
        return this.unitsReserved.length;
      }

      return 0;
    },
    totalUnitsSold: function () {
      if (!isNil(this.unitsSold)) {
        return this.unitsSold.length;
      }

      return 0;
    },
    day(){
      let d = Math.trunc((this.settings.launchDate - this.now) / 1000 / 60 / 60 / 24);
      return d>9?d:'0'+d;
    },
    hour(){
      let h = Math.trunc((this.settings.launchDate - this.now) / 1000 / 60 / 60) % 24;
      return h>9?h:'0'+h;
    },
    min(){
      let m = Math.trunc((this.settings.launchDate - this.now) / 1000 / 60) % 60;
      return m>9?m:'0'+m;
    },
    sec(){
      let s = Math.trunc((this.settings.launchDate - this.now)/1000) % 60
      return s>9?s:'0'+s;
    }
  },
  created() {
    this.getSettings();
    this.getUnitsPending("name", false);
    this.getUnitsPendingUnitDetails("name", false);
    this.getUnitsPendingPayment("name", false);
    this.getUnitsReserved("name", false);
    this.getUnitsSold("name", false);
    settingsCollection
      .doc("globalSettings")
      .get()
      .then((doc) => {
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        (this.settings.allowGlobalDiscount =
          doc.data().allowGlobalDiscount || false),
          (this.settings.globalDiscountType =
            doc.data().globalDiscountType || null),
          (this.settings.globalDiscountAmount =
            doc.data().globalDiscountAmount || null),
          (this.settings.launchDate = doc.data().launchDate
            ? doc.data().launchDate.toDate()
            : null);
        this.settings.interestRate = doc.data().interestRate;
        this.settings.logo = doc.data().logo;
        this.settings.headerImage = doc.data().headerImage;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.isLaunchDate = doc.data().isLaunchDate || false;
      });

    unitsCollection
        // .where("status", "==", "sold")
        .orderBy("name")
        .onSnapshot((snap) => {
          let totalUnitsAll = [];

          snap.forEach((doc) => {
            totalUnitsAll.push({
              id: doc.id,
              name: doc.data().name,
              price: doc.data().price,
              floor: doc.data().floor,
              internalArea: doc.data().internalArea,
              externalArea: doc.data().externalArea,
              totalArea: doc.data().totalArea,
              bedrooms: doc.data().bedrooms,
              bathrooms: doc.data().bathrooms,
              parking: doc.data().parking,
              storeroom: doc.data().storeroom,
              aspect: doc.data().aspect,
              rates: doc.data().rates,
              levies: doc.data().levies,

              guaranteedRentalAmount: doc.data().guaranteedRentalAmount,
              status: doc.data().status,
              soldProgress: doc.data().soldProgress,
            });
          });
          this.totalUnitsAll = totalUnitsAll
        });
  },
  watch: {
    settings : {
      immediate : true,
      handler(newVal){
        if(this.timer){
          clearInterval(this.timer)
        }
        this.timer = setInterval(()=>{
          this.now = new Date()
          if(this.negative)
            return
          if(this.now > newVal){
            this.now = newVal
            this.$emit('endTime')
            clearInterval(this.timer)
          }
        }, 1000)
      }
    },
  },
  beforeDestroy(){
    clearInterval(this.timer)
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
    this.startTimer()
  },

  methods: {
    getUnitsPending(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
        .where("status", "==", "pending")
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let unitsPending = [];

          snap.forEach((doc) => {
            unitsPending.push({
              ...doc.data(),
            });
          });

          if (orderByReverse) {
            this.unitsPending = reverse(unitsPending);
          } else {
            this.unitsPending = unitsPending;
          }


          this.componentLoading = false;
        });
    },
    getUnitsPendingUnitDetails(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
        .where("status", "==", "pendingUnitDetails")
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let unitsPendingUnitDetails = [];

          snap.forEach((doc) => {
            unitsPendingUnitDetails.push({
              ...doc.data(),
            });
          });

          if (orderByReverse) {
            this.unitsPendingUnitDetails = reverse(unitsPendingUnitDetails);
          } else {
            this.unitsPendingUnitDetails = unitsPendingUnitDetails;
          }


          this.componentLoading = false;
        });
    },
    getUnitsPendingPayment(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
        .where("status", "==", "pendingPayment")
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let unitsPendingPayment = [];

          snap.forEach((doc) => {
            unitsPendingPayment.push({
              ...doc.data(),
            });
          });

          if (orderByReverse) {
            this.unitsPendingPayment = reverse(unitsPendingPayment);
          } else {
            this.unitsPendingPayment = unitsPendingPayment;
          }


          this.componentLoading = false;
        });
    },
    getUnitsSold(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
        .where("status", "==", "sold")
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let unitsSold = [];

          snap.forEach((doc) => {
            unitsSold.push({
              ...doc.data(),
            });
          });

          if (orderByReverse) {
            this.unitsSold = reverse(unitsSold);
          } else {
            this.unitsSold = unitsSold;
          }


          this.componentLoading = false;
        });
    },
    getUnitsReserved(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
          .where("status", "==", "reserved")
          // .where("status", "==", "sold")
          .orderBy(orderBy)
          .onSnapshot((snap) => {
            let unitsReserved = [];

            snap.forEach((doc) => {
              unitsReserved.push({
                ...doc.data(),
              });
            });

            if (orderByReverse) {
              this.unitsReserved = reverse(unitsReserved);
            } else {
              this.unitsReserved = unitsReserved;
            }


            this.componentLoading = false;
          });
    },
    onTimesUp() {
      clearInterval(this.timerInterval)
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000)
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        (this.settings.allowDiscount = doc.data().allowDiscount);
        (this.settings.launchDate = doc.data().launchDate ? doc.data().launchDate.toDate() : null);
        (this.settings.allowReserve = doc.data().allowReserve);
        (this.settings.displaySplashScreen = doc.data().displaySplashScreen);
        (this.settings.splashScreenMessage = doc.data().splashScreenMessage);
        (this.settings.allowGlobalDiscount = doc.data().allowGlobalDiscount || false);
        (this.settings.globalDiscountType = doc.data().globalDiscountType || null);
        (this.settings.globalDiscountAmount = doc.data().globalDiscountAmount || null);
        (this.settings.logo = doc.data().logo);
        (this.settings.headerImage = doc.data().headerImage);
        (this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false);
        (this.settings.isLaunchDate = doc.data().isLaunchDate || false);
      });
    },
  },
};
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  // console.log(document.documentElement.scrollTop);
  if (document.documentElement.scrollTop > 50) {
    document.getElementById("salesText").style.fontSize = "15px";
    document.getElementById("timerText").style.fontSize = "15px";
    document.getElementById("timerTextContainerScreen").style.padding = "5px 5px 5px 8px";
    document.getElementById("timerTextContainer1Screen").style.padding = "3px 3px 3px 8px";
    document.getElementById("timerTextContainer2Screen").style.padding = "3px 3px 3px 8px";
  } else if(document.documentElement.scrollTop < 10) {
    document.getElementById("salesText").style.fontSize = "20px";
    document.getElementById("timerText").style.fontSize = "20px";
    document.getElementById("timerTextContainerScreen").style.padding = "8px 16px";
    document.getElementById("timerTextContainer1Screen").style.padding = "8px 16px";
    document.getElementById("timerTextContainer2Screen").style.padding = "8px 16px";
  }
}
</script>

<style>
.container-timer {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  text-transform: uppercase;
  letter-spacing: 0.05em;
  flex-wrap: nowrap;
  flex-direction: row;
}

.timer-text {
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .25rem .5rem;
    flex-wrap: nowrap !important;
    flex-direction: row !important;
  }

  .timer-text {
    padding: .25rem .5rem;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 425px) {
  .container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}

@media only screen and (max-width: 415px) {
  .container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}

@media only screen and (max-width: 375px) {
  .container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}

@media only screen and (max-width: 320px) {
  .container-timer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;
    flex-wrap: wrap !important;
    flex-direction: column !important;
  }

  .timer-text {
    padding: .2rem .45rem;
    font-size: .85rem;
  }
}
</style>
