<template>
  <nav
  id="navbar"
  class="flex flex-row w-full px-4 py-2 colorBackGround"
  :class="settings.isUsersOnline ? 'element-sticky' : 'isUsersOnline'">
    <div id="images" class="container-content">
      <a
        class="container-image leading-relaxed whitespace-no-wrap"
        href="/"
      >
        <img
          id="logoOne"
          :alt="title"
          v-if="!logo && !loading"
          src="../../assets/images/the-one-stellenbosch/default-nav-bar-logo.png"
          class="image-style"
        />

        <img
          id="logoTwo"
          :alt="title"
          v-if="logo && !loading"
          :src="logo"
          class="image-style"
        />
      </a>
    </div>

    <div class="flex flex-row flex-grow items-center justify-center">
      <CountDownTimerLaunch />
    </div>

    <div class="flex flex-row items-center justify-end">
      <MainNavBarProfileDropdown id="v-step-profile" />
    </div>
  </nav>
</template>

<script>
import { settingsCollection } from "../../firebase.js";
import CountDownTimerLaunch from "./CountDownTimerLaunch.vue";
import MainNavBarProfileDropdown from "./MainNavBarProfileDropdown.vue";
import {APP_TITLE} from "@/Constants";

export default {
  name: "MainNavBar",
  components: {
    MainNavBarProfileDropdown,
    CountDownTimerLaunch,
  },
  data() {
    return {
      title: APP_TITLE,
      contactDialogData: {
        open: false,
      },
      logo: null,
      loading: true,
      settings: {
        isUsersOnline: false,
      },
    };
  },
  created() {
    this.getSettings();

    settingsCollection.doc("globalSettings").onSnapshot((doc) => {
      this.logo = doc.data().logo;
      this.loading = false;
    });
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu;
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.isUsersOnline = doc.data().isUsersOnline;
      });
    },
  },
};
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
  // console.log(document.documentElement.scrollTop);
  if (document.documentElement.scrollTop > 50) {
    document.getElementById("navbar").style.padding = "4.5px 0px";
    document.getElementById("logoTwo").style.padding = "0px 0px";
    document.getElementById("navbar").style.minHeight = "85px";
    if (window.innerWidth <= 768) {
      document.getElementById("navbar").style.minHeight = "65px";
    }
    if (window.innerWidth <= 425) {
      document.getElementById("navbar").style.minHeight = "60px";
    }
    document.getElementById("logoTwo").style.height = "2rem";
  } else if(document.documentElement.scrollTop < 10) {
    document.getElementById("navbar").style.padding = "10px 10px";
    document.getElementById("logoTwo").style.padding = ".25rem 0px";
    document.getElementById("navbar").style.minHeight = "105px";
    if (window.innerWidth <= 768) {
      document.getElementById("navbar").style.minHeight = "80px";
    }
    if (window.innerWidth <= 425) {
      document.getElementById("navbar").style.minHeight = "75px";
    }
    document.getElementById("logoTwo").style.height = "3rem";
  }
}
</script>

<style>
.colorBackGround {
  --tw-bg-opacity: 1;
  background-color: rgba(39,45,51, var(--tw-bg-opacity));
  /*background-color: #272d33;*/
}
.isUsersOnline {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 99;
}
.element-sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 2rem;
  z-index: 99;
}

.container-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  text-transform: uppercase;
}

.image-style {
  max-height: 3rem;
}

@media only screen and (max-width: 425px) {
  .container-image {
    padding: .25rem 0;
  }

  .image-style {
    height: 2.75rem;
    margin-top: -5px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 415px) {
  .image-style {
    max-height: 2.5rem;
  }
}

@media only screen and (max-width: 375px) {
  .image-style {
    max-height: 1.8rem;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 320px) {
  .image-style {
    max-height: 1.5rem;
  }
}
</style>
