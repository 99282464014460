import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '../views/app/App.vue'
import Home from '../views/app/Home.vue'

import firebase from 'firebase'
import {
  profilesCollection,
  settingsCollection
} from '../firebase'
import { hasPermission } from '../Permissions'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: App,
    redirect: {name: 'App'},
    meta: {
      requiresAuthData: true,
    },
    children: [
      {
        name: 'home',
        path: '',
        component: Home,
        meta: {
          requiresAuthData: true,
        },
      },
      {
        name: 'unit',
        path: 'unit/:name',
        component: () =>
            import(
                /* webpackChunkName: "Unit" */ '../views/app/Unit.vue'
                ),
      },
      {
        name: 'onedayonlyunit',
        path: 'onedayonly/:name',
        component: () =>
            import(
                /* webpackChunkName: "Unit" */ '../views/app/Unit.vue'
                )
      },
      {
        name: 'reserve',
        path: 'reserve/:name',
        component: () =>
            import(
                /* webpackChunkName: "Reserve" */ '../views/app/Reserve-2.vue'
                ),
      },
      {
        name: 'profile',
        path: 'profile',
        component: () =>
            import(
                /* webpackChunkName: "Profile" */ '../views/app/Profile.vue'
                ),
      },
      {
        path: '/thankyou',
        name: 'thankyou',
        component: () => import(
            /* webpackChunkName: "ThankYou" */ '../views/app/ThankYou.vue'
            ),
        props: true,
      },
      {
        name: 'cancel',
        path: '/cancel/:id',
        component: () => import(
            /* webpackChunkName: "Cancel" */ '../views/app/Cancel.vue'
            ),
      },
      {
        path: '/survey',
        name: 'survey',
        component: () => import(
            /* webpackChunkName: "Survey" */ '../views/app/Survey.vue'
            ),
      },
      // {
      //   name: 'redirected-unit',
      //   path: '/gounit/:name',
      //   component: () => import(
      //     /* webpackChunkName: "RedirectedUnit" */ '../views/app/RedirectedUnit.vue'
      //   ),
      // },
    ]
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "Admin" */ '../views/admin/Admin.vue'),
    redirect: {name: 'admin'},
    meta: {
      requiresAuthData: true,
      requiresAdminRole: true,
    },
    children: [
      {
        name: 'admin',
        path: 'dashboard',
        component: () => import(
            /* webpackChunkName: "Dashboard" */ '../views/admin/Dashboard-v2.vue'
            ),
        meta: {
          area: 'dashboard',
          action: 'view',
        }
      },
      {
        name: 'admin-units',
        path: 'units',
        component: () => import(
            /* webpackChunkName: "AdminUnits" */ '../views/admin/Units.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-available-units',
        path: 'units-available',
        component: () => import(
            /* webpackChunkName: "AdminAvailableUnits" */ '../views/admin/AvailableUnits.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-pending-units',
        path: 'units-pending',
        component: () => import(
            /* webpackChunkName: "AdminPendingUnits" */ '../views/admin/PendingUnits.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-reserved-units',
        path: 'units-reserved',
        component: () => import(
            /* webpackChunkName: "AdminReservedUnits" */ '../views/admin/ReservedUnits.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-sold-units',
        path: 'units-sold',
        component: () => import(
            /* webpackChunkName: "AdminSoldUnits" */ '../views/admin/SoldUnits.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-shortlisted-units',
        path: 'units-shortlisted',
        component: () => import(
            /* webpackChunkName: "AdminSoldUnits" */ '../views/admin/ShortlistedUnits.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-unit-shortlisters',
        path: 'unit-shortlisters',
        component: () => import(
            /* webpackChunkName: "AdminSoldUnits" */ '../views/admin/UnitShortlisters.vue'
            ),
        meta: {
          area: 'units',
          action: 'view',
        }
      },
      {
        name: 'admin-add-unit',
        path: 'unit',
        component: () => import(
            /* webpackChunkName: "AdminUnit" */ '../views/admin/Unit.vue'
            ),
        meta: {
          area: 'units',
          action: 'add',
        }
      },
      {
        name: 'admin-update-unit',
        path: 'unit/:id',
        component: () => import(
            /* webpackChunkName: "AdminUnit" */ '../views/admin/Unit.vue'
            ),
        meta: {
          area: 'units',
          action: 'update',
        }
      },
      {
        name: 'admin-users',
        path: 'users',
        component: () => import(
            /* webpackChunkName: "AdminUsers" */ '../views/admin/Users.vue'
            ),
        meta: {
          area: 'users',
          action: 'view',
        }
      },
      {
        name: 'admin-attendants',
        path: 'attendants',
        component: () => import(
            /* webpackChunkName: "AdminAgents" */ '../views/admin/SocialChatSettings.vue'
            ),
        meta: {
          area: 'attendants',
          action: 'view',
        }
      },
      {
        name: 'admin-agents',
        path: 'agents',
        component: () => import(
            /* webpackChunkName: "AdminAgents" */ '../views/admin/Agents.vue'
            ),
        meta: {
          area: 'agents',
          action: 'view',
        }
      },
      {
        name: "admin-add-agent",
        path: "agents",
        component: () => import(
            /* webpackChunkName: "AdminAgent" */ '../views/admin/Agent.vue'
            ),
        meta: {
          area: 'agents',
          action: 'add',
        }
      },
      {
        name: "admin-add-attendant",
        path: "attendants",
        component: () => import(
            /* webpackChunkName: "AdminAgent" */ '../views/admin/SocialChatSetting.vue'
            ),
        meta: {
          area: 'attendants',
          action: 'add',
        }
      },
      {
        name: "admin-update-agent",
        path: "agent/:id",
        component: () => import(
            /* webpackChunkName: "AdminAgent" */ '../views/admin/Agent.vue'
            ),
        meta: {
          area: 'agents',
          action: 'update',
        }
      },
      {
        name: "admin-update-attendant",
        path: "attendant/:id",
        component: () => import(
            /* webpackChunkName: "AdminAttendant" */ '../views/admin/SocialChatSetting.vue'
            ),
        meta: {
          area: 'attendants',
          action: 'update',
        }
      },
      {
        name: 'admin-users',
        path: 'users',
        component: () => import(
            /* webpackChunkName: "AdminUsers" */ '../views/admin/Users.vue'
            ),
        meta: {
          area: 'users',
          action: 'view',
        }
      },
      {
        name: 'admin-email-templates',
        path: 'email-templates',
        component: () => import(
            /* webpackChunkName: "AdminEmailTemplates" */ '../views/admin/EmailTemplates.vue'
            ),
        meta: {
          area: 'email-templates',
          action: 'view',
        }
      },
      {
        name: "admin-update-email-template",
        path: "email-templates/:id",
        component: () => import(
            /* webpackChunkName: "EmailTemplate" */ '../views/admin/EmailTemplate.vue'
            ),
        meta: {
          area: 'email-templates',
          action: 'update',
        }
      },
      {
        name: "admin-add-email-template",
        path: "email-template",
        component: () => import(
            /* webpackChunkName: "EmailTemplate" */ '../views/admin/EmailTemplate.vue'
            ),
        meta: {
          area: 'email-templates',
          action: 'add',
        }
      },
      {
        name: 'admin-leads',
        path: 'leads',
        component: () => import(
            /* webpackChunkName: "AdminLeads" */ '../views/admin/Leads.vue'
            ),
        meta: {
          area: 'leads',
          action: 'view',
        }
      },
      {
        name: 'admin-lead',
        path: 'leads/:id?',
        component: () => import(
            /* webpackChunkName: "AdminLead" */ '../views/admin/Lead.vue'
            ),
        meta: {
          area: 'leads',
          action: 'update',
        }
      },
      {
        name: 'admin-leads-migration',
        path: 'leads-migration',
        component: () => import(
            /* webpackChunkName: "AdminLeads" */ '../views/admin/LeadsMigration.vue'
            ),
        meta: {
        }
      },
      {
        name: 'admin-data',
        path: 'data',
        component: () => import(
            /* webpackChunkName: "AdminData" */ '../views/admin/Data.vue'
            ),
        meta: {
          area: 'data',
          action: 'view',
        }
      },
      {
        name: 'admin-settings',
        path: 'settings',
        component: () => import(
            /* webpackChunkName: "AdminSettings" */ '../views/admin/Settings.vue'
            ),
        meta: {
          area: 'settings',
          action: 'view',
        }
      },
      //start Unit Images
      {
        name: 'admin-images',
        path: 'admin-images',
        component: () => import(
            /* webpackChunkName: "AdminSettings" */ '../views/admin/Images.vue'
            ),
        meta: {
          area: 'images',
          action: 'view',
        }
      },
      //end
      {
        name: 'admin-extra-settings',
        path: 'extraSettings',
        component: () => import(
            /* webpackChunkName: "AdminSettings" */ '../views/admin/ExtrasSettings.vue'
            ),
        meta: {
          area: 'extraSettings',
          action: 'view',
        }
      },
      {
        name: 'admin-downloads',
        path: 'downloads',
        component: () => import(
            /* webpackChunkName: "AdminDownloads" */ '../views/admin/Downloads.vue'
            ),
        meta: {
          area: 'downloads',
          action: 'view',
        }
      },
      {
        name: 'admin-import',
        path: 'import',
        component: () => import(
            /* webpackChunkName: "AdminImportUnits" */ '../views/admin/ImportUnits.vue'
            ),
        meta: {
          area: 'units',
          action: 'import',
        }
      },
      {
        name: 'admin-import-leads',
        path: 'import-leads',
        component: () => import(
            /* webpackChunkName: "AdminImportLeads" */ '../views/admin/ImportLeads.vue'
            ),
        meta: {
          area: 'leads',
          action: 'import',
        }
      }
    ]
  },
  {
    name: 'go',
    path: '/go/:ref',
    component: () =>
        import(
            /* webpackChunkName: "RedirectSurvey" */ '../views/app/RedirectSurvey.vue'
            )
  },
  {
    name: 'login',
    path: '/login',
    component: () =>
        import(
            /* webpackChunkName: "Login" */ '../views/global/Login.vue'
            )
  },
  {
    name: 'register',
    path: '/register',
    component: () =>
        import(
            /* webpackChunkName: "Register" */ '../views/global/Register.vue'
            )
  },
  {
    name: 'reset-password',
    path: '/resetpassword',
    component:  () =>
        import(
            /* webpackChunkName: "ResetPassword" */ '../views/global/ResetPassword.vue'
            )
  },
  {
    path: '/thankyou/:id',
    name: 'ThankYou',
    component: () =>
        import(
            /* webpackChunkName: "ThankYou" */ '../views/app/ThankYou.vue'
            )
  },
  {
    path: '/cancel/:id',
    name: 'CancelPayment',
    component:  () =>
        import(
            /* webpackChunkName: "Cancel" */ '../views/app/Cancel.vue'
            )
  },
  {
    name: 'notFound',
    path: "/404",
    component:  () =>
        import(
            /* webpackChunkName: "404" */ '../views/global/404.vue'
            )
  },
  {
    name: 'not-found',
    path: "/404",
    component:  () =>
        import(
            /* webpackChunkName: "404" */ '../views/global/404.vue'
            )
  },
  {
    path: "*",
    component:   () =>
        import(
            /* webpackChunkName: "404" */ '../views/global/404.vue'
            )
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  const user = await firebase.getCurrentUser()

  const requiresAuth = to.matched.some(record => record.meta.requiresAuthData)
  if (requiresAuth) {
    const requiresAdminRole = to.matched.some(record => record.meta.requiresAdminRole)
    if (requiresAdminRole) {
      if (!user || !user.email)
        return router.push({ name: 'login' })

      // Get profile.
      profilesCollection
          .doc(user.uid)
          .onSnapshot(snap => {
            // if (snap.data().role !== 'user')
            //   return next()
            //
            // const meta = to.matched[to.matched.length - 1].meta
            // if (meta) {
            //   if (hasPermission(meta.area, meta.action, snap.data().role))
            //     return router.push({ name: 'not-found' })
            // }
            //
            // return next();

            if (snap.data().role !== 'user') {
              const meta = to.matched[to.matched.length - 1].meta
              if (meta) {
                if (hasPermission(meta.area, meta.action, snap.data().role)) {
                  return next()
                }
                else {
                  return router.push({ name: 'not-found' })
                }
              }
              else {
                return next()
              }
            }
            else {
              return router.push({ name: 'login' })
            }

          })
      return false
    }

    let dataCollection = 0
    if (!user) {
      // Prevent the component from loading.
      settingsCollection
          .doc('globalSettings')
          .onSnapshot(snap => {
            const settings = snap.data()
            if (settings.dataCollection === true && !dataCollection) {
              dataCollection = 1
              router.push({ name: 'login' })
            }
            else
              return next()
          })
      return false
    }
  }

  next()
})

export default router