<template>
  <form class="relative flex flex-col" @submit.prevent="submitForm">
    <div class="flex flex-col md:flex-row -mx-3">
      <div class="w-full md:w-1/2 px-3 mb-5">
        <label for="contact-first-name" class="text-xs font-semibold px-1"
        >First name*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <NameIcon class="text-gray-400 text-lg" />
          </div>
          <input
            id="contact-first-name"
            v-model="form.firstName"
            type="text"
            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': $v.form.firstName.$error,
            invisible: !$v.form.firstName.$error,
          }"
        >
          Required</span
        >
      </div>
      <div class="w-full md:w-1/2 px-3 b-5">
        <label for="contact-last-name" class="text-xs font-semibold px-1"
        >Last name*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <NameIcon class="text-gray-400 text-lg" />
          </div>
          <input
            id="contact-last-name"
            v-model="form.lastName"
            type="text"
            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': $v.form.lastName.$error,
            invisible: !$v.form.lastName.$error,
          }"
        >
          Required</span
        >
      </div>
    </div>

    <div class="flex flex-col md:flex-row -mx-3">
      <div class="w-full md:w-1/2 px-3 mb-5">
        <label for="contact-email" class="text-xs font-semibold px-1"
        >Email*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <EmailIcon class="text-gray-400 text-lg" />
          </div>
          <input
            id="contact-email"
            v-model="form.email"
            type="email"
            class="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': $v.form.email.$error,
            invisible: !$v.form.email.$error,
          }"
        >
          Valid email required</span
        >
      </div>

      <div class="w-full md:w-1/2 px-3 mb-5">
        <label
          for="contact-phone"
          class="text-xs font-semibold px-1"
        >
          Contact Number*
        </label>
        <div class="flex">
          <VuePhoneNumberInput
            id="contact-phone"
            v-model="form.contactNumber"
            @update="updatePhoneNumber"
            class="phone-container"
            size="md"
            color="#aa9461"
            default-country-code="ZA" />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': $v.form.contactNumber.$error,
            invisible: !$v.form.contactNumber.$error,
          }"
        >
          Valid phone number required
        </span>
      </div>
    </div>

    <div class="flex -mx-3">
      <div class="w-full px-3 mb-8">
        <label for="contact-message" class="text-xs font-semibold px-1"
        >Message*</label
        >
        <div class="flex">
          <div
            class="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center"
          >
            <MessageIcon class="text-gray-400 text-lg" />
          </div>
          <textarea
            id="contact-message"
            v-model="form.message"
            type="text"
            class="w-full -ml-10 pl-10 pr-3 h-32 rounded-lg border-2 border-gray-200 outline-none focus:border-primary"
            placeholder=""
          />
        </div>
        <span
          class="block w-full mt-1 text-xs text-red-600"
          :class="{
            '': $v.form.message.$error,
            invisible: !$v.form.message.$error,
          }"
        >
          At least 5 letters required</span
        >
      </div>
    </div>

    <div class="flex -mx-3 justify-end">
      <div class="px-3 mb-2">
        <input
          class="block w-full bg-primary hover:bg-primary focus:bg-primary text-white uppercase rounded-lg px-3 py-3 font-semibold"
          type="submit"
          value="Send"
        />

        <p
          class="absolute top-0 left-0 z-50 flex flex-col justify-center items-center h-full w-full bg-white duration-150"
          v-if="successMessage"
        >
          <MessageSentIcon :size="80" class="text-green-500" />
          Thank you for contacting us. We will be in touch shortly.
        </p>
      </div>
    </div>
  </form>
</template>

<script>
import firebase from "firebase";
import { messagesCollection, leadsCollection } from "../../firebase.js";
import { required, email, minLength } from "vuelidate/lib/validators";
import VuePhoneNumberInput from 'vue-phone-number-input'
// import ContactIcon from "vue-material-design-icons/Cellphone";
import EmailIcon from "vue-material-design-icons/At";
import MessageIcon from "vue-material-design-icons/AndroidMessages";
import NameIcon from "vue-material-design-icons/Account";
import MessageSentIcon from "vue-material-design-icons/CheckAll";

export default {
  name: "MainNavContactButtonMessageTab",
  components: {
    // ContactIcon,
    VuePhoneNumberInput,
    EmailIcon,
    MessageIcon,
    NameIcon,
    MessageSentIcon,
  },
  data: () => ({
    successMessage: false,
    form: {
      firstName: null,
      lastName: null,
      email: null,
      contactNumber: null,
      e164ContactNumber: null,
      formatNationalContactNumber: null,
      message: null,
    },
  }),
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      contactNumber: { required, minLength: minLength(5) },
      message: { required, minLength: minLength(5) },
    },
  },
  methods: {
    updatePhoneNumber(e) {
      this.form.e164ContactNumber = e.e164
      this.form.formatNationalContactNumber = e.formatNational
    },
    submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const timeStamp = firebase.firestore.Timestamp.now();

      const messageData = {
        form: "Navigation Message Tab Form",
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email,
        contactNumber: this.form.contactNumber,
        e164ContactNumber: this.form.e164ContactNumber,
        formatNationalContactNumber: this.form.formatNationalContactNumber,
        message: this.form.message,
        timestamp: timeStamp,
      };

      messagesCollection.add(messageData).then(() => {
        this.successMessage = true;
      });

      const leadData = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
        email: this.form.email.toLowerCase(),
        phone: this.form.contactNumber,
        rating: "TBC",
        source: "Enquiry",
        created: firebase.firestore.Timestamp.now(),
      };

      leadsCollection.add(leadData);
    },
  },
};
</script>

<style>
.input-tel__input {
  border-width: 2px !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  height: 44px !important;
  min-height: 44px !important;
}

.input-tel__input:focus {
  border-width: 2px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity)) !important;
}
.input-tel.is-valid .input-tel__label[data-v-e59be3b4] {
  color: #424542 !important;
}
.country-selector__input {
  border-width: 2px !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
  height: 44px !important;
  min-height: 44px !important;
}

.country-selector__input:focus {
  border-width: 2px;
  /*outline: 2px solid #2563eb !important;*/
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}

.country-selector__label {
  color: #424542 !important;
}

.phone-container {
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 100%;
}

.phone-container:focus {
  --tw-border-opacity: 1;
  border-color: rgba(170, 148, 97, var(--tw-border-opacity));
}
</style>
